import axios from 'axios';
import backendUrl from '../utils/backendUrl';

const login = async (credentials) => {
  return await axios({
    method: 'POST',
    url :`${backendUrl}/api/login`,
    withCredentials: true,
    data: credentials,
  }).then((response) =>{
    return response.data
  }).catch((e)=>{
    return e.response.data;
  });
};

const reset = async (credentials) => {
  const response = await axios.post(`${backendUrl}/api/reset_password`, credentials, {withCredentials: true},);
  return response.data;
};

// const newPassword = async (credentials) => {
//   const response = await axios.post(`${backendUrl}/api/new_password`, credentials, {withCredentials: true},);
//   return response.data;
// };

const register = async (enteredData) => {
  const response = await axios.post(`${backendUrl}/api/register`, enteredData);
  return response.data;
};


export default { login, reset, register };
