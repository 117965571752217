import { Link, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import classes from "./Resource.module.css";

const mbsFeesColumn = [
  {
    text: "MBS Item",
    dataField: "item_num",
  },
  {
    text: "Description",
    dataField: "description",
    formatter: (value) => {
      return (
        <Tooltip className="Add" title={value}>
          <Typography className={classes.Description}>
            {value.length > 55
              ? value?.substring(0, Math.min(55, value?.length)) + "..."
              : value}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    text: "Schedule Fee",
    dataField: "schedule_fee",
    formatter: (value) => {
      return !value ? "N/A" : `$${value}`;
    },
  },
  {
    text: "Benefit 100",
    dataField: "benefit_100",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    text: "Benefit 85",
    dataField: "benefit_85",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    text: "Benefit 75",
    dataField: "benefit_75",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    text: "Action",
    dataField: "action",
  },
];

const viewMBSFees = [
  {
    title: "MBS Item",
    field: "item_num",
  },
  {
    title: "Schedule Fee",
    field: "schedule_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    title: "Benefit 100",
    field: "benefit_100",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    title: "Benefit 85",
    field: "benefit_85",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    title: "Benefit 75",
    field: "benefit_75",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    title: "Description",
    field: "description",
    formatter: (value) => {
      if(value){
        return (
          <Tooltip className="Add" title={value}>
            <Typography className={classes.ViewDescription}>
              {value}
            </Typography>
          </Tooltip>
        );
      }else{
        return "N/A"
      }
    },
  },
];
const viewAmaFees = [
  {
    title: "AMA Item",
    field: "ama_item",
  },
  {
    title: "MBS Item",
    field: "mbs_item",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    title: "AMA Fee",
    field: "ama_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    title: "Schedule Fee",
    field: "schedule_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    title: "Gap Fee",
    field: "gap_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    title: "Description",
    field: "description",
    formatter: (value) => {
      if(value){
        return (
          <Tooltip className="Add" title={value}>
            <Typography className={classes.ViewDescription}>
              {value}
            </Typography>
          </Tooltip>
        );
      }else{
        return "N/A"
      }
    },
  },
];

const viewWorkcoverFees = [
  {
    title: "Workcover Item",
    field: "workcover_item",
  },
  {
    title: "Workcover Fee",
    field: "workcover_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    title: "Description",
    field: "descripton",
  },
];

const amaFees = [
  {
    text: "AMA Item",
    dataField: "ama_item",
  },
  {
    text: "MBS Item",
    dataField: "mbs_item",
  },
  {
    text: "AMA Fee",
    dataField: "ama_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    text: "Schedule Fee",
    dataField: "schedule_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    text: "Gap Fee",
    dataField: "gap_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    text: "Description",
    dataField: "description",
    formatter: (value) => {
      return (
        <Tooltip className="Add" title={value}>
          <Typography className={classes.Description}>
            {value.length > 55
              ? value?.substring(0, Math.min(55, value?.length)) + "..."
              : value}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    text: "Action",
    dataField: "action",
  },
];

const workCover = [
  {
    text: "Workcover Item",
    dataField: "workcover_item",
  },
  {
    text: "Workcover Fee",
    dataField: "workcover_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    text: "Description",
    dataField: "description",
    formatter: (value) => {
      return (
        <Tooltip className="Add" title={value}>
          <Typography className={classes.Description}>
            {value.length > 55
              ? value?.substring(0, Math.min(55, value?.length)) + "..."
              : value}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    text: "Action",
    dataField: "action",
  },
];

const privateFee = [
  {
    text: "Item code",
    dataField: "mbs_item",
  },
  {
    text: "Private Fee",
    dataField: "private_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    text: "Schedule Fee",
    dataField: "schedule_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    text: "Gap Fee",
    dataField: "gap_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    text: "Description",
    dataField: "description",
    formatter: (value) => {
      return (
        <Tooltip className="Add" title={value}>
          <Typography className={classes.Description}>
            {value.length > 55
              ? value?.substring(0, Math.min(55, value?.length)) + "..."
              : value}
          </Typography>
        </Tooltip>
      );
    },
  },
  // {
  //   text: "Provider",
  //   dataField: "provider",
  //   formatter: (value, row) => {
  //     if(row?.all_provider){
  //       return "All Providers"
  //     }
  //     var totalName = value.map((e)=>{
  //      return `${e.first_name}  ${e.last_name}`;
  //     }).join(", ")
  //     return `${totalName}`;
  //   },
  // },
  {
    text: "Action",
    dataField: "action",
  },
];

const viewPrivateFees = [
  {
    title: "Item code",
    field: "mbs_item",
  },
  {
    title: "Private Fee",
    field: "private_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    title: "Schedule Fee",
    field: "schedule_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    title: "Gap Fee",
    field: "gap_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    title: "Description",
    field: "description",
    formatter: (value) => {
      return <p className="ReduceText">{value}</p>;
    },
  },
  // {
  //   title: "Provider",
  //   field: "provider",
  //   formatter: (value, row) => {
  //     if(row.all_provider){
  //       return "All Providers"
  //     }else{
  //       var totalName = value.map((e)=>{
  //         return e.first_name +" " + e.last_name;
  //        }).join(", ")
  //        return `${totalName}`;
  //     }
  //   },
  // },
];

const viewCustomPrivateFees = [
  {
    title: "Item code",
    field: "mbs_item",
  },
  {
    title: "Private Fee",
    field: "private_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    title: "GST Percentage",
    field: "gst_percentage",
  },
  {
    title: "GST Included",
    field: "gst_included",
    formatter: (value) => {
      return value ? "Yes" : "No";
    },
  },
  {
    title: "Description",
    field: "description",
    formatter: (value) => {
      return <p className="ReduceText">{value}</p>;
    },
  },

  // {
  //   title: "Provider",
  //   field: "provider",
  //   formatter: (value, row) => {
  //     if(row.all_provider){
  //       return "All Providers"
  //     }else{
  //       var totalName = value.map((e)=>{
  //         return e.first_name +" " + e.last_name;
  //        }).join(", ")
  //        return `${totalName}`;
  //     }
  //   },
  // },
];

const dvaAlliedFeesTable = [
  {
    text: "Item No",
    dataField: "item_num",
  },
  {
    text: "Provider Type",
    dataField: "item_group",
  },
  {
    text: "Fee",
    dataField: "fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    text: "Description",
    dataField: "description",
    formatter: (value) => {
      return (
        <Tooltip className="Add" title={value}>
          <Typography className={classes.Description}>
            {value.length > 55
              ? value?.substring(0, Math.min(55, value?.length)) + "..."
              : value}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    text: "Action",
    dataField: "action",
  },
];

const dvaAlliedFeesView = [
  {
    title: "Item No",
    field: "item_num",
  },
  {
    title: "Provider Type",
    field: "item_group",
  },
  {
    title: "Fee",
    field: "fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    title: "Description",
    field: "description",
    formatter: (value) => {
      return <p className="ReduceText">{value}</p>;
    },
  },
];

const dvaFeesTable = [
  {
    text: "Item No",
    dataField: "item_num",
  },
  {
    text: "Description",
    dataField: "description",
    formatter: (value) => {
      return (
        <Tooltip className="Add" title={value}>
          <Typography className={classes.Description}>
            {value?.length > 55
              ? value?.substring(0, Math.min(55, value?.length)) + "..."
              : value}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    text: "Schedule",
    dataField: "schedule_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    text: "LMO Fee",
    dataField: "lmo_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    text: "In Hospital Fee",
    dataField: "in_hospital_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    text: "Out of Hospital Fee",
    dataField: "out_hospital_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    text: "Action",
    dataField: "action",
  },
];

const dvaFeesView = [
  {
    title: "Item No",
    field: "item_num",
  },
  {
    title: "Schedule",
    field: "schedule_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    title: "LMO Fee",
    field: "lmo_fee",
    formatter: (value) => {
      return !value ? "N/A" : `$${value}`;
    },
  },
  {
    title: "In Hospital Fee",
    field: "in_hospital_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    title: "Out of Hospital Fee",
    field: "out_hospital_fee",
    formatter: (value) => {
      return value === null ? "N/A" : `$${value}`;
    },
  },
  {
    title: "Description",
    field: "description",
    formatter: (value) => {
      return <p className="ReduceText">{value}</p>;
    },
  },
];

const mbsFeeColumn = {
  mbsFeesColumn,
  viewMBSFees,
  amaFees,
  viewAmaFees,
  viewWorkcoverFees,
  workCover,
  privateFee,
  viewPrivateFees,
  viewCustomPrivateFees,
  dvaAlliedFeesTable,
  dvaAlliedFeesView,
  dvaFeesTable,
  dvaFeesView,
};

export default mbsFeeColumn;
