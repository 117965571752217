import React, { Fragment, useEffect, useState } from "react";
import { Grid, MenuItem, Select } from "@mui/material";
import classes from "./SelectBox.module.css";

function SelectBox(props) {
  const [value, setValue] = useState(0);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid
          item
          xs={props.viewException ? 2 : props.xsLabel || 4}
          className={`LabelBox`}
        >
          <label>
            {props.label}
            {props.required ? <sup>*</sup> : null}
          </label>
        </Grid>

        <Grid item xs={props.xsInside || 8}>
          <Select
            className={`SelectPrimary ${props.error ? "ErrorBorder" : null} ${
              props.disabled === true ? "disabled" : null
            }`}
            onChange={props.onChangeAction}
            onBlur={props.onBlurAction}
            value={value}
            defaultValue={value}
            disabled={props.disabled}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "0 !Important",
              },
              "& .MuiOutlinedInput-input.Mui-disabled": {
                WebkitTextFillColor: "rgb(0 0 0 / 25%) !important",
                backgroundColor: "#e0e0e0 !important",
              },
            }}
          >
            {!props.noNone && !props.viewException && !props.search && (
              <MenuItem className="OptionPrimary" value="">
                Select
              </MenuItem>
            )}
            {props.options &&
              props.options?.map((item, index) => {
                return (
                <MenuItem
                  className="OptionPrimary"
                  value={props.addPatientEmail ? item : item.value}
                  name={item.label}
                >
                  {item.label}
                </MenuItem>
              )})}
          </Select>

          {props.error ? (
            <label className={classes.ErrorMsg}>{props.error}</label>
          ) : null}
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default SelectBox;
