import React, { Fragment } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Divider } from "@mui/material";
import { Icon } from "@iconify/react";
import classes from "./ListButton.module.css";

const ListButton = (props) => {
  return (
    <Fragment>
      <ListItemButton
        key={props.text}
        sx={{
          minHeight: 48,
          justifyContent: props.open ? "initial" : "center",
          px: 2.5,
          backgroundColor:props.name ? "#8e78b7" :  "",
          color:props.name ? "white" : props.active ? "#56438E" : "",

          position:props.name && "fixed !important",
          width:props.name && "280px !important",
          zIndex:props.name && "99999999",
          padding:props.name && "0px",

          borderLeft:props.active ? "4px solid #56438E" : "",


          "&:hover":{
            backgroundColor:props.name ? "#8e78b7" : ""
          }
        }}
        onClick={props.onClickAction}
      >



        {/* Icon before menu text  */}
        <ListItemIcon
          style={props.isSub && props.open ? { paddingLeft: "44px" } : null}
          sx={{
            minWidth: 0,
            mr: props.open ? 3 : "auto",
            justifyContent: "center",
            color:props.name ? "white" : props.active ? "#56438E" : "black",

            backgroundColor:props.name ? "black" :  "",
            height:props.name ? "50px !important" : "", 
            padding:props.name ? "13px 10px !important" : ""
          }}
        >
          <Icon
            icon={props.icon}
            style={{ fontSize: props.fontSize ? props.fontSize : "21px" }}
          />


        </ListItemIcon>

        <ListItemText
          disableTypography
          className={classes.root}
          primary={props.text}
          selected={props.selected}
          sx={
            props.isSub
              ? { fontWeight: 400, color: props.active ? "#56438E" : "#000", opacity: props.open ? 1 : 0 }
              : { opacity: props.open ? 1 : 0 }
          }
        />

        {/* submenu down arrow icon   */}
        {props.subMenu ? (
          <ListItemIcon
            sx={{
              minWidth: 0,
              display: props.open ? "block" : "none",
              justifyContent: "end",
            }}
          >
            {props.expanded ? (
              <Icon icon="charm:chevron-up" sx={{ fontSize: "20px" }} />
            ) : (
              <Icon icon="charm:chevron-down" sx={{ fontSize: "20px" }} />
            )}
          </ListItemIcon>
        ) : null}
      </ListItemButton>
      {props.open ? (
        <Divider variant="fullWidth" component="li" />
      ) : (
        <Divider
          variant="fullWidth"
          component="li"
          sx={{ visibility: "hidden" }}
        />
      )}
    </Fragment>
  );
};

export default ListButton;
