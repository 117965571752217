import React, { Fragment, useEffect, useState } from "react";
import { Alert, Backdrop, Grid } from "@mui/material";
import { Box } from "@mui/system";
import PageHeader from "../../../Layout/PageHeader/PageHeader";
import classes from "./AddUser.module.css";
import theme from "../../../Theme/Theme";
import { ThemeProvider } from "react-bootstrap";
import Button from "../../../UI/Button/Button";
import PageFooter from "../../../Layout/PageFooter/PageFooter";
import axios from "axios";
import SelectBox from "../../../UI/FormGroup/SelectBox/SelectBox";
import TextBox from "../../../UI/FormGroup/TextBox/TextBox";
import backendUrl from "../../../utils/backendUrl";
import RadioGroup from "../../../UI/FormGroup/RadioGroup/RadioGroup";
import storageService from "../../../utils/localStorageHelpers";
import Loader from "../../../UI/Loader/Loader";
import { useNavigate } from "react-router-dom";
import LoaderWithoutBackdrop from "../../../UI/Loader/LoaderWithoutBackdrop";
import Breadcrumb from "../../../Layout/Breadcrumb/Breadcrumb";
import useMediaQuery from "@mui/material/useMediaQuery";
import CautionAlert from "../../../utils/CautionAlert";

const AddUser = (props) => {
  const widthIs14 = useMediaQuery("(max-width:1399px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");

  let siteDt = storageService.loadSite();
  siteDt = siteDt[0];

  const navigate = useNavigate();

  const [roleOption, setRoleOption] = useState(null);
  const [title, setTitle] = useState(null);
  const [titleError, setTitleError] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [middleName, setMiddleName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState(null);
  const [role, setRole] = useState(null);
  const [roleError, setRoleError] = useState(null);
  const [provider, setProvider] = useState(null);
  const [providerError, setProviderError] = useState(null);
  const [username, setUserName] = useState(null);
  const [usernameError, setUserNameError] = useState(null);
  const [site, setSite] = useState(null);
  const [siteError, setSiteError] = useState(null);
  const [providerNumber, setProviderNumber] = useState("");
  const [providerNumberError, setProviderNumberError] = useState(null);
  const [immuneProviderNumber, setImmuneProviderNumber] = useState("");
  const [immuneProviderNumberError, setImmuneProviderNumberError] =
    useState(null);
  const [prescriberNumber, setPrescriberNumber] = useState("");
  const [prescriberNumberError, setPrescriberNumberError] = useState(null);
  const [registrationId, setRegistrationId] = useState("");
  const [registrationIdError, setRegistrationIdError] = useState(null);
  const [qualification, setQualification] = useState(null);
  const [qualificationError, setQualificationError] = useState(null);
  const [hiNumber, setHiNumber] = useState(null);
  const [hiNumberError, setHiNumberError] = useState(null);
  const [hiStatus, setHiStatus] = useState(null);
  const [hiStatusError, setHiStatusError] = useState(null);
  const [error, setError] = useState(null);
  const [noData, setNoData] = useState(false);
  const [success, setSuccess] = useState(null);
  const [loader, setLoader] = useState(true);
  const [userType, setUserType] = useState(null);
  const [userTypeError, setUserTypeError] = useState(null);
  const [fullTimeSub, setFullTimeSub] = useState(null);
  const [partTimeSub, setPartTimeSub] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [partTimeSubError, setPartTimeSubError] = useState(null);
  const [showErrorBtn, setShowErrorBtn] = useState(true);
  const [payeeProviderName, setPayeeProviderName] = useState(null);
  const [payeeProviderNameError, setPayeeProviderNameError] = useState(null);
  const [payeeProviderNumber, setPayeeProviderNumber] = useState(null);
  const [payeeProviderNumberError, setPayeeProviderNumberError] =
    useState(null);

  const [open, setOpen] = useState(null);
  const [existError, setExistError] = useState(null);

  useEffect(() => {
    getSite();
    loadSubscription();
  }, []);

  const titleHandler = (e) => {
    setTitleError();
    setTitle(e.target.value);
  };
  const titleValidation = (e) => {
    if (e && e.target) {
      setTitle(e.target.value);
    }
    if (!title) {
      setTitleError("Required");
    }
  };

  const firstNameHandler = (e) => {
    setFirstNameError();
    setFirstName(e.target.value);
  };

  const firstNameValidation = (e) => {
    if (e && e.target) {
      setFirstName(e.target.value);
    }

    if (!firstName) {
      setFirstNameError("Required");
    }
  };

  const middleNameHandler = (e) => {
    setMiddleName(e.target.value);
  };

  const lastNameHandler = (e) => {
    setLastNameError();
    setLastName(e.target.value);
  };

  const lastNameValidation = (e) => {
    if (e && e.target) {
      setLastName(e.target.value);
    }
    if (!lastName) {
      setLastNameError("Required");
    }
  };

  const emailHandler = (e) => {
    setEmailError();
    setEmail(e.target.value);
  };

  const emailValidation = (e) => {
    if (e && e.target) {
      setEmail(e.target.value);
    }

    if (!email) {
      setEmailError("Required");
    } else if (!isEmail(email)) {
      setEmailError("Invalid email address");
    }
  };

  const isEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const mobileHandler = (e) => {
    if (e.target.value.length > 10) {
      setMobile((prev) => prev);
      return;
    }

    setMobileError();
    setMobile(e.target.value);
  };

  const mobileValidation = (e) => {
    if (e && e.target) {
      setMobile(e.target.value);
    }

    if (!mobile) {
      setMobileError("Required");
    }

    if (mobile && mobile.length < 10) {
      setMobileError("Mobile number should be 10 digits");
    }
  };

  const roleHandler = (e) => {
    setRoleError();
    setRole(e.target.value);

    let arr = roleOption.filter((item) => {
      return item.id === e.target.value;
    });

    // let a = arr[0];

    // let practitioners = [
    //   "Practitioner",
    //   "practitioner",
    //   "Senior Practitioner",
    //   "senior practitioner",
    //   "Senior practitioner",
    // ];

    // if (a && practitioners.includes(a.name)) {
    //   setProvider(true);
    // } else {
    //   setProvider(false);
    // }
  };
  const roleValidation = (e) => {
    if (e && e.target) {
      setRole(e.target.value);
    }
    if (!role) {
      setRoleError("Required");
    }
  };

  const providerHandler = (e) => {
    setProviderError("");
    setProvider((prev) => {
      if (prev === true) {
        setPrescriberNumber();
        setProviderNumber();
        setRegistrationId();
        setHiNumber();
        setHiStatus();
      }
      if (prev === null || (prev !== true && prev !== false)) {
        let val = e.target.value.toLowerCase() === "true";
        return val;
      }
      return !prev;
    });
  };

  const providerValidation = (e) => {
    if (e && e.target) {
      setProvider(e.target.value);
    }
    if (provider !== true && provider !== false) {
      setProviderError("Required");
    }
  };

  const usernameHandler = (e) => {
    setUserNameError();
    setUserName(e.target.value);
  };

  const usernameValidation = async (e) => {
    if (e && e.target) {
      setUserName(e.target.value);
    }
    if (!username) {
      setUserNameError("Required");
    }

    if (username.length < 8) {
      setUserNameError("Username must be at least 8 characters");
    }
  };

  const siteNameHandler = (e) => {
    setSiteError();
    setSite(e.target.value);
  };

  const siteNameValidation = (e) => {
    if (e && e.target) {
      setSite(e.target.value);
    }

    if (!site) {
      setSiteError("Required");
    }
  };

  const getSite = () => {
    const siteArr = storageService.loadSite();
    setSite(siteArr[0]);
  };

  const providerNumberHandler = (e) => {
    if (e.target.value.length > 8) {
      setProviderNumber((prev) => prev);
      return;
    }
    setProviderNumberError();
    setProviderNumber(e.target.value);
  };

  const providerNumberValidation = (e) => {
    if (e && e.target) {
      setProviderNumber(e.target.value.toUpperCase());
    }
    if (provider) {
      if (!providerNumber) {
        setProviderNumberError("Required");
      }
    }
    if (providerNumber && providerNumber.length !== 8) {
      setProviderNumberError("Invalid provider number");
    }
  };

  const immuneProviderNumberHandler = (e) => {
    if (e.target.value.length > 8) {
      setImmuneProviderNumber((prev) => prev);
      return;
    }
    setImmuneProviderNumberError();
    setImmuneProviderNumber(e.target.value);
  };

  // const immuneProviderNumberValidation = (e) => {
  //   if (provider) {
  //     if (immuneProviderNumber && immuneProviderNumber.length !== 8) {
  //       setImmuneProviderNumberError("Invalid immunisation provider number");
  //     }
  //   }
  // };

  const prescriberNumberHandler = (e) => {
    if (e.target.value.length > 7) {
      setPrescriberNumber((prev) => prev);
      return;
    }
    const a = findRole();
    setPrescriberNumberError();
    setPrescriberNumber(e.target.value);
  };

  const prescriberNumberValidation = (e) => {
    if (e && e.target) {
      setPrescriberNumber(e.target.value);
    }
    if (prescriberNumber && prescriberNumber.length !== 7) {
      setPrescriberNumberError("Invalid prescriber number");
    }
  };

  const registrationIdHandler = (e) => {
    if (e.target.value.length > 13) {
      setRegistrationId((prev) => prev);
      return;
    }
    setRegistrationIdError();
    setRegistrationId(e.target.value);
  };

  const registrationIdValidation = (e) => {
    if (e && e.target) {
      setRegistrationId(e.target.value);
    }
    if (provider) {
      if (!registrationId) {
        setRegistrationIdError("Required");
      }
    }
    if (registrationId && registrationId.length !== 13) {
      setRegistrationIdError("Invalid registration id");
    }

    if (registrationId && registrationId.length === 13) {
      let first = registrationId.substring(0, 3);
      let second = registrationId.substring(3, 13);

      let isChar = /^[a-zA-Z]+$/.test(first);
      let isNum = /^\d+$/.test(second);

      if (!isChar) {
        setRegistrationIdError("Invalid registration id");
      }
      if (!isNum) {
        setRegistrationIdError("Invalid registration id");
      }
    }
  };

  const qualificationHandler = (e) => {
    setQualificationError();
    setQualification(e.target.value);
  };
  const qualificationValidation = (e) => {
    if (e && e.target) {
      setQualification(e.target.value);
    }
  };

  const hiNumberHandler = (e) => {
    setHiNumberError(null);
    setHiNumber(e.target.value);
  };
  const hiNumberValidation = (e) => {
    if (provider && !hiNumber) {
      setHiNumberError("Required");
    }
  };

  const hiStatusHandler = (e) => {
    setHiStatusError();
    setHiStatus(e.target.value);
  };
  // const hiStatusValidation = (e) => {
  //   if (e && e.target) {
  //     setHiStatus(e.target.value);
  //   }

  //   if (hiNumber) {
  //     if (!hiStatus) {
  //       setHiStatusError("Required");
  //     }
  //   }
  // };

  const payeeProviderNameHandler = (e) => {
    setPayeeProviderNameError();
    setPayeeProviderName(e.target.value);
  };

  const payeeProviderNameValidation = () => {
    if (provider && !payeeProviderName) {
      setPayeeProviderNameError("Required");
    }
  };

  const payeeProviderNumberHandler = (e) => {
    setPayeeProviderNumberError();

    if (e.target.value.length > 8) {
      setPayeeProviderNumber((prev) => prev);
      return;
    }
    setPayeeProviderNumber(e.target.value);
  };

  const payeeProviderNumberValidation = () => {
    if (provider && !payeeProviderNumber) {
      setPayeeProviderNumberError("Required");
      return;
    }
    if (payeeProviderNumber && payeeProviderNumber.length !== 8) {
      setPayeeProviderNumberError("Invalid provider number");
    }
  };

  const userTypeHandler = (e) => {
    let value = e.target.value;
    setUserTypeError();
    setUserType(value);
  };

  const userTypeValidation = () => {
    if (!userType) {
      setUserTypeError("Required");
    }
  };

  const findRole = () => {
    let arr = roleOption.filter((item) => {
      return item.id === role;
    });
    return arr[0];
  };

  const TitleOption = [
    {
      value: "Dr",
      label: "Dr",
    },
    {
      value: "Miss",
      label: "Miss",
    },
    {
      value: "Mr",
      label: "Mr",
    },
    {
      value: "Ms",
      label: "Ms",
    },
    {
      value: "Prof",
      label: "Prof",
    },
    {
      value: "Rev",
      label: "Rev",
    },
    {
      value: "Sir",
      label: "Sir",
    },
  ];

  const hiStatusOptions = [
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "Deactivated",
      label: "Deactivated",
    },
    {
      value: "Retired",
      label: "Retired",
    },
  ];

  const loadSubscription = () => {
    return axios({
      medthod: "GET",
      url: `${backendUrl}/api/user_subscription`,
      params: {
        site_id: siteDt.site_id,
        user_id: "null",
        status: "Active",
      },
      withCredentials: true,
    })
      .then(async (response) => {
        setNoData(false);
        let dt = response?.data?.data;

        let ft = dt?.filter((sub) => sub.user_type === "fulltime");
        setFullTimeSub(ft);

        let pt = dt?.filter((sub) => sub.user_type === "parttime");
        setPartTimeSub(pt);
        setLoader(false);

        await axios({
          medthod: "GET",
          url: `${backendUrl}/api/role`,
          withCredentials: true,
          params: {
            list_for_site: true,
          },
        })
          .then((response) => {
            setNoData(false);
            let arr = [];

            response.data.data.map((role) => {
              arr.push({
                ...role,
                label: role.name,
                value: role.id,
              });
            });

            setRoleOption(arr);
            setLoader(false);
          })
          .catch((e) => {
            setNoData(true);
            errorHandler("Something went wrong. Please try again", false, true);
            setLoader(false);
          });
      })
      .catch((e) => {
        setNoData(true);
        errorHandler("Something went wrong. Please try again", false, true);
        setLoader(false);
      });
  };

  const providerOptions = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  const userTypeOptions = [
    {
      label: "Full Time",
      value: "fulltime",
    },
    {
      label: "Part Time",
      value: "parttime",
    },
  ];

  const errorHandler = (msg, autoHidden, nullData) => {
    if (nullData) {
      setNoData(true);
    }
    setError(msg);
  };

  const submitValidationHandler = () => {
    titleValidation();
    firstNameValidation();
    lastNameValidation();
    emailValidation();
    mobileValidation();
    usernameValidation();
    roleValidation();
    siteNameValidation();
    userTypeValidation();
    providerValidation();
    if (provider) {
      providerNumberValidation();
      prescriberNumberValidation();
      registrationIdValidation();
      // hiNumberValidation();
      // hiStatusValidation();
      payeeProviderNameValidation();
      payeeProviderNumberValidation();
    }
  };

  const submitValidation = () => {
    if (
      !title ||
      !firstName ||
      !lastName ||
      !email ||
      !mobile ||
      !role ||
      !username ||
      !userType ||
      (provider !== true && provider !== false)
    ) {
      return false;
    } else if (mobile && mobile.length < 10) {
      return false;
    } else if (username.length < 8) {
      return false;
    } else if (provider) {
      if (providerNumber && providerNumber.length !== 8) {
        return false;
      } else if (prescriberNumber && prescriberNumber.length !== 7) {
        return false;
      // } else if (!hiNumber) {
      //   return false;
        // } else if (!hiStatus) {
        //   return false;
      } else if (!payeeProviderName) {
        return false;
      } else if (!payeeProviderNumber) {
        return false;
      } else if (registrationId) {
        if (registrationId && registrationId.length !== 13) {
          return false;
        }
        let first = registrationId.substring(0, 3);
        let second = registrationId.substring(3, 13);

        let isChar = /^[a-zA-Z]+$/.test(first);
        let isNum = /^\d+$/.test(second);

        if (!isChar) {
          return false;
        }
        if (!isNum) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const submitHandler = async () => {
    const toProceed = submitValidation();

    if (!toProceed) {
      submitValidationHandler();
      setLoader(false);
      return;
    }

    const selectedRole = roleOption.find((item) => item.id === role);
    const partSub = partTimeSub.filter((s) => s.role_id === role);
    const fullSub = fullTimeSub.filter((s) => s.role_id === role);

    if (selectedRole.paid_role === true) {
      if (partSub?.length < 1 && fullSub?.length < 1) {
        setSubscriptionError(
          "You required a new licence to create this user. Please contact the administrator"
        );
        return;
      }
    }

    if (selectedRole?.paid_role === true) {
      if (userType === "parttime") {
        if (partSub.length < 1 && fullSub.length > 0) {
          setPartTimeSubError(
            "You do not have a part time licence. Do you want to assign this part time user to a full time licence?"
          );
          return;
        }
      } else {
        if (fullSub.length < 1 && partSub.length > 0) {
          setSubscriptionError(
            "You required a full time licence to create this user. Please contact the administrator or change the user type to part time"
          );
          return;
        }
      }
    }

    return submitFunction();
  };

  const submitFunction = async () => {
    setLoader(true);
    await axios({
      method: "POST",
      url: `${backendUrl}/api/register`,
      data: {
        name: firstName + " " + lastName,
        username,
        email,
        password: "rz4^PSxE8=u%dp2K",
        user_type: userType,
        site_id: site.site_id,
        org_id: site.org_id,
        roleId: role,
      },
      withCredentials: true,
    })
      .then(async (response) => {
        const regUser = response.data.data;
        const token = response.data.token;
        await axios({
          method: "POST",
          url: `${backendUrl}/${site.site_name}/api/site_user`,
          data: {
            user_id: regUser.id,
            title,
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            email,
            mobile,
            provider,
            provider_number: providerNumber ? providerNumber : null,
            immunisation_provider_number: immuneProviderNumber
              ? immuneProviderNumber
              : null,
            prescriber_number: prescriberNumber ? prescriberNumber : null,
            registration_id: registrationId ? registrationId : null,
            qualification,
            health_identifier: hiNumber,
            hi_status: hiStatus,
            payee_provider_name: payeeProviderName,
            payee_provider_number: payeeProviderNumber,
            token,
          },
          withCredentials: true,
        }).catch(async (e) => {
          await axios({
            method: "DELETE",
            url: `${backendUrl}/api/transaction/delete/fromfe/${regUser.id}`,
            withCredentials: true,
          });
          
          errorHandler("Something went wrong. Please try again", true);
        });
      })
      .then((res) => {
        setLoader(false);
        // successHandler("User has been successfully added");
        navigate(`/app/user`, {
          state: {
            showAlert:
              "User has been added successfully and an activation link is sent to the email address",
          },
        });
      })
      .catch((error) => {
        setLoader(false);

        if (error?.response?.data?.message?.includes("username")) {
          // setExistError(error.response.data.message);
          setUserNameError("This username already exist");
          // setTimeout(() => {
          //   setExistError();
          // }, 3000);
          return;
        }

        errorHandler("Something went wrong. Please try again", true);
      });
  };

  /**
   * Individual Health Identifier API
   */

  const hiSearchHandler = async () => {
    setHiNumberError();
    setLoader(true);
    if (lastName && registrationId) {
      await axios({
        method: "POST",
        url: `${backendUrl}/api/providerIndividualSearch`,
        data: {
          registrationId: registrationId,
          familyName: lastName,
        },
        withCredentials: true,
      })
        .then(async (response) => {
          let result = response.data;
          setHiNumber(result.hpii_number);
          if (result.status === "A") {
            setHiStatus("Active");
          } else if (result.status === "D") {
            setHiStatus("Deceased");
          } else {
            setHiStatus("Retired");
          }

          setLoader(false);
        })
        .catch((e) => {
          setHiNumberError("No records have been found");
          setLoader(false);
        });
    }
  };
  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/users",
      iconName: "carbon:user-multiple",
      name: "Users",
    },
    // {
    //   link: "#",
    //   iconName: "carbon:user-follow",
    //   name: "Add User",
    // },
  ];

  const handleClose = (value) => {
    setOpen(false);
  };

  const retry = () => {
    setError(null);
    setTimeout(() => {
      loadSubscription();
    }, 100);
  };

  return (
    <ThemeProvider theme={theme}>
      {partTimeSubError && (
        <CautionAlert
          severity="error"
          open={partTimeSubError}
          content={partTimeSubError}
          onCloseActionB={() => {
            setPartTimeSubError();
            return submitFunction();
          }}
          onCloseActionA={() => {
            setPartTimeSubError();
            setRole();
          }}
          okText="Yes"
          cancelText="No"
        />
      )}
      {subscriptionError && (
        <CautionAlert
          severity="error"
          open={subscriptionError}
          content={subscriptionError}
          oneButton={true}
          onCloseActionB={() => {
            setSubscriptionError();
            setRole();
          }}
          okText="Ok"
        />
      )}
      <Breadcrumb options={breadCrumbData} />
      <Loader open={loader} />

      {existError ? (
        <>
          <Alert
            severity="error"
            sx={{
              mb: 2,
              border: "1px solid red",
              zIndex: "99999 !important",
              position: "relative",
            }}
            action={<></>}
          >
            {existError}
          </Alert>
          <Backdrop
            sx={{ backgroundColor: "#00000000", zIndex: 500 }}
            open={existError}
          ></Backdrop>
        </>
      ) : null}

      {success ? (
        <Alert severity="success" sx={{ mb: 2, border: "1px solid green" }}>
          {success}
        </Alert>
      ) : null}

      {error ? (
        <>
          <Alert
            severity="error"
            sx={{
              mb: 2,
              border: "1px solid red",
              zIndex: "99999 !important",
              position: "relative",
            }}
            action={
              !showErrorBtn ? null : (
                <>
                  <Button
                    onClickAction={retry}
                    color="inherit"
                    size="small"
                    text="Retry"
                  />
                  <Button
                    onClickAction={() => setError(null)}
                    color="inherit"
                    size="small"
                    text="Dismiss"
                    margin="0px 10px !important"
                  />
                </>
              )
            }
          >
            {error}
          </Alert>
          <Backdrop
            sx={{ backgroundColor: "#00000000", zIndex: 500 }}
            open={error}
          ></Backdrop>
        </>
      ) : null}

      <PageHeader left="Add New User" />
      <form>
        {noData ? (
          ""
        ) : (
          <Box className={classes.FormBox}>
            <Grid container direction="row" spacing={2}>
              {/* First Column */}
              <Grid
                container
                item
                xs={widthIs14 ? 12 : 6}
                direction="column"
                sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
                className={classes.Max}
              >
                <SelectBox
                  xsLabel={4}
                  xsInside={8}
                  label="Title"
                  options={TitleOption}
                  value={title}
                  onChangeAction={titleHandler}
                  onBlurAction={titleValidation}
                  error={titleError}
                  required
                />
                <TextBox
                  xsLabel={4}
                  xsInside={8}
                  type="text"
                  label="First Name"
                  onChangeAction={firstNameHandler}
                  onBlurAction={firstNameValidation}
                  value={firstName}
                  error={firstNameError}
                  placeholder="First Name"
                  required
                />
                <TextBox
                  xsLabel={4}
                  xsInside={8}
                  type="text"
                  label="Last Name"
                  onChangeAction={lastNameHandler}
                  onBlurAction={lastNameValidation}
                  value={lastName}
                  error={lastNameError}
                  placeholder="Last Name"
                  required
                />
                <TextBox
                  xsLabel={4}
                  xsInside={8}
                  type="text"
                  label="Middle Name"
                  onChangeAction={middleNameHandler}
                  value={middleName}
                  placeholder="Middle Name"
                />

                <TextBox
                  xsLabel={4}
                  xsInside={8}
                  type="email"
                  label="Email"
                  onChangeAction={emailHandler}
                  onBlurAction={emailValidation}
                  value={email}
                  error={emailError}
                  placeholder="Email"
                  required
                />
                <TextBox
                  type="number"
                  label="Mobile Number"
                  onChangeAction={mobileHandler}
                  onBlurAction={mobileValidation}
                  value={mobile}
                  error={mobileError}
                  placeholder="Mobile number"
                  required
                />
                {roleOption ? (
                  <SelectBox
                    label="Role"
                    options={roleOption}
                    value={role}
                    onChangeAction={roleHandler}
                    onBlurAction={roleValidation}
                    error={roleError}
                    required
                  />
                ) : (
                  <SelectBox
                    label="Role"
                    value={role}
                    onChangeAction={roleHandler}
                    onBlurAction={roleValidation}
                    error={roleError}
                    required
                    disabled
                  />
                )}
                <TextBox
                  type="text"
                  label="Username"
                  onChangeAction={usernameHandler}
                  onBlurAction={usernameValidation}
                  value={username}
                  error={usernameError}
                  placeholder="User Name"
                  required
                />
              </Grid>

              {/* Second Column */}
              <Grid
                container
                item
                xs={widthIs14 ? 12 : 6}
                direction="column"
                className={classes.Max}
                sx={{
                  marginLeft: widthIs1630 ? "" : "80px",
                  maxWidth: widthIs14 ? "" : "590px !important",
                }}
              >
                <TextBox
                  type="text"
                  label="Site Name"
                  onChangeAction={siteNameHandler}
                  onBlurAction={siteNameValidation}
                  value={site && site.site_name}
                  error={siteError}
                  placeholder="Site Name"
                  disabled={true}
                  required
                />

                <TextBox
                  type="text"
                  label="Qualification"
                  onChangeAction={qualificationHandler}
                  onBlurAction={qualificationValidation}
                  value={qualification}
                  error={qualificationError}
                  placeholder="Qualification"
                />

                <RadioGroup
                  options={userTypeOptions}
                  onChangeAction={userTypeHandler}
                  onBlur={userTypeValidation}
                  name="usertype"
                  value={userType}
                  label="User Type"
                  error={userTypeError}
                  md={6}
                  required
                />

                <RadioGroup
                  options={providerOptions}
                  onChangeAction={providerHandler}
                  onBlur={providerValidation}
                  name="is_provider"
                  value={provider}
                  label="Is Provider?"
                  error={providerError}
                  md={6}
                  required
                />

                {provider ? (
                  <Fragment>
                    <TextBox
                      type="text"
                      label="Provider Number"
                      onChangeAction={providerNumberHandler}
                      onBlurAction={providerNumberValidation}
                      value={providerNumber}
                      error={providerNumberError}
                      required
                    />
                    <TextBox
                      type="text"
                      label="Immunisation Provider Number"
                      onChangeAction={immuneProviderNumberHandler}
                      // onBlurAction={immuneProviderNumberValidation}
                      value={immuneProviderNumber}
                      // error={immuneProviderNumberError}
                    />
                    <TextBox
                      type="number"
                      label="Prescriber Number"
                      onChangeAction={prescriberNumberHandler}
                      onBlurAction={prescriberNumberValidation}
                      value={prescriberNumber}
                      error={prescriberNumberError}
                      placeholder="Prescriber Number"
                    />
                    <TextBox
                      type="text"
                      label="Registration ID (AHPRA #)"
                      onChangeAction={registrationIdHandler}
                      onBlurAction={registrationIdValidation}
                      value={registrationId}
                      error={registrationIdError}
                      placeholder="Registration ID"
                      required
                    />
                  </Fragment>
                ) : null}

                {provider ? (
                  <Fragment>
                    {/* <Grid container spacing={2}>
                      <Grid item xs={4}>
                        {" "}
                      </Grid>
                      <Grid item xs={8} className="pb-4">
                        <Button
                          text="Fetch&nbsp;Health&nbsp;Identifier"
                          fontSize="14px"
                          onClickAction={hiSearchHandler}
                          disabled={
                            !lastName || !registrationId || registrationIdError
                          }
                        />
                      </Grid>
                    </Grid> */}

                    <TextBox
                      type="text"
                      label="Health Identifier"
                      onChangeAction={hiNumberHandler}
                      // onBlurAction={hiNumberValidation}
                      value={hiNumber}
                      // error={hiNumberError}
                      placeholder="Health Identifier"
                      // disabled={true}
                    />

                    {/* <TextBox
                      type="text"
                      label="HI Status"
                      onChangeAction={hiStatusHandler}
                      onBlurAction={hiStatusValidation}
                      value={hiStatus}
                      error={hiStatusError}
                      placeholder="Status"
                      // disabled={true}
                      required
                    /> */}
                    <SelectBox
                      label="HI Status"
                      options={hiStatusOptions}
                      value={hiStatus}
                      onChangeAction={hiStatusHandler}
                      // onBlurAction={hiStatusValidation}
                      // error={hiStatusError}
                    />
                    <TextBox
                      type="text"
                      label="Payee Provider Name"
                      onChangeAction={payeeProviderNameHandler}
                      onBlurAction={payeeProviderNameValidation}
                      value={payeeProviderName}
                      error={payeeProviderNameError}
                      required
                    />
                    <TextBox
                      type="text"
                      label="Payee Provider Number"
                      onChangeAction={payeeProviderNumberHandler}
                      onBlurAction={payeeProviderNumberValidation}
                      value={payeeProviderNumber}
                      error={payeeProviderNumberError}
                      required
                    />
                  </Fragment>
                ) : null}
              </Grid>
            </Grid>
          </Box>
        )}
        <PageFooter
          right={
            <Fragment>
              {noData ? (
                ""
              ) : (
                <Box
                  sx={{
                    px: 2,
                  }}
                >
                  <Button text="Save" onMouseDownAction={submitHandler} />
                </Box>
              )}

              <Box>
                <Button
                  fontSize="14px"
                  onMouseDownAction={(e) => navigate(-1)}
                  bgLight={true}
                  text="Cancel"
                />
              </Box>
            </Fragment>
          }
        />
      </form>
    </ThemeProvider>
  );
};

export default AddUser;
