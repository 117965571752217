import { createSlice } from "@reduxjs/toolkit";
import { EditorState } from "draft-js";

const INITIAL_STATE = {
  activeSession: null,
  sessionNote: null,
  start: null,
  timer: 0,
  active: false,
  paused: false,
  saved: true,
  notePath: null,
};

const sessionSlice = createSlice({
  name: "session",
  initialState: INITIAL_STATE,
  reducers: {
    setActiveSession: (state, action) => {
      state.activeSession = action.payload;
    },
    setSessionNote: (state, action) => {
      state.sessionNote = action.payload;
    },
    setStart: (state, action) => {
      state.start = action.payload;
    },
    setTimer: (state, action) => {
      state.timer = action.payload;
    },
    setNotePath: (state, action) => {
      state.notePath = action.payload;
    },
    increment: (state) => {
      state.timer = state.timer + 1;
    },
    activate: (state) => {
      state.active = true;
    },
    deactivate: (state) => {
      state.active = false;
    },
    pause: (state) => {
      state.paused = true;
    },
    resume: (state) => {
      state.paused = false;
    },
    handleStart: (state, action) => {
      state.active = true;
      state.paused = false;
    },
    handlePause: (state) => {
      state.active = true;
      state.paused = true;
    },
    handleResume: (state) => {
      state.active = true;
      state.paused = false;
    },
    handleReset: (state) => {
      state.active = false;
      state.paused = true;
    },
    endSession: (state) => {
      state.activeSession = null;
      state.sessionNote = null;
      state.start = null;
      state.timer = 0;
      state.active = false;
      state.paused = false;
      state.notePath = null;
    },
    savedHandler: (state, action) => {
      state.saved = action.payload;
    },
  },
});

export const {
  setActiveSession,
  setSessionNote,
  setStart,
  setTimer,
  increment,
  activate,
  deactivate,
  pause,
  resume,
  handleStart,
  handlePause,
  handleResume,
  handleReset,
  endSession,
  savedHandler,
  setNotePath,
} = sessionSlice.actions;

export default sessionSlice.reducer;
