import React, { Fragment } from "react";
import styles from "./PageHeader.module.css";
import { Col, Row } from "react-bootstrap";
import { Box, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "../../Theme/Theme";

const PageHeader = (props) => {
  return (
    <Fragment>
      <ThemeProvider theme={Theme}>
        <Box
          backgroundColor={
            props.invert ? "#ffff" : Theme.palette.secondaryColor
          }
          color={props.invert && "#000"}
          className={props.styleClass || styles.Box}
          sx={{
            border: props.invert ? "1.5px solid #e8e8e8" : "none",
          }}
        >
          <Col md={12} style={{ height: "100%" }}>
            {props.fullLeft || props.fullRight || props.fullCenter ? (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  textAlign: props.fullLeft
                    ? "left"
                    : props.fullRight
                    ? "right"
                    : "center",
                }}
              >
                {props.fullLeft
                  ? props.fullLeft
                  : props.fullRight
                  ? props.fullRight
                  : props.fullCenter}
              </Grid>
            ) : (
              <Row style={{ height: "100%" }}>
                <Col
                  className="d-flex align-items-center"
                  style={{ fontSize: "16px", fontWeight: 700, height: "100%" }}
                >
                  {props.left}
                </Col>
                {!props.noCenter && (
                  <Col
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      height: "100%",
                    }}
                  >
                    {props.center}
                  </Col>
                )}
                <Col className="d-flex justify-content-end align-items-center">
                  {props.right}
                </Col>
              </Row>
            )}
          </Col>
        </Box>
      </ThemeProvider>
    </Fragment>
  );
};

export default PageHeader;
