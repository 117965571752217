import React from "react";
import { Box, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import classes from "./Tile.module.css";
import { useNavigate } from "react-router-dom";

import Theme from "../../Theme/Theme";
import { ThemeProvider } from "@mui/private-theming";

const Tile = (props) => {
  const navigate = useNavigate();

  const handleOnClick = () => navigate(props.to, { replace: true });

  return (
    <ThemeProvider theme={Theme}>
      <Box
        sx={{
          width: "100%",
          height: props.small ? 160 : 211,
          bgcolor: "#F0F3EF",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          cursor: "pointer",
          "&:hover": {
            bgcolor: "rgba(240, 243, 239, 0.9)",
            boxShadow: "0px 0px 27px 1px #00000026",
          },
        }}
        onClick={handleOnClick}
      >
        <div
          className={props.small ? classes.SmallCircle : classes.Circle}
        >
          <Icon
            icon={props.icon}
            className={props.small ? classes.SmallIcon : classes.Icon}
          />
        </div>
        <Typography fontWeight={700} fontSize={14}>
          {props.text}
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export default Tile;
