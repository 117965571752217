import React from "react";
import "./Button.module.css";
import { styled } from "@mui/material/styles";
import { default as MuiButton } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Theme from "../../Theme/Theme";

const hex = Theme.palette.primaryColor.backgroundColor;

const Button = ({ mAuto, noWrap, ...props }) => {
  let BootstrapButton = {
    textTransform: "none",
    border: "none",
    borderRadius: 0,
    lineHeight: 1.5,
    borderColor: "#0063cc",
  };
  if (mAuto === true) {
    BootstrapButton.marginLeft = "auto !important";
  }

  const ButtonLight = styled(MuiButton)({
    ...BootstrapButton,

    minWidth: props.minWidth || "0 !important",
    backgroundColor: props.background || "#AA95D1",
    margin: props.margin || 0,
    padding: props.padding || "6px 12px",
    whiteSpace: noWrap ? "nowrap" : "normal",
    fontSize: props.fontSize || "14px",
    boxShadow: props.boxShadow || "none",
    width: props.width || "auto",
    fontWeight: 600,
    ...props.styles,
    "&:hover": {
      backgroundColor: props.background || "#AA95D1",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#AA95D1",
    },
  });

  const ButtonDark = styled(MuiButton)({
    ...BootstrapButton,
    position: props.postion,
    right: props.right,
    zIndex: props.zIndex,
    backgroundColor: hex,
    whiteSpace: noWrap ? "nowrap" : "normal",
    minWidth: props.minWidth || "0 !important",
    margin: props.margin || 0,
    padding: props.padding || "6px 12px",
    fontSize: props.fontSize || "14px",
    boxShadow: props.boxShadow || "none",
    width: props.width || "auto",
    ...props.styles,
    "&:hover": {
      backgroundColor: hex,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: hex,
    },
  });

  return (
    <Stack spacing={9} direction="row">
      {props.bgLight ? (
        <ButtonLight
          value={props.value}
          type={props.type}
          variant="contained"
          onClick={props.onClickAction}
          onMouseDown={props.onMouseDownAction}
          endIcon={props.endIcon}
          startIcon={props.startIcon}
          disabled={props.disabled}
        >
          {props.text}
        </ButtonLight>
      ) : (
        <ButtonDark
          value={props.value}
          variant="contained"
          onClick={props.onClickAction}
          onMouseDown={props.onMouseDownAction}
          endIcon={props.endIcon}
          startIcon={props.startIcon}
          disabled={props.disabled}
        >
          {props.text}
        </ButtonDark>
      )}
    </Stack>
  );
};

export default Button;
