import React, { Fragment, useEffect, useState } from "react";
import DataTable from "../../Layout/DataTable/DataTable";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Button from "../../UI/Button/Button";
import { Icon } from "@iconify/react";
import { useNavigate, useLocation } from "react-router-dom";

// Import these files for breadcrumbs
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";

import CautionAlert from "../../utils/CautionAlert";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import LoaderWithoutBackdrop from "../../UI/Loader/LoaderWithoutBackdrop";
import Loader from "../../UI/Loader/Loader";
import { checkPrivilage } from "../../utils/checkPrivilage";
import columns from "../../Resources/mimsSubColumns";
import Theme from "../../Theme/Theme";
import { DropdownButton } from "react-bootstrap";
import ActionButton from "../../UI/ActionButton/ActionButtonBox";

const MimsSubscriptions = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const [switchItem, setSwitchItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoader(true);
    await axios({
      method: "GET",
      url: `${backendUrl}/api/mims_subscription`,
      withCredentials: true,
    })
      .then((response) => {
        if (response.data?.data) {
          var dt = response.data?.data;
          // dt = dt.map((item) => {
          //   return {
          //     ...item,
          //     action: (
          //       <Fragment>
          //         <style type="text/css">
          //           {`
          //                 .ActionButton button {
          //                   background-color:${Theme.palette.primaryColor.backgroundColor};
          //                 }
          //                 .ActionButton button:hover {
          //                   background-color:${Theme.palette.secondaryColor.backgroundColor};
          //                 }
          //                 .ActionButton button:focus {
          //                   background-color:${Theme.palette.secondaryColor.backgroundColor};
          //                 }
          //             `}
          //         </style>
          //         <DropdownButton
          //           align="end"
          //           title="Actions"
          //           id="dropdown-menu-align-start"
          //           className="ActionButton"
          //           disabled={item.status !== "Active"}
          //         >
          //           {item.status === "Active" && (
          //             <ActionButton
          //               content="Update"
          //               onClickAction={() =>
          //                 navigate(`/app/update/mims-subscription/${item.id}`, {
          //                   state: { data: item },
          //                 })
          //               }
          //               icon="ep:edit"
          //               eventKey="1"
          //             />
          //           )}
          //           {item.status === "Active" && (
          //             <ActionButton
          //               content="Delete"
          //               onClickAction={() => setDeleteItem(item)}
          //               icon="fluent:delete-24-regular"
          //               eventKey="1"
          //             />
          //           )}
          //         </DropdownButton>
          //       </Fragment>
          //     ),
          //   };
          // });

          setData(dt);
          setLoader(false);
        } else {
          setApiError("Something went wrong. Please try again");
          setLoader(false);
        }
      })
      .catch((e) => {
        setApiError("Something went wrong. Please try again");
        setLoader(false);
      });
  };

  const confirmActivate = () => {
    activationHandler(switchItem);
    setSwitchItem(null);
  };

  const activationHandler = async (item) => {
    setLoader(true);
    await axios({
      method: "PATCH",
      withCredentials: true,
      data: { active: !item.active },
      url: `${backendUrl}/api/site/${item.id}`,
    })
      .then(() => {
        setLoader(false);
        setSuccess(
          `Site has been ${
            item.active ? "deactivated" : "activated"
          } successfully`
        );
        loadData();
      })
      .catch(function (error) {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };

  const deletehandler = async (item) => {
    setLoader(true);
    await axios({
      method: "DELETE",
      withCredentials: true,
      url: `${backendUrl}/api/site/${item.id}`,
    })
      .then(() => {
        setLoader(false);
        setSuccess(`Site has been successfully deleted`);
        loadData();
      })
      .catch(function (error) {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };

  const deleteMimsUser = async (item) => {
    setLoader(true);
    setDeleteItem(null);
    await axios({
      method: "DELETE",
      withCredentials: true,
      url: `${backendUrl}/api/mims/delete_user/${item.mims_user_id}`,
    })
      .then((response) => {
        setLoader(false);
        // loadUser(false);
        setSuccess(
          "Your request to delete the user account is successfully submitted to MIMS",
          true
        );
      })
      .catch((e) => {
        setLoader(false);
        if (e.response?.data?.message) {
          setError(e.response?.data?.message, true);
        } else {
          setError("Something went wrong. Please try again", true);
        }
      });
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/mims-management",
      iconName: "fa6-solid:capsules",
      name: "MIMS Management",
    },
  ];

  const retry = () => {
    setApiError(null);
    setTimeout(() => {
      loadData();
    }, 100);
  };

  const handleDelete = async (id) => {
    setDeleteItem(null);
    setLoader(true);
    await axios({
      method: "DELETE",
      withCredentials: true,
      url: `${backendUrl}/api/site/${id}`,
    })
      .then((response) => {
        setLoader(false);
        setSuccess("Site has been successfully deleted");
        loadData();
      })
      .catch(function (error) {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };

  return (
    <Fragment>
      <Loader open={loader} />
      {/* <CautionAlert
        severity="error"
        open={open}
        content="Are you sure you want to delete this item?"
        onCloseActionB={deleteConfirm}
        onCloseActionA={handleClose}
        okText="Delete"
        cancelText="Cancel"
      />
       */}
      {deleteItem && (
        <CautionAlert
          severity="error"
          open={deleteItem}
          content={`Are you sure you want to delete this MIMS user?`}
          onCloseActionB={() => deleteMimsUser(deleteItem)}
          onCloseActionA={() => setDeleteItem(null)}
          okText="Yes"
          cancelText="No"
        />
      )}
      {error ? (
        <>
          <CautionAlert
            severity="error"
            open={error}
            content={error}
            oneButton={true}
            onCloseActionB={() => {
              setError(null);
            }}
            okText="Ok"
          />
        </>
      ) : null}
      {apiError && (
        <CautionAlert
          severity="error"
          open={apiError}
          content={apiError}
          onCloseActionB={retry}
          onCloseActionA={() => setApiError(null)}
          okText="Retry"
          cancelText="Dismiss"
        />
      )}
      <CautionAlert
        severity="success"
        success={true}
        open={success}
        content={success}
        onCloseActionB={() => setSuccess(null)}
        okText="Ok"
        oneButton={true}
      />
      {location.state?.showAlert && (
        <CautionAlert
          severity="success"
          success={true}
          open={location.state.showAlert}
          content={location.state.showAlert}
          onCloseActionB={() =>
            navigate("/app/mims/subscriptions", location.state.showAlert, {})
          }
          okText="Ok"
          oneButton={true}
        />
      )}
      <Breadcrumb options={breadCrumbData} />
      {showTable ? (
        <>
          <PageHeader
            left={"MIMS Subscriptions"}
          />

          {data && <DataTable data={data} columns={columns.mimsSubColumns} />}
        </>
      ) : null}
    </Fragment>
  );
};

export default MimsSubscriptions;
