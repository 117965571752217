import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import siteReducer from "./site";
import sessionReducer from "./session";
import errorReducer from "./error";
import publicAuthReducer from "./publicAuth";
import searchParamsReducer from "./searchParams"

export const store = configureStore({
  reducer: {
    auth: authReducer,
    publicAuth: publicAuthReducer,
    site: siteReducer,
    session: sessionReducer,
    error: errorReducer,
    searchParams: searchParamsReducer
  },
});
