import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  isLoggedIn: false,
  user: null,
};

const publicAuthSlice = createSlice({
  name: "publicAuth",
  initialState: INITIAL_STATE,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

export const { login, logout } = publicAuthSlice.actions;

export default publicAuthSlice.reducer;
