import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Alert, Backdrop, Grid } from "@mui/material";
import { Box } from "@mui/system";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import classes from "./UserManagement.module.css";
import theme from "../../Theme/Theme";
import { ThemeProvider } from "react-bootstrap";
import Button from "../../UI/Button/Button";
import PageFooter from "../../Layout/PageFooter/PageFooter";
import axios from "axios";
import SelectBox from "../../UI/FormGroup/SelectBox/SelectBox";
import TextBox from "../../UI/FormGroup/TextBox/TextBox";
import backendUrl from "../../utils/backendUrl";
import RadioGroup from "../../UI/FormGroup/RadioGroup/RadioGroup";
import storageService from "../../utils/localStorageHelpers";
import Loader from "../../UI/Loader/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoaderWithoutBackdrop from "../../UI/Loader/LoaderWithoutBackdrop";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import useMediaQuery from "@mui/material/useMediaQuery";
import CautionAlert from "../../utils/CautionAlert";
import ShowImage from "../../utils/ImageEdit/ShowImage";
import ImageEdit from "../../utils/ImageEdit/ImageEdit";

const EditUser = (props) => {
  const widthIs14 = useMediaQuery("(max-width:1399px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");
  const { site_id } = useParams();

  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [site, setSite] = useState(null);
  const [title, setTitle] = useState(null);
  const [titleError, setTitleError] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [middleName, setMiddleName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState(null);
  const [role, setRole] = useState(null);
  const [username, setUserName] = useState(null);
  const [usernameError, setUserNameError] = useState(null);
  const [error, setError] = useState(null);
  const [noData, setNoData] = useState(false);
  const [success, setSuccess] = useState(null);
  const [loader, setLoader] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [partTimeSubError, setPartTimeSubError] = useState(null);
  const [existError, setExistError] = useState(null);
  const [data, setData] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [deleteFile, setDeleteFile] = useState(null);
  const [photoDeleted, setPhotoDeleted] = useState(null);

  useEffect(() => {
    findUser();
  }, []);

  useEffect(() => {
    prefillData();
  }, [data]);

  const findUser = async () => {
    setLoader(true);
    if (location.state && location.state.user) {
      let a = location.state.user?.user_subscriptions?.find(
        (sub) => sub.status === "Active"
      );
      setData(location.state.user);
      setLoader(false);
    } else {
      await axios({
        method: "GET",
        withCredentials: true,
        url: `${backendUrl}/api/users?id=${id}`,
      })
        .then(async (gb) => {
          setLoader(false);
          const global = gb.data && gb.data.data;
          if (!global) {
            throw new Error("User not found");
          }

          setData(global);
        })
        .catch((e) => {
          setApiError("User not found");
          setLoader(false);
        });
    }
  };

  const prefillData = () => {
    if (data) {
      setTitle(data.title);
      setFirstName((data?.name).split(" ")?.[0]);
      setLastName((data?.name).split(" ")?.[1]);
      setEmail(data.email);
      setMobile(data.mobile);
      setSite(data.site);
    }
  };

  const titleHandler = (e) => {
    setTitleError();
    setTitle(e.target.value);
  };
  const titleValidation = (e) => {
    if (e && e.target) {
      setTitle(e.target.value);
    }
    if (!title) {
      setTitleError("Required");
    }
  };

  const firstNameHandler = (e) => {
    setFirstNameError();
    setFirstName(e.target.value);
  };

  const firstNameValidation = (e) => {
    if (e && e.target) {
      setFirstName(e.target.value);
    }

    if (!firstName) {
      setFirstNameError("Required");
    }
  };

  const middleNameHandler = (e) => {
    setMiddleName(e.target.value);
  };

  const lastNameHandler = (e) => {
    setLastNameError();
    setLastName(e.target.value);
  };

  const lastNameValidation = (e) => {
    if (e && e.target) {
      setLastName(e.target.value);
    }
    if (!lastName) {
      setLastNameError("Required");
    }
  };

  const emailHandler = (e) => {
    setEmailError();
    setEmail(e.target.value);
  };

  const emailValidation = (e) => {
    if (e && e.target) {
      setEmail(e.target.value);
    }

    if (!email) {
      setEmailError("Required");
    } else if (!isEmail(email)) {
      setEmailError("Invalid email address");
    }
  };

  const isEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const mobileHandler = (e) => {
    if (e.target.value.length > 10) {
      setMobile((prev) => prev);
      return;
    }

    setMobileError();
    setMobile(e.target.value);
  };

  const mobileValidation = (e) => {
    if (e && e.target) {
      setMobile(e.target.value);
    }

    if (!mobile) {
      setMobileError("Required");
    }

    if (mobile && mobile.length < 10) {
      setMobileError("Mobile number should be 10 digits");
    }
  };

  const usernameHandler = (e) => {
    setUserNameError();
    setUserName(e.target.value);
  };

  const usernameValidation = async (e) => {
    if (e && e.target) {
      setUserName(e.target.value);
    }
    if (!username) {
      setUserNameError("Required");
    }

    if (username.length < 8) {
      setUserNameError("Username must be at least 8 characters");
    }
  };

  const photoHandler = (img) => {
    setPhoto(img);
  };

  const TitleOption = [
    {
      value: "Dr",
      label: "Dr",
    },
    {
      value: "Miss",
      label: "Miss",
    },
    {
      value: "Mr",
      label: "Mr",
    },
    {
      value: "Ms",
      label: "Ms",
    },
    {
      value: "Prof",
      label: "Prof",
    },
    {
      value: "Rev",
      label: "Rev",
    },
    {
      value: "Sir",
      label: "Sir",
    },
  ];

  const submitValidationHandler = () => {
    titleValidation();
    firstNameValidation();
    lastNameValidation();
    mobileValidation();
  };

  const submitValidation = () => {
    if (!title || !firstName || !lastName || !mobile) {
      return false;
    } else if (mobile && mobile.length < 10) {
      return false;
    } else {
      return true;
    }
  };

  const submitHandler = async () => {
    const toProceed = submitValidation();

    if (!toProceed) {
      submitValidationHandler();
      setLoader(false);
      return;
    }

    return submitFunction();
  };

  const submitFunction = async () => {
    setLoader(true);
    await axios({
      method: "PATCH",
      url: `${backendUrl}/api/user/${id}`,
      data: {
        title,
        name: firstName + " " + lastName,
        email,
        mobile,
      },
      withCredentials: true,
    })
      .then(async (res) => {
        if (data.role.name === "Site Admin") {
          const body = {
            title,
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            mobile,
          };
          const formData = new FormData();
          formData.append("photo", photo);
          formData.append("body", JSON.stringify(body));
          await axios({
            method: "PATCH",
            url: `${backendUrl}/${site.site_name}/api/site_user/user/${id}`,
            data: formData,
            withCredentials: true,
            headers: { "Content-Type": "multipart/form-data" },
          }).catch((e) => {
            setLoader(false);
            setError("This username already exist");
          });
        }

        setLoader(false);
        // successHandler("User has been successfully added");
        navigate(`/app/users`, {
          state: {
            showAlert: "User has been successfully updated",
          },
        });
      })
      .catch((error) => {
        setLoader(false);

        if (error?.response?.data?.message?.includes("username")) {
          // setExistError(error.response.data.message);
          setError("This username already exist");
          // setTimeout(() => {
          //   setExistError();
          // }, 3000);
          return;
        }

        setError("Something went wrong. Please try again");
      });
  };
  const handleDelete = useCallback((file) => {
    setDeleteFile(file);
  }, []);
  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/sites-management",
      iconName: "bi:building",
      name: "Sites Management",
    },
    // {
    //   link: "#",
    //   iconName: "carbon:user-follow",
    //   name: "Add User",
    // },
  ];

  const retry = () => {
    setError(null);
    setTimeout(() => {
      findUser();
    }, 100);
  };

  console.log(data);
  

  return (
    <ThemeProvider theme={theme}>
      <Breadcrumb options={breadCrumbData} />
      <Loader open={loader} />
      <CautionAlert
        severity="error"
        open={apiError}
        content={apiError}
        onCloseActionB={retry}
        onCloseActionA={() => setApiError(null)}
        okText="Retry"
        cancelText="Dismiss"
      />
      {success ? (
        <CautionAlert
          severity="success"
          success={true}
          open={success}
          content={success}
          oneButton={true}
          onCloseActionB={() => {
            setSuccess(null);
          }}
          okText="Ok"
        />
      ) : null}

      {error ? (
        <>
          <CautionAlert
            severity="error"
            open={error}
            content={error}
            oneButton={true}
            onCloseActionB={() => {
              setError(null);
            }}
            okText="Ok"
          />
        </>
      ) : null}

      <PageHeader left="Edit User" />
      <form>
        {noData ? (
          ""
        ) : (
          <Box className={classes.FormBox}>
            <Grid container direction="row" spacing={2}>
              {/* First Column */}
              <Grid
                container
                item
                xs={widthIs14 ? 12 : 6}
                direction="column"
                sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
                className={classes.Max}
              >
                <SelectBox
                  xsLabel={4}
                  xsInside={8}
                  label="Title"
                  options={TitleOption}
                  value={title}
                  onChangeAction={titleHandler}
                  onBlurAction={titleValidation}
                  error={titleError}
                  required
                />
                <TextBox
                  xsLabel={4}
                  xsInside={8}
                  type="text"
                  label="First Name"
                  onChangeAction={firstNameHandler}
                  onBlurAction={firstNameValidation}
                  value={firstName}
                  error={firstNameError}
                  placeholder="First Name"
                  required
                />
                <TextBox
                  xsLabel={4}
                  xsInside={8}
                  type="text"
                  label="Last Name"
                  onChangeAction={lastNameHandler}
                  onBlurAction={lastNameValidation}
                  value={lastName}
                  error={lastNameError}
                  placeholder="Last Name"
                  required
                />
              </Grid>

              {/* Second Column */}
              <Grid
                container
                item
                xs={widthIs14 ? 12 : 6}
                direction="column"
                className={classes.Max}
                sx={{
                  marginLeft: widthIs1630 ? "" : "80px",
                  maxWidth: widthIs14 ? "" : "590px !important",
                }}
              >
                <TextBox
                  xsLabel={4}
                  xsInside={8}
                  type="email"
                  label="Email"
                  onChangeAction={emailHandler}
                  onBlurAction={emailValidation}
                  value={email}
                  error={emailError}
                  placeholder="Email"
                  required
                  disabled={data?.status === "Active"}
                />
                <TextBox
                  type="number"
                  label="Mobile Number"
                  onChangeAction={mobileHandler}
                  onBlurAction={mobileValidation}
                  value={mobile}
                  error={mobileError}
                  placeholder="Mobile number"
                  required
                />
                {/* {roleOption ? (
                  <SelectBox
                    label="Role"
                    options={roleOption}
                    value={role}
                    onChangeAction={roleHandler}
                    onBlurAction={roleValidation}
                    error={roleError}
                    required
                  />
                ) : (
                  <SelectBox
                    label="Role"
                    value={role}
                    onChangeAction={roleHandler}
                    onBlurAction={roleValidation}
                    error={roleError}
                    required
                    disabled
                  />
                )} */}
                <TextBox
                  type="text"
                  label="Username"
                  value={data?.username}
                  placeholder="User Name"
                  disabled
                />
                {data?.photo ? (
                  <ShowImage
                    image={data?.photo}
                    handleDelete={handleDelete}
                    name="photo"
                    deleted={photoDeleted}
                  />
                ) : null}
                <Grid container spacing={2} mb={1}>
                  <Grid item xs={4} className={`LabelBox`}>
                    Profile Photo
                  </Grid>
                  <Grid item xs={8}>
                    <ImageEdit
                      image={photo}
                      dataHandler={photoHandler}
                      ratio={7 / 8}
                      value={data?.photo}
                      clear={()=> setPhoto(null)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
        <PageFooter
          right={
            <Fragment>
              {noData ? (
                ""
              ) : (
                <Box
                  sx={{
                    px: 2,
                  }}
                >
                  <Button text="Update" onMouseDownAction={submitHandler} />
                </Box>
              )}

              <Box>
                <Button
                  fontSize="14px"
                  onMouseDownAction={(e) => navigate(-1)}
                  bgLight={true}
                  text="Cancel"
                />
              </Box>
            </Fragment>
          }
        />
      </form>
    </ThemeProvider>
  );
};

export default EditUser;
