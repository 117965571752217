import React, { Fragment, useState } from "react";
import { Grid } from "@mui/material";
import classes from "./TextBox.module.css";

export default function TextInput(props) {
  return (
    <Fragment>
      <Grid container spacing={2} sx={props.sxContainer || ""}>
        {props.noLabel ? (
          ""
        ) : (
          <Grid
            item
            sx={props.sxLabel || ""}
            xs={props.xsLabel || 4}
            className={`LabelBox`}
          >
            <label>
              {props.label}
              {props.required ? <sup>*</sup> : null}
            </label>
          </Grid>
        )}

        <Grid item sx={props.sx || ""} xs={props.xsInside || 8}>
          <input
            className={`InputPrimary ${props.error ? "ErrorBorder" : null} ${
              props.disabled === true ? "disabled" : null
            }`}
            value={props.value}
            onChange={props.onChangeAction}
            onBlur={props.onBlurAction}
            onKeyDown={
              props.numberOnly
                ? (e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                : ""
            }
            // placeholder={props.placeholder}
            type={props.type}
            disabled={props.disabled}
            name={props.name}
            onWheel={(e) => e.target.blur()}
          />
          {/* <label>Title<sup>*</sup></label> */}
          {props.error && props.error !== true ? (
            <label className={classes.ErrorMsg}>{props.error}</label>
          ) : null}
        </Grid>
      </Grid>
    </Fragment>
  );
}
