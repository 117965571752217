import React, { useState, useRef, useEffect } from "react";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { ThemeProvider } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Button from "../../UI/Button/Button";

import "react-image-crop/dist/ReactCrop.css";
import { Typography } from "@mui/material";
import { Icon } from "@iconify/react/dist/iconify.js";
import classes from "./style.module.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    maxWidth: "700px",
    minWidth: "700px",
    borderRadius: "0px",
    maxHeight: "95vh",
  },

  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
    borderRadius: 0,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    borderRadius: 0,
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        textAlign: "center",
        backgroundColor: "#615586",
        color: "white",
      }}
      {...other}
    >
      {children}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function ImageEdit({
  dataHandler,
  ratio,
  value,
  noClear,
  clear,
}) {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(ratio);
  const [open, setOpen] = useState(false);
  const fileRef = useRef();

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
      setOpen(true);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const handleCreateFile = (cb) => {
    const canvas = previewCanvasRef.current;
    canvas.toBlob((blob) => {
      const file = new File([blob], "new_file.jpg", { type: "image/jpeg" });
      cb(file);
    }, "image/jpeg");
  };

  const completedCropHandler = (c) => {
    setCompletedCrop(c);
  };

  const closeHandler = () => {
    setOpen(false);
    let retrieveFile = (v) => {
      dataHandler(v);
    };
    handleCreateFile(retrieveFile);
  };

  function handleClear() {
    fileRef.current.value = "";
    setCompletedCrop(null);
    clear();
    setOpen(false);
  }

  return (
    <div className="App">
      <ThemeProvider>
        <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            style={{ textAlign: "left" }}
          >
            Edit Image
          </BootstrapDialogTitle>
          <DialogContent
            dividers
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight:"50vh"
            }}
          >
            {!!imgSrc && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={completedCropHandler}
                aspect={aspect}
                style={{ maxHeight: "75vh" }}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
          </DialogContent>
          <DialogActions>
            <Button text="Save" onMouseDownAction={closeHandler} />
            {fileRef?.current?.value && !noClear ? (
              <Button text="Close" onMouseDownAction={handleClear} />
            ) : null}
          </DialogActions>
        </BootstrapDialog>
      </ThemeProvider>
      <div className={`Crop-Controls ${classes.FileInput}`}>
        <input
          className="InputPrimary BackWhite"
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          style={{ marginBottom: "10px" }}
          ref={fileRef}
        />
        {fileRef?.current?.value && !noClear ? (
          <Icon
            className={classes.IconButton}
            onClick={handleClear}
            icon="ci:close-big"
          />
        ) : null}
      </div>
      <div>
        <Typography variant="caption" display="block" gutterBottom>
          <ul>
            <li>Maximum upload size: 5 MB.</li>
            <li>Allowed format : JPEG, PNG</li>
          </ul>
        </Typography>
      </div>
      {!!completedCrop && (
        <>
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                objectFit: "contain",
                width: 100,
                height: 200,
              }}
            />
          </div>
          {/* <div>
      <button onClick={onDownloadCropClick}>Download Crop</button>
      <a
        ref={hiddenAnchorRef}
        download
        style={{
          position: "absolute",
          top: "-200vh",
          visibility: "hidden",
        }}
      >
        Hidden download
      </a>
    </div> */}
        </>
      )}
    </div>
  );
}
