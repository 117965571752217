import React, { Fragment, useEffect, useState } from "react";
import DataTable from "../../Layout/DataTable/DataTable";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Button from "../../UI/Button/Button";
import { Icon } from "@iconify/react";
import { useNavigate, useLocation } from "react-router-dom";

// Import these files for breadcrumbs
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";

import CautionAlert from "../../utils/CautionAlert";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import LoaderWithoutBackdrop from "../../UI/Loader/LoaderWithoutBackdrop";
import Loader from "../../UI/Loader/Loader";
import { checkPrivilage } from "../../utils/checkPrivilage";
import columns from "./../../Resources/RolesTableColumns";
import Theme from "../../Theme/Theme";
import { DropdownButton } from "react-bootstrap";
import ActionButton from "../../UI/ActionButton/ActionButtonBox";
import { Box } from "@mui/material";
import AddFeaturePopup from "./AddFeaturePopup";

const RolesManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const [switchItem, setSwitchItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [openAddFeature, setOpenAddFeature] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoader(true);
    await axios({
      method: "GET",
      url: `${backendUrl}/api/role`,
      withCredentials: true,
    })
      .then((response) => {
        if (response.data?.data) {
          var dt = response.data?.data;
          dt = dt.map((item) => {
            return {
              ...item,
              action: (
                <Fragment>
                  <style type="text/css">
                    {`
                          .ActionButton button {
                            background-color:${Theme.palette.primaryColor.backgroundColor};
                          }
                          .ActionButton button:hover {
                            background-color:${Theme.palette.secondaryColor.backgroundColor};
                          }
                          .ActionButton button:focus {
                            background-color:${Theme.palette.secondaryColor.backgroundColor};
                          }
                      `}
                  </style>
                  <DropdownButton
                    align="end"
                    title="Actions"
                    id="dropdown-menu-align-start"
                    className="ActionButton"
                  >
                    <ActionButton
                      content="Edit"
                      onClickAction={() =>
                        navigate(`/app/edit/role/${item.id}`, {
                          state: { data: item },
                        })
                      }
                      icon="ep:edit"
                      eventKey="1"
                    />
                    <ActionButton
                      content="Permissions"
                      onClickAction={() =>
                        navigate(`/app/role/permissions/${item.id}`)
                      }
                      icon="fluent-mdl2:permissions-solid"
                      eventKey="1"
                    />
                  </DropdownButton>
                </Fragment>
              ),
            };
          });

          setData(dt);
          setLoader(false);
        } else {
          setApiError("Something went wrong. Please try again");
          setLoader(false);
        }
      })
      .catch((e) => {
        
        setApiError("Something went wrong. Please try again");
        setLoader(false);
      });
  };

  const confirmActivate = () => {
    activationHandler(switchItem);
    setSwitchItem(null);
  };

  const activationHandler = async (item) => {
    setLoader(true);
    await axios({
      method: "PATCH",
      withCredentials: true,
      data: { active: !item.active },
      url: `${backendUrl}/api/site/${item.id}`,
    })
      .then(() => {
        setLoader(false);
        setSuccess(
          `Site has been ${
            item.active ? "deactivated" : "activated"
          } successfully`
        );
        loadData();
      })
      .catch(function (error) {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "#",
      iconName: "eos-icons:role-binding",
      name: "Roles Management",
    },
  ];

  const retry = () => {
    setApiError(null);
    setTimeout(() => {
      loadData();
    }, 100);
  };

  const handleDelete = async (id) => {
    setDeleteItem(null);
    setLoader(true);
    await axios({
      method: "DELETE",
      withCredentials: true,
      url: `${backendUrl}/api/site/${id}`,
    })
      .then((response) => {
        setLoader(false);
        setSuccess("Site has been successfully deleted");
        loadData();
      })
      .catch(function (error) {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };

  const saveFeatureHandler = async (feature) => {
    setLoader(true);
    setOpenAddFeature(false);
    let body = {
      feature,
    };

    await axios
      .post(`${backendUrl}/api/add/feature`, body, { withCredentials: true })
      .then((res) => {
        setLoader(false);
        setSuccess("Feature has been added successfully");
      })
      .catch((e) => {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };

  return (
    <Fragment>
      <Loader open={loader} />
      {openAddFeature && (
        <AddFeaturePopup
          open={openAddFeature}
          closeHandler={() => setOpenAddFeature(false)}
          saveFeatureHandler={saveFeatureHandler}
        />
      )}

      {/* <CautionAlert
        severity="error"
        open={open}
        content="Are you sure you want to delete this item?"
        onCloseActionB={deleteConfirm}
        onCloseActionA={handleClose}
        okText="Delete"
        cancelText="Cancel"
      />
       */}
      {switchItem && (
        <CautionAlert
          severity="error"
          open={switchItem}
          content={`Are you sure you want to ${
            switchItem?.active ? "deactivate" : "activate"
          } this site`}
          onCloseActionB={confirmActivate}
          onCloseActionA={() => setSwitchItem(null)}
          okText="Yes"
          cancelText="No"
        />
      )}
      <CautionAlert
        severity="error"
        open={deleteItem}
        content="Are you sure you want to delete this record?"
        onCloseActionB={() => handleDelete(deleteItem)}
        onCloseActionA={() => setDeleteItem(null)}
        okText="Delete"
        cancelText="Cancel"
      />
      {apiError ? (
        <CautionAlert
          severity="error"
          open={apiError}
          content={apiError}
          onCloseActionA={() => setApiError()}
          onCloseActionB={retry}
          okText="Retry"
          cancelText="Dismiss"
        />
      ) : null}

      <CautionAlert
        severity="error"
        open={error}
        content={error}
        onCloseActionB={() => setError(null)}
        okText="Ok"
        oneButton={true}
      />

      <CautionAlert
        severity="success"
        success={true}
        open={success}
        content={success}
        onCloseActionB={() => setSuccess(null)}
        okText="Ok"
        oneButton={true}
      />
      {location.state?.showAlert && (
        <CautionAlert
          severity="success"
          success={true}
          open={location.state.showAlert}
          content={location.state.showAlert}
          onCloseActionB={() =>
            navigate("/app/roles-management", location.state.showAlert, {})
          }
          okText="Ok"
          oneButton={true}
        />
      )}

      <Breadcrumb options={breadCrumbData} />

      {showTable ? (
        <>
          <PageHeader
            left={"Roles"}
            right={
              <Fragment>
                <Box
                  sx={{
                    px: 2,
                  }}
                >
                  <Button
                    // disabled={openAddUser === true ? true : false}
                    onClickAction={() => setOpenAddFeature(true)}
                    text="Add Feature"
                    startIcon={
                      <Icon
                        style={{ fontSize: "14px" }}
                        icon="akar-icons:plus"
                      />
                    }
                  ></Button>
                </Box>
                <Box>
                  <Button
                    // disabled={openAddUser === true ? true : false}
                    onClickAction={() => navigate("/app/add/role")}
                    text="Add Roles"
                    startIcon={
                      <Icon
                        style={{ fontSize: "14px" }}
                        icon="akar-icons:plus"
                      />
                    }
                  ></Button>
                </Box>
              </Fragment>
            }
          />

          {data && (
            <DataTable data={data} columns={columns.rolesTableColumns} />
          )}
        </>
      ) : null}
    </Fragment>
  );
};

export default RolesManagement;
