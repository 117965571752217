import { store } from "./../../redux/store";

export const checkPrivilage = (module, operation) => {
  const authState = store.getState();
  const { privilages } = authState.auth;
  const permission = privilages?.find(
    (p) => p.name === module && p.permission !== "0000" && p.active === true
  );
  if (!permission) {
    return false;
  }

  /**
   * Checking permission
   * permission is a string Eg: '1111' or '1001'
   */
  const pList = ["CREATE", "READ", "UPDATE", "DELETE"];

  const myPer = permission?.permission.split("");

  const i = pList.indexOf(operation);

  /**
   * value of operation, It might be '1' or '0'
   */
  const v = myPer[i];

  if (v === "1") {
    return true;
  } else {
    return false;
  }
};

export const checkAnyPrivilage = (module) => {
  const authState = store.getState();
  const { privilages } = authState.auth;
  const permission = privilages?.find(
    (p) => p.name === module && p.permission !== "0000" && p.active === true
  );

  if (!permission) {
    return false;
  } else {
    return true;
  }
};
