import React, { Fragment, useEffect, useState } from "react";
import { Alert, Backdrop, Grid, Select } from "@mui/material";
import { Box } from "@mui/system";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import classes from "./MimsManagement.module.css";
import theme from "../../Theme/Theme";
import { ThemeProvider } from "react-bootstrap";
import Button from "../../UI/Button/Button";
import PageFooter from "../../Layout/PageFooter/PageFooter";
import axios from "axios";
import SelectBox from "../../UI/FormGroup/SelectBox/SelectBox";
import TextBox from "../../UI/FormGroup/TextBox/TextBox";
import backendUrl from "../../utils/backendUrl";
import RadioGroup from "../../UI/FormGroup/RadioGroup/RadioGroup";
import storageService from "../../utils/localStorageHelpers";
import Loader from "../../UI/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import LoaderWithoutBackdrop from "../../UI/Loader/LoaderWithoutBackdrop";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import useMediaQuery from "@mui/material/useMediaQuery";
import CautionAlert from "../../utils/CautionAlert";
import SearchSite from "../../UI/SearchSite/SearchSite";
import SearchUserBox from "../../UI/SearchUserBox/SearchUserBox";
import SelectBoxNonContainer from "../../UI/FormGroup/SelectBox/SelectBoxNonContainer";
import TextInput from "../../UI/FormGroup/TextBox/TextInput";
import TextInputNonContainer from "../../UI/FormGroup/TextBox/TextInputNonContainer";
import moment from "moment";

const UpdateMimsUser = (props) => {
  const widthIs14 = useMediaQuery("(max-width:1399px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");
  const navigate = useNavigate();

  const location = useLocation();

  const [user, setUser] = useState(null);
  const [userError, setUserError] = useState(null);
  const [roleOption, setRoleOption] = useState(null);
  const [title, setTitle] = useState(null);
  const [titleError, setTitleError] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState(null);
  const [role, setRole] = useState(null);
  const [roleError, setRoleError] = useState(null);
  const [site, setSite] = useState(null);
  const [siteError, setSiteError] = useState(null);
  useState(null);
  const [error, setError] = useState(null);
  const [noData, setNoData] = useState(false);
  const [success, setSuccess] = useState(null);
  const [loader, setLoader] = useState(false);
  const [showErrorBtn, setShowErrorBtn] = useState(true);
  useState(null);

  const [addressLine1, setAddressLine1] = useState(null);
  const [addressLine1Error, setAddressLine1Error] = useState(null);
  const [addressLine2, setAddressLine2] = useState(null);
  const [city, setCity] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [postcode, setPostcode] = useState("");
  const [postcodeError, setPostcodeError] = useState("");
  const [state, setState] = useState(null);
  const [stateError, setStateError] = useState(null);

  const [open, setOpen] = useState(null);
  const [existError, setExistError] = useState(null);

  useEffect(() => {
    if (location.state?.data) {
      let dt = location.state.data;
      setSite(dt.site);
      setUser(dt);
    }
  }, []);

  useEffect(() => {
    loadRoles();
  }, []);
  useEffect(() => {
    if (user) {
      user.role = {
        ...user.role,
        value: user.role.id,
        label: user.role.name,
      };
      setTitle("Mr");
      setFirstName(user.name.split(" ")?.[0]);
      setLastName(user.name.split(" ")?.[1]);
      setRole(user.role);
      setEmail(user.email);
      setMobile(user.mobile);
    }
  }, [user]);

  useEffect(() => {
    if (site) {
      setUser(null);
      setAddressLine1(site.address_line_one);
      setAddressLine2(site.address_line_two);
      setCity(site.suburb);
      setState(site.state);
      setPostcode(site.post_code);
    }
  }, [site]);

  const loadRoles = async () => {
    setLoader(false);
    await axios({
      medthod: "GET",
      url: `${backendUrl}/api/role`,
      withCredentials: true,
      params: {
        list_for_site: true,
      },
    })
      .then((response) => {
        setNoData(false);
        let arr = [];

        response.data.data.map((role) => {
          arr.push({
            ...role,
            label: role.name,
            value: role.id,
          });
        });

        setRoleOption(arr);
        setLoader(false);
      })
      .catch((e) => {
        setNoData(true);
        setError("Something went wrong. Please try again");
        setLoader(false);
      });
  };

  const titleHandler = (e) => {
    setTitleError();
    setTitle(e.target.value);
  };
  const titleValidation = (e) => {
    if (e && e.target) {
      setTitle(e.target.value);
    }
    if (!title) {
      setTitleError("Required");
    }
  };

  const firstNameHandler = (e) => {
    setFirstNameError();
    setFirstName(e.target.value);
  };

  const firstNameValidation = (e) => {
    if (e && e.target) {
      setFirstName(e.target.value);
    }

    if (!firstName) {
      setFirstNameError("Required");
    }
  };

  const lastNameHandler = (e) => {
    setLastNameError();
    setLastName(e.target.value);
  };

  const lastNameValidation = (e) => {
    if (e && e.target) {
      setLastName(e.target.value);
    }
    if (!lastName) {
      setLastNameError("Required");
    }
  };

  const emailHandler = (e) => {
    setEmailError();
    setEmail(e.target.value);
  };

  const emailValidation = (e) => {
    if (e && e.target) {
      setEmail(e.target.value);
    }

    if (!email) {
      setEmailError("Required");
    } else if (!isEmail(email)) {
      setEmailError("Invalid email address");
    }
  };

  const isEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const mobileHandler = (e) => {
    if (e.target.value.length > 10) {
      setMobile((prev) => prev);
      return;
    }

    setMobileError();
    setMobile(e.target.value);
  };

  const mobileValidation = (e) => {
    if (e && e.target) {
      setMobile(e.target.value);
    }
    if (!mobile) {
      setMobile("Required");
    }
    if (mobile && mobile.length < 10) {
      setMobileError("Mobile number should be 10 digits");
    }
  };

  const roleHandler = (e) => {
    setRoleError();

    let r = roleOption.find((item) => {
      return item.id === e.target.value;
    });

    setRole(r);
  };
  const roleValidation = (e) => {
    if (e && e.target) {
      setRole(e.target.value);
    }
    if (!role) {
      setRoleError("Required");
    }
  };

  const siteValidation = () => {
    if (!site) {
      setSiteError("Required");
      return false;
    }
  };

  const handleAddressLine1 = (e) => {
    setAddressLine1(e.target.value);
  };
  const addressLine1Validation = () => {
    if (!addressLine1) {
      setAddressLine1Error("Required");
    }
  };

  const handleAddressLine2 = (e) => {
    setAddressLine2(e.target.value);
  };
  const handleCity = (e) => {
    setCityError(null);
    setCity(e.target.value);
  };

  const cityValidation = () => {
    if (!city) {
      setCityError("Required");
    }
  };

  const handlePostcode = (e) => {
    setPostcodeError(null);
    if (e.target.value.length > 4) {
      setPostcode((prev) => prev);
      return;
    }
    setPostcode(e.target.value);
  };

  const postcodeValidation = () => {
    if (!postcode) {
      setPostcodeError("Required");
    }
  };

  const handleState = (e) => {
    setStateError(null);
    setState(e.target.value);
  };

  const stateValidation = () => {
    if (!state) {
      setStateError("Required");
    }
  };

  const TitleOption = [
    {
      value: "Dr",
      label: "Dr",
    },
    {
      value: "Miss",
      label: "Miss",
    },
    {
      value: "Mr",
      label: "Mr",
    },
    {
      value: "Ms",
      label: "Ms",
    },
    {
      value: "Prof",
      label: "Prof",
    },
    {
      value: "Rev",
      label: "Rev",
    },
    {
      value: "Sir",
      label: "Sir",
    },
  ];
  const stateOptions = [
    {
      label: "ACT",
      value: "ACT",
    },
    {
      label: "NSW",
      value: "NSW",
    },
    {
      label: "NT",
      value: "NT",
    },
    {
      label: "QLD",
      value: "QLD",
    },
    {
      label: "SA",
      value: "SA",
    },
    {
      label: "TAS",
      value: "TAS",
    },
    {
      label: "VIC",
      value: "VIC",
    },
    {
      label: "WA",
      value: "WA",
    },
  ];

  const userTypeOptions = [
    {
      label: "Full Time",
      value: "fulltime",
    },
    {
      label: "Part Time",
      value: "parttime",
    },
  ];

  const errorHandler = (msg, autoHidden, nullData) => {
    if (nullData) {
      setNoData(true);
    }
    setError(msg);
  };

  const submitValidationHandler = () => {
    titleValidation();
    firstNameValidation();
    lastNameValidation();
    emailValidation();
    mobileValidation();
    roleValidation();
    cityValidation();
    addressLine1Validation();
    stateValidation();
    postcodeValidation();
  };

  const submitValidation = () => {
    if (
      !title ||
      !firstName ||
      !addressLine1 ||
      !city ||
      !state ||
      !postcode ||
      !lastName ||
      !email ||
      !role
    ) {
      return false;
    } else if (!mobile || mobile.length < 10) {
      return false;
    } else {
      return true;
    }
  };

  const submitHandler = async () => {
    const toProceed = submitValidation();

    if (!toProceed) {
      submitValidationHandler();
      setLoader(false);
      return;
    }
    mimsUserAPIHandler();
  };

  

  /**
   * Individual Health Identifier API
   */

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/mims-management",
      iconName: "fa6-solid:capsules",
      name: "MIMS Management",
    },
    // {
    //   link: "#",
    //   iconName: "carbon:user-follow",
    //   name: "Add User",
    // },
  ];

  const handleClose = (value) => {
    setOpen(false);
  };

  const retry = () => {
    setError(null);
    setTimeout(() => {}, 100);
  };

  const selectedSite = (value) => {
    setSite(value);
  };

  const selectedUser = (value) => {
    setUser(value);
  };

  const userValidation = () => {
    if (!user) {
      setUserError("Required");
    }
  };

  const mimsUserAPIHandler = async () => {
    let dt = location.state?.data;
    setLoader(true);
    let body = {
      salutation: title,
      firstName: firstName,
      lastName: lastName,
      title: role.name,
      company: site.business_name,
      phone: mobile,
      referenceID: dt.id,
      address: addressLine1,
      city: city,
      state: state,
      postalCode: postcode,
      country: "AUS",
      from_admin: true,
      site_id: site.id,
      department: "Medipapel integrated"
    };

    await axios({
      method: "PATCH",
      withCredentials: true,
      url: `${backendUrl}/api/mims/user/update/${dt.mims_user_id}`,
      data: body,
    })
      .then((response) => {
        setLoader(false);
        navigate(`/app/mims/users`, {
          state: {
            showAlert: "MIMS account is updated successfully",
          },
        });
      })
      .catch((e) => {
        setLoader(false);
        if (e.response?.data?.message) {
          setError(e.response?.data?.message, true);
        } else {
          setError("Something went wrong. Please try again", true);
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Breadcrumb options={breadCrumbData} />
      <Loader open={loader} />

      {existError ? (
        <>
          <Alert
            severity="error"
            sx={{
              mb: 2,
              border: "1px solid red",
              zIndex: "99999 !important",
              position: "relative",
            }}
            action={<></>}
          >
            {existError}
          </Alert>
          <Backdrop
            sx={{ backgroundColor: "#00000000", zIndex: 500 }}
            open={existError}
          ></Backdrop>
        </>
      ) : null}

      {success ? (
        <CautionAlert
          severity="success"
          success={true}
          open={success}
          content={success}
          oneButton={true}
          onCloseActionB={() => {
            setSuccess(null);
          }}
          okText="Ok"
        />
      ) : null}

      {error ? (
        <>
          <CautionAlert
            severity="error"
            open={error}
            content={error}
            oneButton={true}
            onCloseActionB={() => {
              setError(null);
            }}
            okText="Ok"
          />
        </>
      ) : null}

      <PageHeader left="Update MIMS User" />
      <form>
        {noData ? (
          ""
        ) : (
          <Box className={classes.FormBox}>
            <Grid container direction="row" spacing={2}>
              {/* First Column */}
              <Grid
                container
                item
                xs={widthIs14 ? 12 : 6}
                direction="column"
                sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
                className={classes.Max}
              >
                {/* <SearchSite
                  xsLabel={4}
                  xsInside={8}
                  selectedSite={selectedSite}
                  inlineError={siteError}
                  value={site}
                  disabled={true}
                />
                <SearchUserBox
                  xsLabel={4}
                  xsInside={8}
                  selectedUser={selectedUser}
                  inlineError={userError}
                  value={user}
                  site={site}
                  disabled={true}
                /> */}
                <SelectBox
                  xsLabel={4}
                  xsInside={8}
                  label="Title"
                  options={TitleOption}
                  value={title}
                  onChangeAction={titleHandler}
                  onBlurAction={titleValidation}
                  error={titleError}
                  required
                  disabled
                />
                <TextBox
                  xsLabel={4}
                  xsInside={8}
                  type="text"
                  label="First Name"
                  onChangeAction={firstNameHandler}
                  onBlurAction={firstNameValidation}
                  value={firstName}
                  error={firstNameError}
                  placeholder="First Name"
                  required
                  disabled
                />
                <TextBox
                  xsLabel={4}
                  xsInside={8}
                  type="text"
                  label="Last Name"
                  onChangeAction={lastNameHandler}
                  onBlurAction={lastNameValidation}
                  value={lastName}
                  error={lastNameError}
                  placeholder="Last Name"
                  required
                  disabled
                />
                <TextBox
                  xsLabel={4}
                  xsInside={8}
                  type="email"
                  label="Email"
                  onChangeAction={emailHandler}
                  onBlurAction={emailValidation}
                  value={email}
                  error={emailError}
                  placeholder="Email"
                  required
                  disabled
                />
                <TextBox
                  type="number"
                  label="Mobile Number"
                  onChangeAction={mobileHandler}
                  onBlurAction={mobileValidation}
                  value={mobile}
                  error={mobileError}
                  placeholder="Mobile number"
                  required
                  disabled
                />
                {roleOption ? (
                  <SelectBox
                    label="Role"
                    options={roleOption}
                    value={role?.value}
                    onChangeAction={roleHandler}
                    onBlurAction={roleValidation}
                    error={roleError}
                    required
                    disabled
                  />
                ) : (
                  <SelectBox
                    label="Role"
                    value={role?.value}
                    onChangeAction={roleHandler}
                    onBlurAction={roleValidation}
                    error={roleError}
                    required
                    disabled
                  />
                )}
              </Grid>
              {/* Second Column */}
              <Grid
                container
                item
                xs={widthIs14 ? 12 : 6}
                direction="column"
                className={classes.Max}
                sx={{
                  marginLeft: widthIs1630 ? "" : "80px",
                  maxWidth: widthIs14 ? "" : "590px !important",
                }}
              >
                <TextInput
                  xsLabel={4}
                  xsInside={8}
                  type="text"
                  label="Address Line 1"
                  onChangeAction={handleAddressLine1}
                  onBlurAction={addressLine1Validation}
                  value={addressLine1}
                  error={addressLine1Error}
                  placeholder="Address Line 1"
                  required
                  disabled
                />
                <TextInput
                  xsLabel={4}
                  xsInside={8}
                  type="text"
                  label="Address Line 2"
                  onChangeAction={handleAddressLine2}
                  onBlurAction={addressLine1Validation}
                  value={addressLine2}
                  error={addressLine1Error}
                  placeholder="Address Line 2"
                  disabled
                />
                <SelectBox
                  sxInside={{ maxWidth: widthIs14 ? "" : "350px !important" }}
                  xsLabel={4}
                  xsInside={8}
                  lgInside={4.5}
                  label="State"
                  options={stateOptions}
                  value={state}
                  onChangeAction={handleState}
                  onBlurAction={stateValidation}
                  error={stateError}
                  required
                  disabled
                />
                <TextInput
                  // sxLabel={{maxWidth:"80px !important"}}
                  xsLabel={4}
                  xsInside={8}
                  type="number"
                  label="Postcode"
                  numberOnly
                  onChangeAction={handlePostcode}
                  onBlurAction={postcodeValidation}
                  value={postcode}
                  error={postcodeError}
                  placeholder="Postcode"
                  required
                  disabled
                />
                <TextInput
                  sxInside={{ maxWidth: widthIs14 ? "" : "350px !important" }}
                  xsLabel={4}
                  xsInside={8}
                  lgInside={4.5}
                  type="text"
                  label="City/Suburb"
                  onChangeAction={handleCity}
                  onBlurAction={cityValidation}
                  value={city}
                  error={cityError}
                  placeholder="City/Suburb"
                  required
                  disabled
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <PageFooter
          right={
            <Fragment>
              {noData ? (
                ""
              ) : (
                <Box
                  sx={{
                    px: 2,
                  }}
                >
                  <Button text="Update" onMouseDownAction={submitHandler} />
                </Box>
              )}

              <Box>
                <Button
                  fontSize="14px"
                  onMouseDownAction={(e) => navigate(-1)}
                  bgLight={true}
                  text="Cancel"
                />
              </Box>
            </Fragment>
          }
        />
      </form>
    </ThemeProvider>
  );
};

export default UpdateMimsUser;
