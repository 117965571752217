import React, { Fragment, useState } from "react";
import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import classes from './DurationInput.module.css'
import { default as Input } from "react-otp-input";


export default function DurationSelectInput(props) {

    const inputStyle = {
        width: "12px",
        border: "0",
        margin: "0 3px",
        backgroundColor: "transparent",
        outline: "none",
        fontSize: "13px",
        borderBottom: "1px solid #000",
      };
    
      const containerStyle = {
        height: "42px",
        marginRight: "5px",
        border: "1.5px solid #E8E8E8",
        backgroundColor: "#fff",
        padding: "10px 5px",
      };

  return (
    <Fragment>
    <Grid container spacing={2}>

      <Grid item xs={props.XsLabel || 4} className={`LabelBox`}>
        <label>
          {props.label}
          {props.required ? <sup>*</sup> : null}
        </label>
      </Grid>


      <Grid container xs={8} sx={{ padding: "16px 0 0 16px" }}>
        <Grid container xs={12}>

          <section> 

          <Select
              className={`SelectPrimary ${props.error ? "ErrorBorder" : null} ${props.disabled === true ? "disabled" : null}`}
              onChange={props.hourOnChangeAction}
              onBlur={props.onBlurHour}
              value={props.hourValue}

              defaultValue={props.defaultValue}
              disabled={props.disabled}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "0 !Important",
                },
                "& .MuiOutlinedInput-input.Mui-disabled":{
                  WebkitTextFillColor: "rgb(0 0 0 / 67%) !important"
                }
              }}
            >
              {(!props.viewException && !props.search) && (
                <MenuItem className="OptionPrimary" value="">
                  Select
                </MenuItem>
              )}
              {props.hourOptions && props.hourOptions?.map((item, index) => (
                  (<MenuItem className="OptionPrimary" value={item.value}>
                    {item.label}
                  </MenuItem>)
                ))}
            </Select>

            {/* <Input
              numInputs={2}
              isInputNum
              inputStyle={inputStyle}
              containerStyle={containerStyle}
              value={props.duration.hour}
              onChange={props.hourChange}
              onBlur={props.onBlurHour}
            /> */}

            <label
              className={classes.normalLabel}
              style={{ marginLeft: "5px" }}
            >
              Hours
            </label>

          </section>


          <Grid>
            <Typography
              sx={{
                paddingTop: "10px",
                marginRight: "5px",
                marginLeft: "5px"
              }}
            >
               :
            </Typography>
          </Grid>


          <section>


          <Select
              className={`SelectPrimary ${props.error ? "ErrorBorder" : null} ${props.disabled === true ? "disabled" : null}`}
              onChange={props.minuteOnChangeAction}
              onBlur={props.onBlurMinute}
              value={props.minuteValue}

              defaultValue={props.defaultValue}
              disabled={props.disabled}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "0 !Important",
                },
                "& .MuiOutlinedInput-input.Mui-disabled":{
                  WebkitTextFillColor: "rgb(0 0 0 / 67%) !important"
                }
              }}
            >
              {(!props.viewException && !props.search) && (
                <MenuItem className="OptionPrimary" value="">
                  Select
                </MenuItem>
              )}
              {props.minuteOptions && props.minuteOptions?.map((item, index) => (
                  (<MenuItem className="OptionPrimary" value={item.value}>
                    {item.label}
                  </MenuItem>)
                ))}
            </Select>


            {/* <Input
              numInputs={2}
              isInputNum
              inputStyle={inputStyle}
              containerStyle={containerStyle}
              value={props.duration.minute}
              onChange={props.minuteChange}
              onBlur={props.onBlurMinute}
            /> */}

            <label
              className={classes.normalLabel}
              style={{ marginLeft: "5px" }}
            >
              Minute
            </label>
          </section>


        </Grid>
        {props.error ? (
          <label
            className={classes.ErrorMsg}
            style={{ marginLeft: '25px'}}
          >
            {props.error}
          </label>
        ) : null}
      </Grid>


    </Grid>
</Fragment>
  )
}
