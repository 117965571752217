import React, { Fragment, useEffect, useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";
import classes from "./TextArea.module.css";
import autosize from "autosize";

function TextAreaNonGrid(props) {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (props.testOrder) {
      textareaRef.current.style.height = "100px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
    }
  }, [props.value]);

  const style = {
    display: "block",
    overflow: "hidden",
    width: "100%",
    height: "100px",
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        {props.noLabel ? (
          ""
        ) : (
          <Grid item xs={props.xsLabel || 4} className={`LabelBox`}>
            <label>
              {props.label}
              {props.required ? <sup>*</sup> : null}
            </label>
          </Grid>
        )}


        <Grid
          item
          sx={
            props.markAvail ? { paddingLeft: "9px !important" } : props.sx || ""
          }
          xs={props.xsInside || 8}
        >
          <textarea
            ref={textareaRef}
            style={props.testOrder && style}
            rows={props.rows || "4"}
            className={`InputPrimary ${props.error ? "ErrorBorder" : null} ${
              props.disabled === true ? "disabled" : null
            }`}
            value={props.value}
            onChange={props.onChangeAction}
            onBlur={props.onBlurAction}
            // placeholder={props.placeholder}
            type={props.type}
            disabled={props.disabled}
            name={props.name}
          />
          {/* <label>Title<sup>*</sup></label> */}
          {props.error ? (
            <>
              <label className={classes.ErrorMsg}>{props.error}</label>
            </>
          ) : null}

          {props.patienSMS ? (
            <Typography sx={{ fontSize: "14px", pt: 1 }}>
              {props.value && props.value.length} / {160} characters
            </Typography>
          ) : (
            ""
          )}
          
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default TextAreaNonGrid;
