import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  site: null,
};

const siteSlice = createSlice({
  name: "site",
  initialState: INITIAL_STATE,
  reducers: {
    saveSite: (state, action) => {
      state.site = action.payload;
    },
    removeSite: (state) => {
      state.site = null;
    },
    //  reloadSite: async (state) => {
    //    const siteName = window.location.host.split(".")[0];

    //    await axios({
    //      method: "GET",
    //      url: `${backendUrl}/api/site?name=${siteName}`,
    //    })
    //      .then((response) => {
    //        if (
    //          !response === undefined ||
    //          !response.data ||
    //          !response.data.data[0]
    //        ) {
    //          throw new Error("Couldn't find site");
    //        }
    //        const siteKey = "siteDetails";
    //        localStorage.removeItem(siteKey);
    //        localStorage.setItem(siteKey, JSON.stringify(response.data.data));
    //        const siteLocal = JSON.parse(localStorage.getItem(siteKey));
    //        state.site = siteLocal;
    //      })
    //      .catch((e) => {
    //      });
    //  },
  },
});

export const { saveSite, removeSite } = siteSlice.actions;

export default siteSlice.reducer;
