import React, { Fragment, memo, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import styles from "./DataTable.module.css";
import { Icon } from "@iconify/react";
import "./DataTable.css";
import { Typography } from "@mui/material";
import PageFooter from "../PageFooter/PageFooter";

//styling row's background
const rowClasses = (row, rowIndex) => {
  let classes = null;

  if (rowIndex % 2 === 0) {
    classes = styles.RowEven;
  } else {
    classes = styles.RowOdd;
  }
  // classes = classes + ' ' + styles.Row
  return classes;
};

const DataTable = ({perPage = 10, ...props}) => {
  useEffect(() => {}, [props.data]);

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "< Prev",
    nextPageText: "Next >",
    lastPageText: "Last",
    showTotal: true,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: perPage.toString(),
        value: perPage,
      },
      {
        text: "All",
        value: props.data.length,
      },
    ],
    custom: true,
    totalSize: props.data.length,
  };

  // const [selected, setSelected] = useState([]);

  // const handleOnSelect = (row, isSelect) =>{
  //   if (isSelect) {
  //     let previousVal = [...selected]
  //     let dt = [];
  //     dt.push(...previousVal, row.id)
  //     setSelected(dt)
  //   } else {
  //     let dt = selected.filter(x => x !== row.id)
  //     setSelected(dt);
  //   }
  // }

  // const handleOnSelectAll = (isSelect, rows) =>{
  //   const ids = rows.map(r => r.id);
  //   if (isSelect) {
  //     setSelected(ids)
  //   } else {
  //     setSelected([])
  //   }
  // }

  // const selectRow = {
  //   mode: 'checkbox',
  //   clickToSelect: true,
  //   selected: props.selectedRow(selected),
  //   onSelect: handleOnSelect,
  //   onSelectAll: handleOnSelectAll
  // }

  // if (props.test) {
  //   setInterval(() => {
  //   }, 1000);
  // }

  return (
    <Fragment>
      <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }) => (
          <div>
            <BootstrapTable
              sx={{ maxHeight: "150px !important" }}
              keyField="id"
              data={props.data}
              columns={props.columns}
              pagination={paginationFactory()}
              classes={styles.Table}
              headerClasses={props.headerClass || styles.Header}
              rowClasses={rowClasses}
              // selectRow={props.select ? selectRow : "" }
              {...paginationTableProps}
            />

            {props.patient ? (
              ""
            ) : props.noPagination ? (
              <PageFooter
                left={props.footerLeft}
                right={props.footerRight}
                noMargin={props.noFooterMargin}
              />
            ) : (
              <PaginationListStandalone {...paginationProps} />
            )}
          </div>
        )}
      </PaginationProvider>
    </Fragment>
  );
};

export default memo(DataTable);
