import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import classes from "./Body.module.css";
import SideMenu from "../SideMenu/SideMenu";
import { Outlet, useParams } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import theme from "../../Theme/Theme";
import { ThemeProvider } from "react-bootstrap";
import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dashboard from "../../Pages/Dashboard/Dashboard";

export default function Body(props) {
  const widthIs14 = useMediaQuery("(max-width:1399px)");

  const DrawerHeader = styled("div")(({ theme }) => ({
    "& div": {
      minHeight: "180px !important",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const [isCollapsed, setIsCollapsed] = useState(false);

  const [checkingAppointment, setCheckingAppointment] = useState(null);

  const checkingCollapse = (e) => {
    setIsCollapsed(e);
  };
  const checkingSessionId = (e) => {
    props.gettingSessionId(e);
  };

  const ifAppointment = (e) => {
    setCheckingAppointment(e);
  };

  return (
    <ThemeProvider theme={theme}>
      <Header
        hasAppointment={ifAppointment}
        gettingId={checkingSessionId}
        collapse={checkingCollapse}
        patient={props.patient}
      />
      <Box className={widthIs14 ? classes.Body42 : classes.Body}>
        <SideMenu />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pt: 3,
            pr: 3,
            pl: 3,
            overflow: "scroll",
            height: "calc(100vh - 50px)",
          }}
        >
          <DrawerHeader
            sx={{
              minHeight: props.patient
                ? isCollapsed
                  ? widthIs14
                    ? "150px !important"
                    : "130px !important"
                  : widthIs14
                  ? "380px !important"
                  : "232px !important"
                : "",
            }}
          />

          {window.location.pathname === "/app" ? <Dashboard /> : null}

          <Outlet />
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}
