const prodaExceptionColumns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
    },
    {
      dataField: "code",
      text: "Code",
    },
    {
      dataField: "text",
      text: "Text",
    },
]

export default {
    prodaExceptionColumns
}