import { Icon } from "@iconify/react";
import React, { Fragment } from "react";
import classes from "./Tooltip.module.css";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const TooltipInfo = ({ info }) => {
  const displayInfo = () => {
    return (
      <ul style={{ marginTop: "15px", paddingLeft: "20px" }}>
        {info?.map((i) => (
          <li>{i}</li>
        ))}
      </ul>
    );
  };
  return (
    <Fragment>
      <Tooltip
        title={displayInfo()}
        placement="right"
        sx={{ backgroundColor: "#fff !important" }}
      >
        <Icon className={classes.Icon} icon="bxs:info-circle" />
      </Tooltip>
    </Fragment>
  );
};

export default TooltipInfo;
