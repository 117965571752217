import moment from "moment";
import timestampHandler from "../utils/timstampHandler";

const mimsSubColumns = [
  {
    dataField: "id",
    text: "id",
    hidden: true,
  },
  {
    dataField: "site",
    text: "Business Name",
    formatter: (value) => {
      return value ? value?.business_name : "N/A";
    },
  },
  {
    dataField: "site_name",
    text: "Site Name",
    formatter: (value) => {
      return value ? value?.site_name : "N/A";
    },
  },
  {
    dataField: "user",
    text: "User",
    formatter: (value) => {
      return value?.name;
    },
  },
  {
    dataField: "email",
    text: "Email",
  },
  {
    dataField: "subscribed_on",
    text: "Subscribed On",
    formatter: (value) => {
      return moment(value).format("DD/MM/YYYY");
    },
  },
  {
    dataField: "expiry_date",
    text: "Expiry Date",
    formatter: (value) => {
      return value ? moment(value).format("DD/MM/YYYY") : "N/A";
    },
  },
  {
    dataField: "mims_user_id",
    text: "MIMS User ID",
  },
  {
    dataField: "status",
    text: "Subscription Status",
  },
  // {
  //   dataField: "action",
  //   text: "Actions",
  // },
];

const prescriptionTemplateColumns = [
  {
    dataField: "name",
    text: "Name",
  },
  {
    dataField: "template_url",
    text: "Template URL",
  },
  {
    text: "Created On",
    dataField: "created_at",
    formatter: (row) => {
      return timestampHandler(row, "DD/MM/YYYY");
    },
  },
  {
    text: "Last updated on",
    dataField: "updated_at",
    formatter: (row) => {
      return timestampHandler(row, "DD/MM/YYYY");
    },
  },
  {
    dataField: "status",
    text: "Status",
    formatter: (value) => {
      return value === "Deleted" ? "Deactivated" : value
    }
  },
  {
    dataField: "action",
    text: "Actions",
  },
]

export default {
  mimsSubColumns,
  prescriptionTemplateColumns
};
