import timestampHandler from "../utils/timstampHandler";

const jobTableColumns = [
  {
    text: "Job",
    dataField: "job",
  },
  {
    text: "Run On",
    dataField: "created_at",
    formatter: (row) => {
      return timestampHandler(row, "DD/MM/YYYY hh:mma");
    },
  },
  {
    text: "Status",
    dataField: "status",
  },
  {
    text: "Interval",
    dataField: "interval",
  },
  {
    text: "Actions",
    dataField: "action",
  },
];
const failedJobTableColumns = [
  {
    text: "Site",
    dataField: "site",
    formatter: (value) => {
      return value.business_name;
    },
  },
  {
    text: "Job",
    dataField: "job",
  },
  {
    text: "Run On",
    dataField: "created_at",
    formatter: (row) => {
      return timestampHandler(row, "DD/MM/YYYY hh:mma");
    },
  },
  {
    text: "Status",
    dataField: "status",
  },
  {
    text: "Interval",
    dataField: "interval",
  },
  {
    text: "Actions",
    dataField: "action",
  },
];

export default {
  jobTableColumns,
  failedJobTableColumns,
};
