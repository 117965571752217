import React, { Fragment, useEffect, useState } from "react";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Loader from "../../UI/Loader/Loader";
import TabLists from "../Dashboard/TabLists";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import DataTable from "../../Layout/DataTable/DataTable";
import columns from "../../Resources/prodaExceptionColumns";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import CustomDataTable from "../../Layout/DataTable/CustomDataTable";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import Theme from "../../Theme/Theme";
import { DropdownButton } from "react-bootstrap";
import ActionButton from "../../UI/ActionButton/ActionButtonBox";
import storageService from "../../utils/localStorageHelpers";
import Button from "../../UI/Button/Button";
import { Alert, Backdrop, Grid } from "@mui/material";
import AmaPopup from "./../FeeManagment/AmaPopup";
import CautionAlert from "../../utils/CautionAlert";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { checkPrivilage } from "../../utils/checkPrivilage";
import PrivateFeePopup from "./../FeeManagment/PrivateFeePopup";
import moment from "moment";
import UploadPopup from "./UploadPopup";

export default function ProdaExceptions() {
  const navigate = useNavigate();
  const location = useLocation();

  const canCreate = checkPrivilage("PRIVATE_FEES", "CREATE");
  const canUpdate = checkPrivilage("PRIVATE_FEES", "UPDATE");
  const canDelete = checkPrivilage("PRIVATE_FEES", "DELETE");
  const canReadMBS = checkPrivilage("MBS_ITEM", "READ");

  useEffect(() => {
    if (location.state && location.state.showAlert) {
      setTimeout(() => {
        navigate("/app/fee-management", location.state.showAlert, {});
      }, 2000);
    }

    if (location?.state?.tab) {
      setTabIndex(location?.state?.tab);
    }
  }, []);

  const [loader, setLoader] = useState(null);

  const [tabIndex, setTabIndex] = useState(1);
  const [data, setData] = useState([]);
  const [monthlyMBS, setMonthlyMBS] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [MBSPopup, setMBSPopup] = useState(false);
  const [DVAPopup, setDVAPopup] = useState(false);
  const [AlliedPopup, setAlliedPopup] = useState(false);

  const [dvaAlliedFeePageData, setDvaAlliedFeePageData] = useState(1);

  const [dvaException, setDvaException] = useState([]);
  const [dvaFeePageData, setDvaFeePageData] = useState(1);
  const [dvaExceptionCount, setDvaExceptionCount] = useState(0);

  const [deleteOpen, setDeleteOpen] = useState(false);

  const perPage = 10;
  useEffect(() => {
    onPageChange(page, perPage);
    onDvaExceptionChange(dvaFeePageData, perPage);
  }, []);

  const onPageChange = (page, sizePerPage) => {
    setPage(page);
    canReadMBS && loadData(page);
  };

  const onDvaExceptionChange = (page, sizePerPage) => {
    setDvaFeePageData(page);
    canReadMBS && loadDvaException(page);
  };

  const tabHandler = (e, newValue) => {
    setTabIndex(newValue);
  };

  const errorHandler = (msg, autoHidden) => {
    setError(msg);
  };

  const loadData = async (pg) => {
    setError();
    setLoader(true);
    await axios({
      method: "GET",
      url: `${backendUrl}/api/proda-exceptions?page=${
        pg ? Number(pg) - 1 : null
      }&perPage=${pg ? perPage : null}`,
      params: { type: "Medicare" },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data && response.data.data) {
          let result = response.data.data;
          setData(result);
          setCount(response.data && response.data.count);
          setLoader(false);
        }
      })
      .catch((e) => {
        setApiError("Something went wrong. Please try again", false);
        setLoader(false);
      });
  };

  const loadDvaException = async (pg) => {
    setError();
    setLoader(true);
    await axios({
      method: "GET",
      url: `${backendUrl}/api/proda-exceptions?page=${
        pg ? Number(pg) - 1 : null
      }&perPage=${pg ? perPage : null}`,
      params: { type: "DVA" },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data && response.data.data) {
          let result = response.data.data
          setDvaException(result);
          setDvaExceptionCount(response.data && response.data.count);
          setLoader(false);
        } else {
          setApiError("No record found", true);
        }
      })
      .catch((e) => {
        setApiError("Something went wrong. Please try again", false);
        setLoader(false);
      });
  };


  let tabData = [
    {
      label: "Medicare Exceptions",
      value: 1,
    },
    {
      label: "DVA Exceptions",
      value: 2,
    },
  ];

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "#",
      iconName: "bi:currency-dollar",
      name: "Medicare/DVA Exceptions",
    },
  ];

  const retry = () => {
    setApiError(null);
    setTimeout(() => {
      onPageChange(page, perPage);
      onDvaExceptionChange(dvaFeePageData, perPage);
    }, 100);
  };

  const handleClose = () => {
    setDeleteOpen(false);
  };

  const uploadMBS = () => {
    setMBSPopup(true);
  };

  const uploadDVA = () => {
    setDVAPopup(true);
  };

  const uploadAllied = () => {
    setAlliedPopup(true);
  };

  const upoadMBSDone = () => {
    setSuccess("Medicare exceptions has been successfully added");
    loadData(page);
  };

  const uploadDVADone = () => {
    setSuccess("DVA exceptions has been successfully added");
    loadDvaException(page);
  };

  const handleMBSPopupClose = () => {
    setMBSPopup(false);
  };
  const handleDVAPopupClose = () => {
    setDVAPopup(false);
  };
  const handleAlliedPopupClose = () => {
    setAlliedPopup(false);
  };

  return (
    <Fragment>
      <Loader open={loader} />
      <Breadcrumb options={breadCrumbData} />

      <CautionAlert
        severity="error"
        open={deleteOpen}
        content="Are you sure you want to delete this item?"
        // onCloseActionB={deleteConfirm}
        onCloseActionA={handleClose}
        okText="Delete"
        cancelText="Cancel"
      />

      {MBSPopup && (
        <UploadPopup
          ama={true}
          title="Upload Medicare Exceptions"
          handlePopupOpen={uploadMBS}
          PopUpOpen={MBSPopup}
          success={upoadMBSDone}
          handlePopupClose={handleMBSPopupClose}
          type="Medicare"
          url={`${backendUrl}/api/proda-exceptions`}
          />
          )}

      {DVAPopup && (
        <UploadPopup
          title="Upload DVA Exceptions"
          handlePopupOpen={uploadDVA}
          PopUpOpen={DVAPopup}
          success={uploadDVADone}
          handlePopupClose={handleDVAPopupClose}
          type="DVA"
          url={`${backendUrl}/api/proda-exceptions`}
        />
      )}
      <CautionAlert
        severity="success"
        success={true}
        open={success}
        content={success}
        onCloseActionB={() => setSuccess(null)}
        okText="Ok"
        oneButton={true}
      />

      {location.state && location.state.showAlert ? (
        <>
          <Alert
            severity="success"
            sx={{
              mb: 2,
              border: "1px solid green",
              zIndex: "99999 !important",
              position: "relative",
            }}
          >
            {location.state && location.state.showAlert}
          </Alert>
          <Backdrop
            sx={{ backgroundColor: "#00000000", zIndex: 500 }}
            open={location.state && location.state.showAlert}
          ></Backdrop>
        </>
      ) : null}

      {apiError ? (
        <CautionAlert
          severity="error"
          open={apiError}
          content={apiError}
          onCloseActionA={() => setApiError()}
          onCloseActionB={retry}
          okText="Retry"
          cancelText="Dismiss"
        />
      ) : null}

      <CautionAlert
        severity="error"
        open={error}
        content={error}
        onCloseActionB={() => setError(null)}
        okText="Ok"
        oneButton={true}
      />

      <Fragment>
        {monthlyMBS &&
          monthlyMBS.map((e) => {
            return (
              <Fragment>
                <TabContext
                  className="SubSectionRow"
                  style={{
                    height: "100%",
                    margin: "0px !important",
                    padding: "0px !important",
                  }}
                  value={tabIndex}
                >
                  <TabPanel sx={{ padding: "0px !important" }} value={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sx={{ mb: 2 }}>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TabContext>
              </Fragment>
            );
          })}

        <PageHeader
          left={"Medicare/DVA Exceptions"}
          right={
            <TabContext
              className="SubSectionRow"
              style={{
                height: "100%",
                margin: "0px !important",
                padding: "0px !important",
              }}
              value={tabIndex}
            >
              <TabPanel sx={{ padding: "0px !important" }} value={1}>
                {canCreate && (
                  <Button
                    fontSize="14px"
                    onClickAction={() => uploadMBS(true)}
                    text="Upload Medicare Exceptions"
                    startIcon={
                      <Icon
                        style={{ fontSize: "14px" }}
                        icon="akar-icons:plus"
                      />
                    }
                  ></Button>
                )}
              </TabPanel>

              <TabPanel sx={{ padding: "0px !important" }} value={2}>
                {canCreate && (
                  <Button
                    fontSize="14px"
                    onClickAction={() => uploadDVA(true)}
                    text="Upload DVA Exceptions"
                    startIcon={
                      <Icon
                        style={{ fontSize: "14px" }}
                        icon="akar-icons:plus"
                      />
                    }
                  ></Button>
                )}
              </TabPanel>
            </TabContext>
          }
        />

        <TabLists
          data={tabData}
          // uploadAMA={uploadAMA}
          // uploadWorkcover={uploadWorkcover}
          // uploadPrivateFee={uploadPrivateFee}
          value={tabIndex}
          handleChange={tabHandler}
        />

        <TabContext
          className="SubSectionRow"
          style={{
            height: "100%",
            margin: "0px !important",
            padding: "0px !important",
          }}
          value={tabIndex}
        >
          <TabPanel sx={{ padding: "0px !important" }} value={1}>
            {data && (
              <CustomDataTable
                data={data}
                length={count}
                page={page}
                perPage={perPage}
                columns={columns.prodaExceptionColumns}
                onPageChange={onPageChange}
              />
            )}
          </TabPanel>

          <TabPanel sx={{ padding: "0px !important" }} value={2}>
            {data && (
              <CustomDataTable
                data={dvaException}
                length={dvaExceptionCount}
                page={dvaFeePageData}
                perPage={perPage}
                columns={columns.prodaExceptionColumns}
                onPageChange={onDvaExceptionChange}
              />
            )}
          </TabPanel>
        </TabContext>
      </Fragment>
    </Fragment>
  );
}
