import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { ThemeProvider } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import theme from "../../Theme/Theme";
import Button from "../../UI/Button/Button";
import PageFooter from "../PageFooter/PageFooter";
import PageHeader from "../PageHeader/PageHeader";
import classes from "./DataView.module.css";

const DataView = (props) => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      {props.noHeader ? null : (
        <>
          <PageHeader left={props.title} center={props.status} />
        </>
      )}

      {props.data ? (
        <Fragment>
          <Box className={classes.FormBox}>
            <Grid container xs={12} direction="row" Spacing={3}>
              <Grid container xs={12} direction="column" Spacing={3}>
                {props.columns &&
                  props.columns.map((item) => {
                    return (
                      <Fragment>
                        {props.data[item.field] === null &&
                        item.hide === true ? null : (
                          <Grid
                            item
                            // xs={0.1}
                            // sm={0.1}
                            // md={0.7}

                            // lg={2}

                            xs={props.xs}
                            sm={props.sm}
                            md={props.md}
                            lg={props.lg}
                            className={classes.LabelBox}
                            sx={props.sxLabel}
                          >
                            <label>
                              {!item.noLabel && <strong>{item.title}: </strong>}
                              {item.formatter
                                ? item.formatter(
                                    props.data[item.field],
                                    props.data
                                  )
                                : props.data[item.field] === null
                                ? "N/A"
                                : props.data[item.field]}
                            </label>
                          </Grid>
                        )}
                      </Fragment>
                    );
                  })}
              </Grid>
            </Grid>
          </Box>

          {!props.noFooter &&
            (!props.patientPage ? (
              <PageFooter
                right={
                  <Fragment>
                    {props.edit !== false ? (
                      <Box
                        sx={{
                          display: "inline-flex",
                          m: 1,
                        }}
                      >
                        <Button onClickAction={props.editView} text="Edit" />
                      </Box>
                    ) : null}

                    {props.noCancel ? (
                      ""
                    ) : (
                      <Box
                        sx={{
                          display: "inline-flex",
                          m: 1,
                        }}
                      >
                        <Button
                          onClickAction={props.closeView}
                          bgLight={true}
                          text="Cancel"
                        />
                      </Box>
                    )}
                  </Fragment>
                }
              />
            ) : (
              ""
            ))}
        </Fragment>
      ) : (
        <PageFooter
          right={
            <Fragment>
              {props.noCancel ? (
                ""
              ) : (
                <Box
                  sx={{
                    display: "inline-flex",
                    m: 1,
                  }}
                >
                  <Button
                    onClickAction={props.closeView}
                    bgLight={true}
                    text="Cancel"
                  />
                </Box>
              )}
            </Fragment>
          }
        />
      )}
    </ThemeProvider>
  );
};

export default DataView;
