import { Autocomplete, Grid, TextField } from "@mui/material";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import backendUrl from "../../utils/backendUrl";
import Loader from "../Loader/Loader";
import classes from "./SearchUserBox.module.css";

export default function SearchUserBox(props) {
  const [userItems, setUserItems] = useState(null);
  const [userListing, setUserListing] = useState(null);
  const [searchUserOptions, setSearchUserOptions] = useState([]);
  const [userInput, setUserInput] = useState(null);
  const [inlineError, setInlineError] = useState(null);
  const [loader, setLoader] = useState(null);

  useEffect(() => {
    if (props.inlineError) {
      setInlineError(props.inlineError);
    }
  }, [props.inlineError]);

  const mbsHandler = (event, value) => {
    setUserItems(value);
    props.selectedUser(value);
    setUserListing(value[0]);
    setUserInput(null);
  };
  const mbsInputHandler = (e, value) => {
    if (e?.target) {
      setUserInput(e.target.value);
    }
  };

  useEffect(() => {
    let apiTimeOut;
    if (userInput?.length >= 3) {
      apiTimeOut = setTimeout(() => {
        searchSingleUser();
      }, 1000);
    } else {
      // setSetUserSearched([]);
    }
    return () => {
      clearTimeout(apiTimeOut);
    };
  }, [userInput]);

  const searchSingleUser = async () => {
    setLoader(true);
    let url = `${backendUrl}/api/user/search?name=${userInput}`;
    if (props.site) {
      url = `${backendUrl}/api/user/search?site_id=${props.site?.id}&name=${userInput}`;
    }
    await axios({
      method: "GET",
      url,
      withCredentials: true,
    })
      .then(async (res) => {
        setInlineError(null);
        let dt = res.data.data;
        setSearchUserOptions(dt);
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        setInlineError("Something went wrong. Please try again");
      });
  };

  return (
    <Fragment>
      <Loader open={loader} />
      <Grid container spacing={2}>
        <Grid item xs={props.xsLabel || 4} className={`LabelBox`}>
          <label>
            {props.label || (
              <>
                Search User<sup>*</sup>
              </>
            )}
          </label>
        </Grid>
        <Grid item xs={props.xsInside || 8}>
          <div>
            <Autocomplete
              value={props.value}
              id="combo-box-demo"
              options={searchUserOptions && searchUserOptions}
              onChange={mbsHandler}
              onInputChange={mbsInputHandler}
              disabled={props.disabled}
              noOptionsText={
                searchUserOptions.length <= 0 ? "Not Found" : "Searching..."
              }
              open={inlineError ? false : userInput}
              // disableCloseOnSelect
              // disableClearable
              variant="outlined"
              ListboxProps={{
                style: { maxHeight: "15rem" },
                position: "bottom-start",
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>{option.name}</li>
              )}
              renderInput={(params) => (
                <TextField
                  ListboxProps={{
                    style: { maxHeight: "15rem" },
                    position: "bottom-start",
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      height: "1.2rem !important",
                    },
                    border: "0px !important",
                    "& .MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
                      {
                        border: "0px !important",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#ff000000 !important",
                      },
                    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#ff000000 !important",
                      },
                  }}
                  {...params}
                />
              )}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#00000000 !important",
                },
                "& .MuiInputBase-input": {
                  height: "1.5rem !important",
                },
                ".MuiAutocomplete-endAdornment": {
                  display: "none !important",
                },
                "& .MuiOutlinedInput-root": {
                  padding: "2px !important",
                  borderRadius: "0px",
                  backgroundColor: "white",
                  border: inlineError
                    ? "1.5px solid #FF0000 !important"
                    : "1px solid #E8E8E8",
                  height:
                    userItems && userItems.length > 2 ? "150px !important" : "",
                  overflow: "scroll",
                },
                "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
                  backgroundColor: "#e9e9e9 !important",
                  border: "0px !important",
                },
              }}
            />
          </div>

          {inlineError ? (
            <label className={classes.ErrorMsg}>{inlineError}</label>
          ) : null}
        </Grid>
      </Grid>
    </Fragment>
  );
}
