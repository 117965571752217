import moment from "moment";
import timestampHandler from "../utils/timstampHandler";

const intervalOptions = [
  {
    label: "10 minutes",
    value: 10,
  },
  {
    label: "15 minutes",
    value: 15,
  },
  {
    label: "20 minutes",
    value: 20,
  },
];

const viewSiteDetailsColumns = [
  {
    title: "Site Name",
    field: "site_name",
    formatter: (value) => value ?? "N/A",
  },
  {
    title: "Organisation Name",
    field: "organisation",
    formatter: (value) => {
      return value?.organisation_name;
    },
  },
  {
    title: "Minor ID",
    field: "minor_id",
    formatter: (value) => value ?? "N/A",
  },
  {
    title: "Organisation Type",
    field: "type",
    formatter: (value) => value ?? "N/A",
  },
  {
    title: "Business Name",
    field: "business_name",
    formatter: (value) => value ?? "N/A",
  },
  {
    title: "ABN",
    field: "abn_number",
    formatter: (value) => value ?? "N/A",
  },
  {
    title: "HPIO Number",
    field: "hpio_number",
    formatter: (value) => value ?? "N/A",
  },
  {
    title: "MIMS Integrated",
    field: "mims_integrated",
    formatter: (value) => {
      return value ? "Yes" : "No";
    },
  },
  {
    title: "Healthlink EDI",
    field: "edi",
    formatter: (value) => value ?? "N/A",
  },
  {
    title: "Backend URL",
    field: "backend_url",
    formatter: (value) => value ?? "N/A",
  },
  {
    title: "Frontend URL",
    field: "frontend_url",
    formatter: (value) => value ?? "N/A",
  },
  {
    title: "External API Key",
    field: "users",
    formatter: (value) => {
      return  <div
          style={{
            border: "1px solid #56438e",
            backgroundColor: "#fff",
            padding: "10px 15px",
          }}
        >
           {value?.length ? value[0].api_key : "N/A"}
        </div>
    },
  },
  {
    title: "Address Line 1",
    field: "address_line_one",
    formatter: (value) => value ?? "N/A",
  },
  {
    title: "Address Line 2",
    field: "address_line_two",
    formatter: (value) => {
      return value ? value : "N/A";
    },
  },
  {
    title: "Suburb",
    field: "suburb",
    formatter: (value) => value ?? "N/A",
  },
  {
    title: "State",
    field: "state",
    formatter: (value) => value ?? "N/A",
  },
  {
    title: "Postcode",
    field: "post_code",
    formatter: (value) => value ?? "N/A",
  },
  {
    title: "Country",
    field: "country",
    formatter: (value) => value ?? "N/A",
  },
  {
    title: "Phone Number",
    field: "work_phone",
    formatter: (value) => value ?? "N/A",
  },
  {
    title: "Fax Number",
    field: "fax",
    formatter: (value) => value ?? "N/A",
  },
  {
    title: "Email",
    field: "work_email",
    formatter: (value) => value ?? "N/A",
  },
  {
    title: "Appointment Interval",
    field: "appointment_interval",
    formatter: (value) => {
      return intervalOptions.find((a) => a.value == value)?.label;
    },
  },
  {
    title: "Created by",
    field: "created_by_uid",
  },
  {
    title: "Created at",
    field: "created_at",
    formatter: (value) => {
      return timestampHandler(value, "DD/MM/YYYY");
    },
  },
  {
    title: "Updated at",
    field: "updated_at",
    formatter: (value) => {
      return timestampHandler(value, "DD/MM/YYYY");
    },
  },
  {
    title: "Updated by",
    field: "updated",
    formatter: (value) => {
      return `${value?.name}`;
    },
  },
  {
    title: "Header Logo",
    field: "header_logo",
    noLabel: true,
    formatter: (row, value) => {
      var timestamp = new Date().getTime();
      if (row) {
        row = row + `?datetime=${timestamp}`;
      }
      return (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>Header Logo:&nbsp;</strong>

            {row ? (
              <>
                <div
                  style={{
                    background: "#56438E",
                    padding: "10px",
                    height: "100px",
                    width: "150px",
                    display: "inline-block",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${row})`,
                      height: "100%",
                      width: "100%",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                </div>
              </>
            ) : (
              "Not uploaded"
            )}
          </div>
        </>
      );
    },
  },
  {
    title: "Template Logo",
    field: "template_logo",
    noLabel: true,
    formatter: (row, value) => {
      var timestamp = new Date().getTime();
      if (row) {
        row = row + `?datetime=${timestamp}`;
      }
      return (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>Template Logo:&nbsp;</strong>
            {row ? (
              <div
                style={{
                  backgroundImage: `url(${row})`,
                  height: "100px",
                  width: "150px",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  display: "inline-block",
                }}
              ></div>
            ) : (
              "Not uploaded"
            )}
          </div>
        </>
      );
    },
  },

  {
    title: "Banner",
    field: "banner",
    noLabel: true,
    formatter: (row, value) => {
      var timestamp = new Date().getTime();
      if (row) {
        row = row + `?datetime=${timestamp}`;
      }
      return (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>Banner:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>
            {row ? (
              <div
                style={{
                  backgroundImage: `url(${row})`,
                  height: "150px",
                  width: "100px",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  display: "inline-block",
                }}
              ></div>
            ) : (
              "Not uploaded"
            )}
          </div>
        </>
      );
    },
  },
];
const siteDetailsTableColumns = [
  {
    dataField: "id",
    text: "id",
    hidden: true,
  },
  {
    dataField: "name",
    text: "First Name",
  },
  {
    dataField: "last_name",
    text: "Last Name",
  },
  {
    dataField: "username",
    text: "Username",
  },
  {
    dataField: "role_name",
    text: "User Role",
  },
  {
    dataField: "email",
    text: "Email",
  },
  {
    dataField: "mobile",
    text: "Mobile Number",
  },
  {
    dataField: "status",
    text: "Status",
  },
  {
    dataField: "action",
    text: "Action",
  },
];

const siteDetails = { viewSiteDetailsColumns, siteDetailsTableColumns };

export default siteDetails;
