import timestampHandler from "../utils/timstampHandler";

const testTableColumns = [
  {
    text: "Name",
    dataField: "name",
  },
  {
    text: "Code",
    dataField: "code",
  },
  {
    text: "Type",
    dataField: "type",
  },
  {
    text: "Created On",
    dataField: "created_at",
    formatter: (row) => {
      return timestampHandler(row, "DD/MM/YYYY");
    },
  },
  {
    text: "Last updated on",
    dataField: "updated_at",
    formatter: (row) => {
      return timestampHandler(row, "DD/MM/YYYY");
    },
  },
  {
    text: "Actions",
    dataField: "action",
  },
];

export default {
  testTableColumns,
};
