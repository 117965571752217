import { checkAnyPrivilage } from "../utils/checkPrivilage";

const subMenuFontSie = "16px";
const customFontSize = "25px";
const menu = [
  {
    name: "Management",
    icon: "icon-park-outline:connect",
    to: "/app/management",
  },
  {
    name: "Users",
    icon: "teenyicons:users-solid",
    to: "/app/users",
  },
  {
    name: "Cron Jobs",
    icon: "mdi:tool-time",
    to: "/app/cron-jobs",
  },
  // {
  //     name: "Patients",
  //     icon: "healthicons:virus-patient-outline",
  //     to: "/",
  //     fontSize: customFontSize,
  //     expanded: true,
  //     permission: ["PATIENTS"],
  //     subMenu: [
  //       {
  //         name:"Patient List",
  //         icon: "ant-design:unordered-list-outlined",
  //         to: "/app/patients",
  //         fontSize: subMenuFontSie,
  //         permission: [{name: "PATIENTS", operation: "READ"}]
  //       },
  //       {
  //         name:"New Patient",
  //         icon: "ant-design:user-add-outlined",
  //         to: "/app/patients/add",
  //         fontSize: subMenuFontSie,
  //         permission: [{name: "PATIENTS", operation: "CREATE"}]
  //       },
  //     ]
  // },
];

export default menu;
