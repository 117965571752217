import React from 'react'
import { Dropdown, DropdownButton } from "react-bootstrap";
import classes from "./ActionButton.module.css"
import { Icon } from '@iconify/react';
import Theme from '../../Theme/Theme';
import { ThemeProvider } from '@mui/material/styles';


const ActionButton = (props) => {
  return (
    <ThemeProvider theme={Theme}>
    <Dropdown.Item onClick={props.onClickAction} eventKey={props.eventKey}>
      <Icon icon={props.icon} className={classes.Icon} />
    {props.content}
    </Dropdown.Item>
    </ThemeProvider>
   
  )
}

export default ActionButton