import React, { Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import styles from "./DataTable.module.css";
import "./DataTable.css";

//styling row's background
const rowClasses = (row, rowIndex) => {
  let classes = null;

  if (rowIndex % 2 === 0) {
    classes = styles.RowEven;
  } else {
    classes = styles.RowOdd;
  }
  // classes = classes + ' ' + styles.Row
  return classes;
};

const CustomDataTable = ({ length, page, perPage, columns, data, onPageChange,  }) => {
  
  // const onPageChange = (e) => {
  //   e.preventDefault();
  //   onPageChange(page);
  // };

  const paginationOption = {
    custom: true,
    totalSize: length,
    sizePerPage: perPage,
    alwaysShowAllBtns: true,
    paginationSize: 4,
    firstPageText: "First",
    prePageText: "< Prev",
    nextPageText: "Next >",
    lastPageText: "Last",
    onPageChange: onPageChange,
    page: page
  };

  const options = {
    custom: true,
    totalSize: 110,
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "< Prev",
    nextPageText: "Next >",
    lastPageText: "Last",
    page: 3,
    showTotal: true,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ],
    custom: true,
    totalSize: data.length,
  };
  

  return (
    <Fragment>
      <PaginationProvider pagination={paginationFactory(paginationOption)}>
        {({ paginationProps, paginationTableProps }) => (
          <div>
            <BootstrapTable 
              keyField="id"
              data={data}
              columns={columns}
              pagination={paginationFactory()}
              classes={styles.Table}
              headerClasses={styles.Header}
              rowClasses={rowClasses}
              remote={true}
              fetchInfo={100}
              {...paginationTableProps}
            />
            <PaginationListStandalone {...paginationProps} />
          </div>
        )}
      </PaginationProvider>
    </Fragment>
  );
};

export default CustomDataTable;
