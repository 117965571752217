import moment from "moment";
import timestampHandler from "../utils/timstampHandler";

const viewOrganisationColumns = [
  {
    title: "Organisation Name",
    field: "organisation_name",
  },
  {
    title: "ABN",
    field: "abn_number",
  },
  {
    title: "Phone Number",
    field: "contact_number",
  },
  {
    title: "Fax Number",
    field: "fax",
  },
  {
    title: "Email",
    field: "contact_email",
  },
  {
    title: "Created by",
    field: "created",
    formatter: (value) => {
      return `${value?.name}`;
    },
  },
  {
    title: "Created at",
    field: "created_at",
    formatter: (value) => {
      return timestampHandler(value, "DD/MM/YYYY");
    },
  },
  {
    title: "Updated at",
    field: "updated_at",
    formatter: (value) => {
      return timestampHandler(value, "DD/MM/YYYY");
    },
  },
  {
    title: "Updated by",
    field: "updated",
    formatter: (value) => {
      return `${value?.name}`;
    },
  },
];
const organisationTableColumns = [
  {
    dataField: "id",
    text: "id",
    hidden: true,
  },
  {
    dataField: "organisation_name",
    text: "Organisation Name",
  },
  {
    dataField: "abn_number",
    text: "ABN Number",
  },
  {
    dataField: "contact_email",
    text: "Email",
  },
  {
    dataField: "contact_number",
    text: "Phone",
  },
  {
    dataField: "fax",
    text: "Fax",
  },
  {
    dataField: "action",
    text: "Action",
  },
];

const siteDetails = { organisationTableColumns, viewOrganisationColumns };

export default siteDetails;
