import { Box } from "@mui/system";
import React, { Fragment, useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  ListItem,
  AlertTitle,
  OutlinedInput,
} from "@mui/material";
import { CssBaseline } from "@material-ui/core";
import Image from "./../.././assets/Images/forgotpss-side.png";
import { ThemeProvider } from "react-bootstrap";
import logo from "./../.././assets/Images/DarkLogo.png";
import Theme from "../Theme/Theme";
import Alert from "@mui/material/Alert";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../UI/Loader/Loader";
import { useParams } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import backendUrl from "../utils/backendUrl";
import CryptoJS from "crypto-js";

const Reset = () => {
  let { id } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is mendatory")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        {}
      ),
    confirmPwd: Yup.string()
      .required("Password is mendatory")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });

  let navigate = useNavigate();

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loader, setLoader] = React.useState(false);

  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  async function onSubmit(data) {
    var password = data.password;
    var token = id;

    setLoader(true);

    let body = CryptoJS.AES.encrypt(
      JSON.stringify({
        password: JSON.stringify(password).replace(/\"/g, ""),
        token: JSON.stringify(token).replace(/\"/g, ""),
        request: "Reset",
      }),
      "secret key usr2323"
    ).toString();

    await axios({
      method: "POST",
      data: { data: body },
      withCredentials: true,
      url: `${backendUrl}/api/new_password`,
    })
      .then(function (response) {
        setError(null);
        setSuccess(response.data.message);
        setLoader(false);
        setTimeout(function () {
          navigate("/app/login", { replace: true });
        }, 3000);
      })
      .catch(function (error) {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
    return false;
  }

  return (
    <ThemeProvider theme={Theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={2}
          md={4}
          sx={{
            backgroundImage: `url(${Image})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "white",
            backgroundSize: "cover",
            backgroundPosition: "center",
            boxShadow: "4px 0 39px 3px #00000025",
          }}
        />
        <Grid item xs={0} sm={2} md={2} />

        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          square
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 10,
            }}
          >
            {error ? (
              <Alert
                severity="error"
                sx={{ mb: 2, border: "1px solid #f0625f" }}
              >
                {error}
              </Alert>
            ) : (
              <></>
            )}
            {success ? (
              <Alert
                severity="success"
                sx={{ mb: 2, border: "1px solid green" }}
              >
                {success}
              </Alert>
            ) : (
              <></>
            )}

            <img
              alt="logo"
              src={logo}
              className="img-fluid"
              style={{ marginBottom: "50px", height: "55px" }}
            />

            <Typography
              component={"h1"}
              variant={"h5"}
              sx={{
                fontSize: "24px",
                fontWeight: 700,
                color: "#615586",
                textAlign: "left",
                mb: 5,
              }}
            >
              Reset Password
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              <CustomLoader open={loader} />

              <TextField
                label="Password"
                fullWidth
                variant="outlined"
                type={showPassword ? "text" : "password"}
                className={` ${errors.password ? "is-invalid" : ""}`}
                {...register("password")}
                error={errors.password?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  mb: 2,
                }}
              />

              {errors.password?.message ? (
                <Alert severity="error" sx={{ mb: 4 }}>
                  <AlertTitle>Your new password must:</AlertTitle>
                  <ListItem sx={{ p: 0, display: "list-item" }}>
                    Include at least 8 characters
                  </ListItem>
                  <ListItem sx={{ p: 0, display: "list-item" }}>
                    Include at least one uppercase
                  </ListItem>
                  <ListItem sx={{ p: 0, display: "list-item" }}>
                    Include at least one number
                  </ListItem>
                  <ListItem sx={{ p: 0, display: "list-item" }}>
                    Include at least one special case character
                  </ListItem>
                </Alert>
              ) : (
                <></>
              )}
              <TextField
                label="Confirm Password"
                fullWidth
                variant="outlined"
                name="confirmPwd"
                type={showPassword ? "text" : "password"}
                {...register("confirmPwd")}
                className={` ${errors.confirmPwd ? "is-invalid" : ""}`}
                error={errors.confirmPwd?.message}
                helperText={errors.confirmPwd?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  mb: 2,
                }}
              />

              <Button
                type="Submit"
                color={"primaryColor"}
                fullWidth
                variant="contained"
                sx={{ mt: 4, mb: 2, height: "40px" }}
                disabled={success}
              >
                Reset password
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Reset;
