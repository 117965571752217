import React, { Fragment, useEffect, useState } from "react";
import DataView from "../../../Layout/DataView/DataView";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import storageService from "../../../utils/localStorageHelpers";
import backendUrl from "../../../utils/backendUrl";
import columns from "../../../Resources/AppointmentTypeColumn";
import LoaderWithoutBackdrop from "../../../UI/Loader/LoaderWithoutBackdrop";
import Breadcrumb from "../../../Layout/Breadcrumb/Breadcrumb";
import { checkPrivilage } from "../../../utils/checkPrivilage";

const ViewAppointmentType = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const canUpdate = checkPrivilage("APPOINTMENT_TYPES", "UPDATE");

  const [data, setData] = useState({});
  const [loader, setLoader] = useState(true);
  const [notFound, setNotFound] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoader(true);

    let siteDt = storageService.loadSite();

    if (location.state && location.state.data) {
      setData(location.state.data);
      setLoader(false);
    } else {
      await axios({
        method: "GET",
        withCredentials: true.valueOf,
        url: `${backendUrl}/${siteDt[0].site_name}/api/appointment_types?id=${id}`,
      }).then(async (gb) => {})
        .catch((e) => {
          setNotFound("Appointment type not found");
          setLoader(false);
        });
    }
  };



  const allowEdit = data.from !== 'System' && canUpdate

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/appointment-type",
      iconName: "cil:filter",
      name: "Appointment Types",
    },
  ];

  return (
    <Fragment>
    <Breadcrumb options={breadCrumbData} />
      {loader ? (
        <LoaderWithoutBackdrop open={loader} />
      ) : notFound ? (
        <DataView
          noData={notFound}
          title={`View Appointment Type`}
          closeView={() => navigate(-1)}
        />
      ) : (
        <DataView
        xs={0.1}
        sm={0.1}
        md={6}
        lg={1}
        sxLabel={{padding:"20px 20px !important"}}

          data={data}
          columns={columns.appointmentTypeViewColumns}
          title={`View Appointment Type`}
          edit={allowEdit}
          editView={() => navigate(`/app/appointment-type/edit/${data.id}`)}
          closeView={() => navigate(-1)}
        />
      )}
    </Fragment>
  );
};

export default ViewAppointmentType;
