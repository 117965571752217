import moment from "moment";

const userSubColumns = [
  {
    dataField: "id",
    text: "id",
    hidden: true,
  },
  {
    dataField: "site",
    text: "Business Name",
    formatter: (value) => {
      return value?.business_name;
    },
  },
  {
    dataField: "site",
    text: "Site Name",
    formatter: (value) => {
      return value?.site_name;
    },
  },
  {
    dataField: "role",
    text: "Role Name",
    formatter: (value) => {
      return value?.name;
    },
  },
  {
    dataField: "user",
    text: "User",
    formatter: (value) => {
      return value ? value?.name : "N/A";
    },
  },
  {
    dataField: "user_type",
    text: "Type",
    formatter: (value) => {
      return `${value[0].toUpperCase()}${value.slice(1)}`;
    },
  },
  {
    dataField: "start_date",
    text: "Start Date",
    formatter: (value) => {
      return moment(value).format("DD/MM/YYYY");
    },
  },
  {
    dataField: "expiry_date",
    text: "Expiry Date",
    formatter: (value) => {
      return moment(value).format("DD/MM/YYYY");
    },
  },
  {
    dataField: "status",
    text: "Status",
  },
  {
    dataField: "action",
    text: "Actions",
  },
];

export default {
  userSubColumns,
};
