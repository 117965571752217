import React, { Fragment, useEffect, useState } from "react";
import DataTable from "../../Layout/DataTable/DataTable";
import ActionButton from "../../UI/ActionButton/ActionButtonBox";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Button from "../../UI/Button/Button";
import { Icon } from "@iconify/react";
import AddUser from "../User/AddUser/AddUser";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Import these files for breadcrumbs
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import { DropdownButton } from "react-bootstrap";
import LoaderWithoutBackdrop from "../../UI/Loader/LoaderWithoutBackdrop";
import columns from "../../Resources/userColumns";
import storageService from "../../utils/localStorageHelpers";
import { Alert, Backdrop } from "@mui/material";
import CautionAlert from "../../utils/CautionAlert";
import Theme from "../../Theme/Theme";
import moment from "moment";
import InfoAlert from "../../utils/InfoAlert";
import { checkPrivilage } from "../../utils/checkPrivilage";
import CustomDataTable from "../../Layout/DataTable/CustomDataTable";
import SearchUser from "./SearchUser";

const Users = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const canCreate = true;
  const canUpdate = true;
  const canDelete = true;
  // const canCreate = checkPrivilage("USERS", "CREATE");
  // const canUpdate = checkPrivilage("USERS", "UPDATE");
  // const canDelete = checkPrivilage("USERS", "DELETE");

  const perPage = 10;

  const [data, setData] = useState([]);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [viewUser, setViewUser] = useState("");
  const [editUser, setEditUser] = useState("");
  const [showTable, setShowTable] = useState(true);
  const [loader, setLoader] = useState(false);
  const [site, setSite] = useState(null);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [resend, setResend] = useState(null);
  const [resendOpen, setResendOpen] = useState(false);
  const [revoke, setRevoke] = useState(null);
  const [editFrom, setEditFrom] = useState(null);
  const [deactivate, setDeactivate] = useState(null);
  const [deactivateAlert, setDeactivateAlert] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [fullTimeSub, setFullTimeSub] = useState(null);
  const [partTimeSub, setPartTimeSub] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [partTimeSubError, setPartTimeSubError] = useState(null);
  const [showErrorBtn, setShowErrorBtn] = useState(true);
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState(null);
  const [count, setCount] = useState(null);

  const closeAddUserHandler = () => {
    setOpenAddUser(false);
  };

  useEffect(() => {
    getData(page);
    loadSubscription();
  }, [page]);

  useEffect(() => {
    onPageChange(page, perPage);
  }, [searchParams]);

  const successHandler = (msg) => {
    setSuccess(msg);
    getData(page);
  };

  const errorHandler = (msg, autoHidden) => {
    setError(msg);
  };

  const loadSubscription = () => {
    return axios({
      medthod: "GET",
      url: `${backendUrl}/api/user_subscription`,
      params: {
        status: "Active",
      },
      withCredentials: true,
    })
      .then((response) => {
        let dt = response?.data?.data;
        setSubscriptions(dt);
        let ft = dt?.filter(
          (sub) => sub.user_type === "fulltime" && sub.user_id === null
        );
        setFullTimeSub(ft);

        let pt = dt?.filter(
          (sub) => sub.user_type === "parttime" && sub.user_id === null
        );
        setPartTimeSub(pt);
        setLoader(false);
      })
      .catch((e) => {
        
        errorHandler("Something went wrong. Please try again", false);
        setLoader(false);
      });
  };

  const onPageChange = (page) => {
    setPage(page);
  };

  const getData = async (pg) => {
    let arr = [];
    setLoader(true);
    await axios({
      method: "GET",
      withCredentials: true,
      url: `${backendUrl}/api/user/dashboard/search?page=${
        pg ? Number(pg) - 1 : null
      }&perPage=${pg ? perPage : null}`,
      params: searchParams ? searchParams : null,
    })
      .then(async (response) => {
        let dt = response.data.data;
        setCount(response.data.count);

        return Promise.all(
          dt.map(async (item) => {
            item.role_name = item.role.name;

            arr.push({
              ...item,
              action: (
                <Fragment>
                  <style type="text/css">
                    {`
                  .ActionButton button {
                    background-color:${Theme.palette.primaryColor.backgroundColor};
                  }
                  .ActionButton button:hover {
                    background-color:${Theme.palette.secondaryColor.backgroundColor};
                  }
                  .ActionButton button:focus {
                    background-color:${Theme.palette.secondaryColor.backgroundColor};
                  }
              `}
                  </style>
                  <DropdownButton
                    align="end"
                    title="Actions"
                    id="dropdown-menu-align-start"
                    className="ActionButton"
                  >
                    {canUpdate && (
                      <ActionButton
                        content="Edit"
                        onClickAction={(e) =>
                          navigate(`/app/user/edit/${item.id}`, {
                            state: { user: item },
                          })
                        }
                        icon="ep:edit"
                        eventKey="2"
                      />
                    )}
                    {canUpdate && item.status === "Active" ? (
                      <ActionButton
                        content={"Deactivate"}
                        onClickAction={(e) => setDeactivate(item)}
                        icon="bytesize:reload"
                        eventKey="3"
                      />
                    ) : canUpdate && item.status === "Deactivated" ? (
                      <ActionButton
                        content={"Activate"}
                        onClickAction={(e) => {
                          setResend(item);
                          setResendOpen(true);
                        }}
                        icon="bytesize:reload"
                        eventKey="3"
                      />
                    ) : (
                      canUpdate && (
                        <Fragment>
                          <ActionButton
                            content={
                              item.status === "Revoked"
                                ? "Send Activation Email"
                                : "Resend Activation Email"
                            }
                            onClickAction={(e) => {
                              setResend(item);
                              setResendOpen(true);
                            }}
                            icon="bytesize:reload"
                            eventKey="3"
                          />
                          {item.status !== "Revoked" ? (
                            <ActionButton
                              content="Revoke Invitation"
                              onClickAction={(e) => {
                                setRevoke(item);
                              }}
                              icon="charm:block"
                              eventKey="4"
                            />
                          ) : null}
                        </Fragment>
                      )
                    )}
                  </DropdownButton>
                </Fragment>
              ),
            });
          })
        );
      })
      .then(() => {
        arr = arr.sort(compare);
        setData(arr);
        setLoader(false);
      })
      .catch((e) => {
        errorHandler("Something went wrong. Please try again", false);
        setLoader(false);
      });
  };

  function compare(a, b) {
    if (a.status < b.status) {
      return -1;
    }
    if (a.status > b.status) {
      return 1;
    }
    return 0;
  }


  const resendActivationHandler = async () => {
    return resendFunction();
  };

  const resendFunction = async (subscribed = false) => {
    let body = {
      paid_role: resend.role.paid_role,
      user_type: resend.user_type,
      roleId: resend?.role?.id,
      subscribed,
    };
    await axios({
      method: "POST",
      withCredentials: true,
      url: `${backendUrl}/api/resend/link/${resend.id}`,
      data: body
    })
      .then((response) => {
        successHandler(response.data.message);
        setResendOpen(false);
        setResend(null);
      })
      .catch(function (error) {
        setResendOpen(false);
        setResend(null);
        errorHandler("Something went wrong. Please try again", true);
      });
  };

  const closeResendAlert = () => {
    setResend(null);
  };
  const revokeUserHandler = async () => {
    if (revoke) {
      await axios({
        method: "PATCH",
        withCredentials: true,
        url: `${backendUrl}/api/user/deactivation/${revoke.id}`,
        data: {
          status: "Revoked",
          reset_token: null,
          expire_token: null,
        },
      })
        .then((response) => {
          successHandler("User revoked");
          setRevoke(null);
        })
        .catch(function (error) {
          if (error.response) {
            setError(error.response.data.message);
            setRevoke(null);
          } else {
            setError(error.message);
            setRevoke(null);
          }
        });
    }
  };

  const closeRevokeHandler = () => {
    setRevoke(null);
  };

  const deactivateHandler = () => {
    Promise.all([deactivateUserHandler()])
      .then((result) => {
        setDeactivate(null);
        successHandler("User account is successfully deactivated");
        navigate("/app/users");
      })
      .catch((e) => {
        setDeactivate(null);
        errorHandler("Something went wrong", true);
      });
  };

  const deactivateUserHandler = async () => {
    if (deactivate) {
      await axios({
        method: "PATCH",
        withCredentials: true,
        url: `${backendUrl}/api/user/deactivation/${deactivate.id}`,
        data: {
          status: "Deactivated",
        },
      })
        .then((response) => {
          return true;
        })
        .catch(function (error) {
          if (error.response) {
            setDeactivate(null);
            throw new Error();
          } else {
            setDeactivate(null);
            throw new Error();
          }
        });
    }
  };

  const searchParamsHandler = (value) => {
    setPage(1);
    setSearchParams(value);
  };

  const closeDeactivateAlert = () => {
    setDeactivate(null);
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "#",
      iconName: "carbon:user-multiple",
      name: "Users",
    },
  ];

  const retry = () => {
    setError(null);
    setTimeout(() => {
      getData();
    }, 100);
  };

  return (
    <Fragment>
      {partTimeSubError && (
        <CautionAlert
          severity="error"
          open={partTimeSubError}
          content={partTimeSubError}
          onCloseActionB={() => {
            setPartTimeSubError();
            return resendFunction();
          }}
          onCloseActionA={() => setPartTimeSubError()}
          okText="Yes"
          cancelText="No"
        />
      )}
      {subscriptionError && (
        <CautionAlert
          severity="error"
          open={subscriptionError}
          content={subscriptionError}
          oneButton={true}
          onCloseActionB={() => setSubscriptionError()}
          okText="Ok"
        />
      )}
      {resendOpen && resend && (
        <CautionAlert
          severity="error"
          open={resendOpen}
          content={
            resend.status === "Revoked" || resend.status === "Deactivated"
              ? `Send activation link for ${resend.name}?`
              : `Resend activation link for ${resend.name}?`
          }
          okText={
            resend.status === "Revoked" || resend.status === "Deactivated"
              ? "Send"
              : "Resend"
          }
          cancelText="Cancel"
          onCloseActionB={resendActivationHandler}
          onCloseActionA={closeResendAlert}
        />
      )}
      {deactivate && (
        <CautionAlert
          severity="error"
          open={deactivate}
          content="Are you sure you want to deactivate the user?"
          onCloseActionB={deactivateHandler}
          onCloseActionA={closeDeactivateAlert}
          okText="Yes"
          cancelText="No"
        />
      )}

      {deactivateAlert && (
        <CautionAlert
          severity="error"
          open={deactivateAlert}
          content={deactivateAlert}
          onCloseActionB={() => setDeactivateAlert()}
          okText="Ok"
          oneButton={true}
        />
      )}

      {revoke && (
        <CautionAlert
          severity="error"
          open={revoke}
          content={`Revoke user invitation?`}
          okText="Revoke"
          cancelText="Cancel"
          onCloseActionB={revokeUserHandler}
          onCloseActionA={closeRevokeHandler}
        />
      )}

      <Breadcrumb options={breadCrumbData} />

      {location.state && location.state.showAlert ? (
        <CautionAlert
          severity="success"
          success={true}
          open={location.state.showAlert}
          content={location.state.showAlert}
          onCloseActionB={() =>
            navigate("/app/users", location.state.showAlert, {})
          }
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      {openAddUser ? (
        <AddUser
          closeAction={closeAddUserHandler}
          successMsg={successHandler}
        />
      ) : null}

      {success ? (
        <CautionAlert
          severity="success"
          success={true}
          open={success}
          content={success}
          oneButton={true}
          onCloseActionB={() => setSuccess(null)}
          okText="Ok"
        />
      ) : null}

      {error ? (
        <CautionAlert
          severity="error"
          open={error}
          content={error}
          oneButton={true}
          onCloseActionB={() => setError(null)}
          okText="Ok"
        />
      ) : null}

      {showTable ? (
        <Fragment>
          <SearchUser searchParamsHandler={searchParamsHandler} />
          <PageHeader
            left={"System Users"}
            right={
              <Button
                fontSize="14px"
                onClickAction={() => navigate("/app/add/super-admin")}
                text="Add Super Admin"
                startIcon={
                  <Icon style={{ fontSize: "14px" }} icon="akar-icons:plus" />
                }
              ></Button>
            }
          />

          {data && (
            <CustomDataTable
              data={data}
              length={count}
              page={page}
              perPage={perPage}
              columns={columns.userTableColumns}
              onPageChange={onPageChange}
            />
          )}

          <LoaderWithoutBackdrop open={loader} />
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default Users;
