import React, { Fragment, useEffect, useState } from "react";
import DataTable from "../../Layout/DataTable/DataTable";
import ActionButton from "../../UI/ActionButton/ActionButtonBox";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Button from "../../UI/Button/Button";
import { Icon } from "@iconify/react";
import CreateAppointment from "./AddAppointmentType";
import EditAppointmentComp from "./EditAppointment";
import { DropdownButton } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

// Import these files for breadcrumbs
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";

import CautionAlert from "../../utils/CautionAlert";
import axios from "axios";
import { Alert, Backdrop } from "@mui/material";
import backendUrl from "../../utils/backendUrl";
import LoaderWithoutBackdrop from "../../UI/Loader/LoaderWithoutBackdrop";
import DataView from "../../Layout/DataView/DataView";
import resource from "../../Resources/AppointmentTypeColumn";
import Theme from "../../Theme/Theme";
import Loader from "../../UI/Loader/Loader";
import storageService from "../../utils/localStorageHelpers";
import { checkPrivilage } from "../../utils/checkPrivilage";
import CryptoJS from "crypto-js";

const AppointmentType = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const canCreate = checkPrivilage("GLOBAL_APPOINTMENT_TYPES", "CREATE");
  const canUpdate = checkPrivilage("GLOBAL_APPOINTMENT_TYPES", "UPDATE");
  const canDelete = checkPrivilage("GLOBAL_APPOINTMENT_TYPES", "DELETE");

  const [data, setData] = useState([]);
  const [Appointment, setAppointment] = useState(null);
  const [passIdAppointment, setpassIdAppointment] = useState(null);
  const [passIdDelete, setPassIdDelete] = useState(null);
  const [showTable, setShowTable] = useState(true);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [viewAppointment, setviewAppointment] = useState(false);
  const [editAppointment, setEditAppointment] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [loader, setLoader] = React.useState(false);

  const [deleteLoader, setDeleteLoader] = useState(false);

  const [success, setSuccess] = useState("");
  const [loadData, getloadData] = useState(null);

  const closeAddUserHandler = () => {
    setOpenAddUser(false);
  };

  const closeAppointmentView = () => {
    setviewAppointment(false);
    setShowTable(true);
  };

  const closeAppointmentEdit = () => {
    setEditAppointment(false);
    setShowTable(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      dataField: "appointment_type",
      text: "Appointment Type",
    },
    {
      dataField: "description",
      text: "Description",
    },
    // {
    //   dataField: "last_name",
    //   text: "Last Name",
    // },
    {
      dataField: "duration",
      text: "Duration",

      formatter: (cell, row) => {
        let splitTime = row.duration;
        const splittedTime = splitTime === null ? "" : splitTime.split(":");
        return (
          <div>{`${row.duration} ${
            splittedTime[1] === "00"
              ? "Hours"
              : splittedTime[1] === "0"
              ? "Hour"
              : splittedTime[1] === null
              ? " "
              : "Minutes"
          }`}</div>
        );
      },
    },
    // {
    //   dataField: "role.name",
    //   text: "Role",
    // },
    // {
    //   dataField: "is_provider",
    //   text: "Is Provider?",
    // },
    {
      text: "Created by",
      dataField: "created",
      formatter: (row) => {
        return row?.name;
      },
    },
    // {
    //   dataField: "mobile_number",
    //   text: "Mobile Number",
    // },

    {
      dataField: "action",
      text: "Action",
    },
  ];

  const ShowAppointment = (item) => {
    setviewAppointment(true);
    setAppointment(item);
  };

  const ShowEditAppointment = (item) => {
    setEditAppointment(true);
    setAppointment(item);
    setpassIdAppointment(item);
  };

  const sendIdForDelete = (item) => {
    setPassIdDelete(item);
  };

  const reloadHandlerForCreate = () => {
    getData();
    setOpenAddUser(false);
  };

  const reloadHandlerForEdit = () => {
    getData();
    setEditAppointment(false);
    setShowTable(true);
  };

  const getData = async () => {
    let arr = [];
    setLoader(true);

    if (location.state && location.state.users) {
      setData(location.state.users);
      setLoader(false);
      return;
    }
    await axios({
      method: "GET",
      withCredentials: true,
      url: `${backendUrl}/api/appointment_types`,
    })
      .then(async (response) => {
        let dt;
        var bytes = CryptoJS.AES.decrypt(
          response?.data?.data,
          "secret key usr2323"
        );
        dt = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        dt = [...dt];
        return Promise.all(
          dt.map(async (item) => {
            arr.push({
              ...item,

              action: (
                <>
                  <style type="text/css">
                    {`
                      .ActionButton button {
                        background-color:${Theme.palette.primaryColor.backgroundColor};
                      }
                  `}
                  </style>
                  <DropdownButton
                    align="end"
                    title="Actions"
                    id="dropdown-menu-align-start"
                    className="ActionButton"
                  >
                    <ActionButton
                      content="View"
                      onClickAction={() =>
                        navigate(`/app/appointment-type/view/${item.id}`, {
                          state: { data: item },
                        })
                      }
                      icon="carbon:view"
                      eventKey="1"
                    />
                    {!item.from && canUpdate ? (
                      <ActionButton
                        content="Edit"
                        onClickAction={() =>
                          navigate(`/app/appointment-type/edit/${item.id}`, {
                            state: { data: item },
                          })
                        }
                        icon="ep:edit"
                        eventKey="2"
                      />
                    ) : null}

                    {!item.from && canDelete ? (
                      <ActionButton
                        content="Delete"
                        onClickAction={(e) => {
                          setOpen(true);
                          sendIdForDelete(item);
                        }}
                        icon="ant-design:delete-outlined"
                        eventKey="3"
                      />
                    ) : null}
                  </DropdownButton>
                </>
              ),
            });
          })
        );
      })
      .then(() => {
        setData(arr);
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);

        setApiError("Something went wrong. Please try again");
      });
  };

  const handleDelete = async (id) => {
    setDeleteLoader(true);
    await axios({
      method: "DELETE",
      withCredentials: true,
      url: `${backendUrl}/api/appointment_types/${id}`,
    })
      .then((response) => {
        setOpen(false);
        setSuccess(response.data.message);
        getData();
        setDeleteLoader(false);
      })
      .catch(function (error) {
        setOpen(false);
        if (error.response) {
          setDeleteLoader(false);
          setError("Something went wrong. Please try again");
        } else {
          setDeleteLoader(false);
          setError("Something went wrong. Please try again");
        }
      });
  };

  const deleteConfirm = () => {
    handleDelete(passIdDelete.id);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "#",
      iconName: "cil:filter",
      name: "Appointment Types",
    },
  ];

  const editView = () => {
    setviewAppointment(false);
    setEditAppointment(true);
    setShowTable(false);
  };

  const formatter = (cell, row) => {
    let splitTime = row.duration;
    const splittedTime = splitTime.split(":");
    return (
      <div>{`${row.duration} ${
        splittedTime[1] === "00" || splittedTime[1] === "0" ? "Hour" : "Minutes"
      }`}</div>
    );
  };

  const retry = () => {
    setApiError(null);
    setLoader(true)
    setTimeout(() => {
      getData();
    }, 100);
  };

  return (
    <Fragment>
      <Loader open={deleteLoader} />

      <CautionAlert
        severity="error"
        open={open}
        content="Are you sure you want to delete this item?"
        onCloseActionB={deleteConfirm}
        onCloseActionA={handleClose}
        okText="Delete"
        cancelText="Cancel"
      />

      <Breadcrumb options={breadCrumbData} />

      {location.state && location.state.showAlert ? (
        <>
          <CautionAlert
            severity="success"
            success={true}
            open={location.state.showAlert}
            content={location.state.showAlert}
            onCloseActionB={() =>
              navigate("/app/appointment-type", location.state.showAlert, {})
            }
            okText="Ok"
            oneButton={true}
          />
        </>
      ) : null}

      {success ? (
        <CautionAlert
          severity="success"
          success={true}
          open={success}
          content={success}
          onCloseActionB={() => setSuccess(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : (
        <></>
      )}

      {apiError ? (
        <CautionAlert
          severity="error"
          open={apiError}
          content={apiError}
          onCloseActionB={retry}
          onCloseActionA={() => setApiError(null)}
          okText="Retry"
          cancelText="Dismiss"
        />
      ) : null}

      {error ? (
        <CautionAlert
          severity="error"
          open={error}
          content={error}
          onCloseActionB={() => setError(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      {openAddUser ? (
        <CreateAppointment
          reload={reloadHandlerForCreate}
          loadDataComp={loadData}
          onClickAction={closeAddUserHandler}
        />
      ) : null}

      {viewAppointment && Appointment ? (
        <DataView
          title="View Appointment"
          columns={resource.appointmentTypeViewColumns}
          data={Appointment}
          editView={editView}
          closeView={closeAppointmentView}
        />
      ) : null}

      {editAppointment && Appointment ? (
        <EditAppointmentComp
          reload={reloadHandlerForEdit}
          appointments={Appointment}
          passIdAppointment={passIdAppointment}
          onClickAction={closeAppointmentEdit}
        />
      ) : null}

      {showTable ? (
        <>
          <PageHeader
            left={"Appointment Types"}
            right={
              canCreate
                ? !openAddUser && (
                    <Button
                      // disabled={openAddUser === true ? true : false}
                      onClickAction={() =>
                        navigate("/app/appointment-type/add")
                      }
                      text="Add Appointment Type"
                      startIcon={
                        <Icon
                          style={{ fontSize: "14px" }}
                          icon="akar-icons:plus"
                        />
                      }
                    ></Button>
                  )
                : null
            }
          />

          {data && <DataTable data={data} columns={columns} />}

          <LoaderWithoutBackdrop open={loader} />
        </>
      ) : null}
    </Fragment>
  );
};

export default AppointmentType;
