import React, { Fragment, useEffect, useRef, useState } from "react";
import { Alert, Backdrop, Grid } from "@mui/material";
import { Box } from "@mui/system";
import PageFooter from "../../Layout/PageFooter/PageFooter";
import { ThemeProvider } from "react-bootstrap";
import Loader from "../../UI/Loader/Loader";
import Button from "../../UI/Button/Button";
import theme from "../../Theme/Theme";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import classes from "./UserSubscription.module.css";
import TextBox from "../../UI/FormGroup/TextBox/TextBox";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../UI/FormGroup/SelectBox/SelectBox";
import storageService from "../../utils/localStorageHelpers";
import DateSelector from "../../UI/FormGroup/DateSelector/DateSelector";
import moment from "moment";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchSite from "../../UI/SearchSite/SearchSite";

export default function SearchSubscription(props) {
  const widthIs14 = useMediaQuery("(max-width:1399px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");
  const navigate = useNavigate();

  const loadViewPatient = async (id, user) => {
    navigate(`/app/patient/view/${id}`, { state: { user: user } });
  };

  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [isEmpty, setIsEmpty] = useState(null);
  const [site, setSite] = useState(null);
  const [status, setStatus] = useState(null);

  const selectedSite = (value) => {
    setSite(value);
  };

  const statusHandler = (e) => {
    checkingValue();
    setStatus(e.target.value);
  };

  const StatusOption = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "Deactivated",
      label: "Deactivated",
    },
    {
      value: "Expired",
      label: "Expired",
    },
    {
      value: "Revoked",
      label: "Revoked",
    },
  ];

  const checkingValue = () => {
    if (!site || !status) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  };

  const searchPatient = async () => {
    let params = null;
    if (site) {
      params = {
        site_id: site.id,
      };
    }
    if (status && status !== "all") {
      params = {
        ...params,
        status: status,
      };
    }
    props.searchParamsHandler(params);
  };

  const resetForm = () => {
    setSite(null);
    setStatus("all");
    props.searchParamsHandler(null);
  };

  const retry = () => {
    setApiError(null);
    setTimeout(() => {
      props.retry();
    }, 100);
    // searchPatient();
  };

  return (
    <ThemeProvider theme={theme}>
      <Loader open={loader} />

      {apiError ? (
        <>
          <Alert
            severity="error"
            sx={{
              mb: 2,
              border: "1px solid red",
              zIndex: "99999 !important",
              position: "relative",
            }}
            action={
              <>
                <Button
                  onClickAction={retry}
                  color="inherit"
                  size="small"
                  text="Retry"
                />
                <Button
                  onClickAction={() => setApiError(null)}
                  color="inherit"
                  size="small"
                  text="Dismiss"
                  margin="0px 10px !important"
                />
              </>
            }
          >
            {apiError + "ddddfdfd"}
          </Alert>
          <Backdrop
            sx={{ backgroundColor: "#00000000", zIndex: 500 }}
            open={apiError}
          ></Backdrop>
        </>
      ) : null}

      {success ? (
        <Alert severity="success" sx={{ mb: 2, border: "1px solid green" }}>
          {success}
        </Alert>
      ) : null}

      {error ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      ) : null}

      <PageHeader left="Search Subscription" />
      <form>
        <Box className={classes.FormBox}>
          <Grid container direction="row" spacing={2}>
            <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
              className={classes.Max}
            >
              <SearchSite
                xsLabel={4}
                xsInside={8}
                selectedSite={selectedSite}
                value={site}
              />
            </Grid>

            <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              className={classes.Max}
              sx={{
                marginLeft: widthIs1630 ? "" : "80px",
                maxWidth: widthIs14 ? "" : "590px !important",
              }}
            >
              <SelectBox
                label="Status"
                options={StatusOption}
                value={status}
                onChangeAction={statusHandler}
                search={true}
                // onBlurAction={titleValidation}
                // error={titleError}
              />
            </Grid>
          </Grid>
        </Box>

        <PageFooter
          right={
            <Fragment>
              <Box
                sx={{
                  px: 2,
                }}
              >
                <Button text="Search" onClickAction={searchPatient} />
              </Box>
              <Box>
                <Button
                  fontSize="14px"
                  type="reset"
                  onClickAction={resetForm}
                  bgLight={true}
                  text="Clear"
                />
              </Box>
            </Fragment>
          }
        />
      </form>
    </ThemeProvider>
  );
}
