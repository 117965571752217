import React, { Fragment } from "react";
import styles from "./PageFooter.module.css";
import { Col, Row } from "react-bootstrap";
import { Box } from "@mui/material";

const PageFooter = (props) => {
  return (
    <Fragment>
      <Box
        className={`${styles.Box} ${props.noMargin ? "" : "mb-4"}`}
        sx={props.sxBox}
      >
        <Col md={12} style={{ height: "100%" }}>
          <Row style={{ height: "100%" }}>
            <Col
              className="pt-1 d-flex align-items-center"
              style={{ height: "100%" }}
            >
              {props.left}
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              {props.right}
            </Col>
          </Row>
        </Col>
      </Box>
    </Fragment>
  );
};

export default PageFooter;
