import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import Theme from "../../Theme/Theme";

export default function Breadcrumb(props) {
  return (
    <>
      <style type="text/css">
        {`
          #breadcrumb {
            list-style: none;
            display: inline-block;
            padding: 0px;
          }
          #breadcrumb .icon {
            font-size: 14px;
          }
          #breadcrumb li {
            float: left;
          }

          #breadcrumb li a {
            color: #FFF;
            display: block;
            background:${Theme.palette.secondaryColor.backgroundColor};
            text-decoration: none;
            position: relative;
            height: 40px;
            line-height: 40px;
            padding: 0 10px 0 5px;
            text-align: center;
            margin-right: 23px;
          }
          #breadcrumb li:nth-child(even) a {
            background-color: ${Theme.palette.secondaryColor.backgroundColor};
          }
          #breadcrumb li:nth-child(even) a:before {
            border-color: ${Theme.palette.secondaryColor.backgroundColor};
            border-left-color: transparent;
          }
          #breadcrumb li:nth-child(even) a:after {
            border-left-color: ${Theme.palette.secondaryColor.backgroundColor};
          }
          #breadcrumb li:first-child a {
            padding-left: 15px;
          }
          #breadcrumb li:first-child a:before {
            border: none;
          }
          #breadcrumb li:last-child a {
            padding-right: 15px;
          
          }
          #breadcrumb li:last-child a:after {
            border: none;
          }
          #breadcrumb li a:before, #breadcrumb li a:after {
            content: "";
            position: absolute;
            top: 0;
            border: 0 solid ${Theme.palette.secondaryColor.backgroundColor};
            border-width: 20px 10px;
            width: 0;
            height: 0;
          }
          #breadcrumb li a:before {
            left: -20px;
            border-left-color: transparent;
          }
          #breadcrumb li a:after {
            left: 100%;
            border-color: transparent;
            border-left-color: ${Theme.palette.secondaryColor.backgroundColor};
          }
          #breadcrumb li a:hover {
            background-color: #615586;
          }
          #breadcrumb li a:hover:before {
            border-color: #615586;
            border-left-color: transparent;
          }
          #breadcrumb li a:hover:after {
            border-left-color: #615586;
          }
          #breadcrumb li a:active {
            background-color: #615586;
          }
          #breadcrumb li a:active:before {
            border-color: #615586;
            border-left-color: transparent;
          }
          #breadcrumb li a:active:after {
            border-left-color: #615586;
          }
        `}
      </style>
      <ul id="breadcrumb">
        {props.options &&
          props.options.map((item) => {
            return (
              <li>
                <Link style={item.customStyles} to={item.link}>
                  <Icon icon={item.iconName} style={{ marginRight: "10px" }} />
                  {item.name}
                </Link>
              </li>
            );
          })}
      </ul>
    </>
  );
}
