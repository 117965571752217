import { Grid, ThemeProvider } from "@mui/material";
import React from "react";
import Theme from "../../Theme/Theme";
import notFoundImage from "./../../../assets/Images/Notfound.jpg";
import classes from "./Notfound.module.css";

const NotFound = () => {
  return (
    <ThemeProvider theme={Theme}>
      <div className={classes.outer} style={{ height: "100vh" }}>
        <Grid container>
          <Grid
            item
            sx={{
              height: "100vh",
              background: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img className="NotFoundImage" src={notFoundImage} />
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default NotFound;
