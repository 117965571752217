import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  patientAPIError: null,
  headerpopUpError: null,
};

const errorSlice = createSlice({
  name: "error",
  initialState: INITIAL_STATE,
  reducers: {
    setPatientAPIError: (state, action) => {
      state.patientAPIError = action.payload;
    },
    clearPatientAPIError: (state) => {
      state.patientAPIError = null;
    },
    setHeaderPopUpError: (state, action) => {
      state.headerpopUpError = action.payload;
    },
    clearHeaderPopUpError: (state) => {
      state.headerpopUpError = null;
    },
  },
});

export const {
  setPatientAPIError,
  clearPatientAPIError,
  setHeaderPopUpError,
  clearHeaderPopUpError,
} = errorSlice.actions;

export default errorSlice.reducer;
