import moment from "moment/moment";
import timestampHandler from "../utils/timstampHandler";

const appointmentTypeViewColumns = [
  {
    field: "appointment_type",
    title: "Appointment Type",
  },
  {
    field: "duration",
    title: "Duration",
    formatter: (row) => {
      let splitTime = row;
      const splittedTime = splitTime === null ? "" : splitTime.split(":");
      return `${row} ${
        splittedTime[1] === "00"
          ? "Hours"
          : splittedTime[1] === "0"
          ? "Hour"
          : splittedTime[1] === null
          ? " "
          : "Minutes"
      }`;
    },
  },
  {
    field: "description",
    title: "Description",
  },
  {
    field: "created",
    title: "Created by",
    formatter: (row) => {
      return row.name;
    },
  },
  {
    field: "created_at",
    title: "Created On",
    formatter: (row) => {
      return timestampHandler(row, "DD/MM/YYYY");
    },
  },
  {
    field: "updated",
    title: "Last updated by",
    formatter: (row) => {
      return row.name;
    },
  },
  {
    field: "updated_at",
    title: "Last updated on",
    formatter: (row) => {
      return timestampHandler(row, "DD/MM/YYYY");
    },
  },
];

const fNames = { appointmentTypeViewColumns };

export default fNames;
