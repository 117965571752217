import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import React, { Fragment } from "react";
import classes from "./RadioGroup.module.css";

const CustomRadioGroup = (props) => {
  return (
    <Fragment>
      <Grid container spacing={2} sx={props.sx ? props.sx : ""}>
        {!props.noLabel && (
          <Grid item xs={props.xsLabel || 4} className="LabelBox">
            <label>
              {props.label}
              {props.required ? <sup>*</sup> : null}
            </label>
          </Grid>
        )}

        <Grid item xs={props.xsInside || 8}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={props.value}
            onChange={props.onChangeAction}
          >
            {props.options &&
              props.options.map((item, index) => (
                <FormControlLabel
                  className={`RadioInput`}
                  value={item.value}
                  name={props.name}
                  id={item.label}
                  disabled={props.disabled || item.disabled}
                  checked={props.value === item.value}
                  label={item.label}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      color:
                        props.disabled || item.disabled ? "#d3d3d3" : "#615586",
                    },

                    "& .MuiTypography-root": {
                      fontSize: "14px",
                      ...props.sxRadio,
                    },
                  }}
                  control={<Radio />}
                />
              ))}
            <br />
          </RadioGroup>
          {props.error ? (
            <label className={classes.ErrorMsg}>{props.error}</label>
          ) : null}
        </Grid>
      </Grid>
      {/* </Grid> */}
    </Fragment>
  );
};

export default CustomRadioGroup;
