import { Container, Row, Col, Stack } from "react-bootstrap";
import React, { Fragment } from "react";
import classes from "./Footer.module.css";

const Footer = () => {
  const now = new Date();
  return (
    <Fragment>
      <div className={classes.Footer}>
        <Container fluid>
          <Row>
            <Col md={6}>
              <Stack
                direction="horizontal"
                className="justify-content pt-3"
                style={{ width: "100%" }}
                gap={5}
              >
                <p>Medipapel | Axon | ver. 1.01.1</p>
              </Stack>
            </Col>
            <Col md={6}>
              <Stack
                direction="horizontal"
                className="justify-content-end pt-3"
                style={{ width: "100%" }}
                gap={5}
              >
                <p>© Copyright {now.getFullYear()}. All right reserved</p>
              </Stack>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Footer;
