import { createTheme } from '@mui/material/styles';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const Theme = createTheme({
    palette: {
      primaryColor:{
        backgroundColor:"#56438E",
        color:"#56438E",
        main:"#56438E"
      },
      secondaryColor: {
        backgroundColor:"#8E78B7",
      },
      secondaryColor2: createColor('#AA95D1'),
    },

    typography: {
        fontFamily: [
          'Open Sans',
          'sans-serif',
        ].join(','),
       "fontSize": 14,
       "fontSizeHead" : 15,
       "fontWeightLight": 300,
       "fontWeightRegular": 400,
       "fontWeightMedium": 600,
       "fontWeightBold": 700,
      }
      
})

export default Theme;
