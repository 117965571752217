import React, { Fragment, useEffect, useState, useCallback } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import classes from "./SideMenu.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "./../../../assets/Images/DarkLogo.png";

import menu from "../../Resources/SideMenuConfigure";
import ListButton from "../../UI/ListButton/ListButton";
import { Box, Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import { checkAnyPrivilage, checkPrivilage } from "../../utils/checkPrivilage";
import storageService from "./../../utils/localStorageHelpers";

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "#F0F3EF",
  top: "69px",
  height: "calc(100% - 118px)",
  overflow: "scroll",
  overflow: "overlay",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",

  [theme.breakpoints.down("lg")]: {
    backgroundColor: "#fff",
    top: 0,
    zIndex: 999,
    height: "100%",
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  top: "69px",
  height: "calc(100% - 118px)",
  backgroundColor: "#F0F3EF",
  overflowX: "hidden",
  overflowY: "scroll",
  overflowY: "overlay",
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.down("lg")]: {
    backgroundColor: "#fff",
    width: 0,
    left: -1,
    top: 0,
    zIndex: 999,
    height: "100%",
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideMenu = () => {
  let siteDt = JSON.parse(localStorage.getItem("siteDetails"));
  // siteDt = siteDt[0];
  const location = useLocation();
  const pathname = location.pathname;
  const user = storageService.loadUser()?.site_user;

  const [testTrue, setTestTrue] = useState(true);

  useEffect(() => {
    if (location.state && location.state.menu) {
      setTestTrue(true);

      // setTimeout(() => {
      //   navigate("/app/user", location.state.showAlert, {});
      // }, 2000);
    }

    if (testTrue === true) {
      setOpen(true);
    }
  }, []);

  const [menuData, setMenuData] = useState(menu);
  const [collapseCount, setCollapseCount] = useState(1);

  let closedPaths = [
    "/app/appointments",
    "/app/template-management/editor",
    "app/template-management/edit",
  ];

  useEffect(() => {
    let closed = closedPaths.find((a) => location.pathname.includes(a));
    if (closed) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [location]);

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const expandHandler = (index) => {
    let menu = menuData[index];
    menu.expanded = !menu.expanded;
    let data = [...menuData];
    data[index] = menu;
    setMenuData(data);
    setCollapseCount((prev) => {
      return prev++;
    });
  };

  const navigate = useNavigate();

  const locationHandler = useCallback(
    (path) => navigate(path, { replace: true }),
    [navigate]
  );

  const [selectedMenu, setSelectedMenu] = useState(null);

  const getActiveMenu = (menu) => {
    setSelectedMenu(menu);
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        flexShrink: 0,
        zIndex: 1,
      }}
    >
      <Grid
        container
        display={{ xs: "flex", lg: "none", justifyContent: "space-between" }}
      >
        <Box item xs={12}>
          <div className={classes.Logo}>
            <img src={logo} className="img-fluid" alt="Logo" />
          </div>
        </Box>
        <Box item xs={2} sx={{ fontSize: "30px", py: 1, px: 2 }}>
          <Icon
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            className={classes.MenuIcon}
            icon="gg:menu"
          />
        </Box>
      </Grid>

      <List sx={{ paddingTop: "0", paddingBottom: "0" }}>
        {menuData &&
          menuData.map((item, index) => {
            let permission = true;
            if (item.permission && item.permission?.length > 0) {
              const permissionArr = item.permission?.map((p) => {
                return checkAnyPrivilage(p);
              });
              let isTrue = permissionArr.includes(true);

              if (!isTrue) {
                permission = false;
              }
            }

            if (!permission) {
              return null;
            }
            return item.subMenu ? (
              <Fragment>
                <ListButton
                  text={item.name}
                  subMenu={item.subMenu}
                  open={open}
                  icon={item.icon}
                  onClickAction={() => {
                    expandHandler(index);
                  }}
                  expanded={item.expanded}
                  fontSize={item.fontSize}
                />
                {item.expanded &&
                  item.subMenu.map((menu, index) => {
                    let subPermission = true;

                    if (item.permission && menu.permission?.length > 0) {
                      const permissionArr = menu.permission?.map((p) => {
                        if (p.name) {
                          return checkPrivilage(p.name, p.operation);
                        } else {
                          return checkAnyPrivilage(p);
                        }
                      });
                      let isFalse = permissionArr.includes(false);

                      if (isFalse) {
                        subPermission = false;
                      }
                    }

                    return (
                      <ListButton
                        text={menu.name}
                        open={open}
                        onClickAction={(e) => {
                          locationHandler(menu.to);
                          getActiveMenu(menu.to);
                        }}
                        active={pathname.includes(menu.to) ? true : false}
                        icon={menu.icon}
                        className={classes.ListSubMenuText}
                        fontSize={menu.fontSize}
                        isSub={true}
                      />
                    );
                  })}
              </Fragment>
            ) : (
              <ListButton
                text={item.name}
                subMenu={item.subMenu}
                open={open}
                onClickAction={(e) => {
                  locationHandler(item.to);
                  getActiveMenu(item.to);
                }}
                active={pathname.includes(item.to) ? true : false}
                icon={item.icon}
                fontSize={item.fontSize}
              />
            );
          })}
        {open ? (
          <ListButton
            text="COLLAPSE"
            open={open}
            onClickAction={handleDrawerClose}
            icon="akar-icons:chevron-left"
          />
        ) : (
          <ListButton
            text="COLLAPSE"
            open={open}
            onClickAction={handleDrawerOpen}
            icon="akar-icons:chevron-right"
          />
        )}

        <Divider variant="fullWidth" component="li" />
      </List>
    </Drawer>
  );
};

export default SideMenu;
