import { Icon } from "@iconify/react/dist/iconify.js";
import { Grid } from "@mui/material";
import React, { memo } from "react";
import Button from "../../UI/Button/Button";

const ShowImage = memo(({ image, deleted, handleDelete, name }) => {
  console.log("Child rendered");

  return (
    <Grid container spacing={2} mb={1}>
      <Grid item xs={4} className={`LabelBox`}></Grid>

      <Grid item xs={4}>
        <div
          style={{
            // background: "#56438E",
            // padding: "10px",
            height: "150px",
            width: "100px",
            // borderRadius: "15px",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${
                image + `?datetime=${new Date().getTime()}`
              })`,
              height: "100%",
              width: "100%",
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </div>
      </Grid>
      <Grid item xs={4} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Button
          startIcon={<Icon icon="fluent:delete-24-regular" />}
          text="Delete"
          onClickAction={() => handleDelete(name)}
          disabled={deleted}
        />
      </Grid>
    </Grid>
  );
});

export default ShowImage;
