import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader(props) {
  return (
    <Backdrop
    style={{zIndex:99999}}
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999999999999 }}
    open={props.open}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
  )
}
