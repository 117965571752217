import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import CustomButton from "../UI/Button/Button";
import classes from "./Dialogue.css";
import { makeStyles } from "@material-ui/core/styles";

export default function ActionAlerts(props) {
  const useStyles = makeStyles((theme) => ({
    dialogPaper: {
      height: "10px",
    },
  }));

  return (
    <Dialog
      classes={{ paper: useStyles.dialogPaper }}
      open={props.open}
      fullWidth={true}
      fullScreen={false}
      maxWidth="xs"
    >
      <Alert
        severity={props.severity}
        className={classes.customDialogue}
        sx={{
          "&.MuiAlert-message": {
            padding: "0 15px 0 0 !important",
          },
          padding: "25px 0px 0px 25px",
        }}
      >
        {props.content}
      </Alert>

      <DialogActions
        sx={{
          padding: "0px 25px 25px 0px",
          backgroundColor: props.success ? "#edf7ed" : props.info ? "#E5F6FD" : "#fdeded",
          margin: "0px",
        }}
      >
        {props.noButton ? (
          ""
        ) : props.oneButton ? (
          <CustomButton
            text={props.okText}
            onMouseDownAction={props.onCloseActionB}
          ></CustomButton>
        ) : (
          <>
            <CustomButton
              text={props.okText}
              onMouseDownAction={props.onCloseActionB}
            ></CustomButton>
            <CustomButton
              bgLight={true}
              text={props.cancelText}
              onMouseDownAction={props.onCloseActionA}
            ></CustomButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
