const storageKeyToken = "medipapelUser";

const siteKey = "siteDetails";

const privilagesKey = "RSA-privilages";

const patient = "pdt";

const saveUser = (user) =>
  sessionStorage.setItem(storageKeyToken, JSON.stringify(user));

const loadUser = () => JSON.parse(sessionStorage.getItem(storageKeyToken));

const logoutUser = () => {
  sessionStorage.removeItem(storageKeyToken);
};

const saveSite = (site) => localStorage.setItem(siteKey, JSON.stringify(site));

const loadSite = () => JSON.parse(localStorage.getItem(siteKey));

const removeSite = () => localStorage.removeItem(siteKey);

const savePermission = (privilages) =>
  localStorage.setItem(privilagesKey, JSON.stringify(privilages));

const loadPermission = () => JSON.parse(localStorage.getItem(privilagesKey));

const loadPatient = () => JSON.parse(localStorage.getItem(patient));

const fNames = {
  saveUser,
  loadUser,
  logoutUser,
  saveSite,
  loadSite,
  removeSite,
  loadPatient,
  savePermission,
  loadPermission,
};

export default fNames;
