import {
  Alert,
  Box,
  Grid,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import PageFooter from "../../Layout/PageFooter/PageFooter";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Theme from "../../Theme/Theme";
import Button from "../../UI/Button/Button";
import TextBox from "../../UI/FormGroup/TextBox/TextBox";
import Loader from "../../UI/Loader/Loader";
import classes from "./Site.module.css";
import UploadButton from "../../UI/FormGroup/UploadButton/UploadButton";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import storageService from "../../utils/localStorageHelpers";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import CautionAlert from "../../utils/CautionAlert";
import { useDispatch } from "react-redux";
import { saveSite } from "../../../redux/site";
import CryptoJS from "crypto-js";
import CustomRadioGroup from "../../UI/FormGroup/RadioGroup/RadioGroup";

export default function AddOrganisation() {
  const widthIs14 = useMediaQuery("(max-width:1399px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");
  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [success, setSuccess] = useState(null);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [mimsIntegrated, setMimsIntegrated] = useState(false);
  const [abn, setAbn] = useState(null);
  const [abnError, setAbnError] = useState(null);
  const [phone, setphone] = useState(null);
  const [phoneError, setphoneError] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [fax, setFax] = useState(null);
  const [faxError, setFaxError] = useState(null);
  const [headerLogo, setHeaderLogo] = useState(null);
  const [templateLogo, setTemplateLogo] = useState(null);
  const [headerLogoError, setHeaderLogoError] = useState(null);
  const [templateLogoError, setTemplateLogoError] = useState(null);
  const [deleteLogo, setDeleteLogo] = useState(null);
  const [headerDeleted, setHeaderDeleted] = useState(false);
  const [templateDeleted, setTemplateDeleted] = useState(false);

  const [fileUploadError, setFileUploadError] = useState(null);

  const [imageURL, setimageURL] = useState(null);

  const [data, setData] = useState(null);

  const nameHandler = (e) => {
    setNameError(null);
    setName(e.target.value);
  };

  const nameValidation = () => {
    if (!name) {
      setNameError("Required");
      return false;
    }
    return true;
  };
  const abnHandler = (e) => {
    setAbnError(null);
    setAbn(e.target.value);
  };

  const abnValidation = (e) => {
    if (!abn) {
      setAbnError("Required");
      return false;
    }
    return true;
  };

  const phoneHandler = (e) => {
    setphoneError(null);
    setphone(e.target.value);
  };

  const phoneValidation = () => {
    if (!phone) {
      setphoneError("Required");
      return false;
    }
    return true;
  };

  const emailHandler = (e) => {
    setEmailError(null);
    setEmail(e.target.value);
  };

  const emailValidation = () => {
    if (!email) {
      setEmailError("Required");
      return false;
    }
    if (!isEmail(email) && email && email.length > 0) {
      setEmailError("Invalid email address");
      return false;
    }
    return true;
  };

  const isEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const faxHandler = (e) => {
    setFaxError(null);
    setFax(e.target.value);
  };

  const faxValidation = () => {
    if (!fax) {
      setFaxError("Required");
      return false;
    }
    return true;
  };

  async function postData() {
    let body = {
      organisation_name: name,
      abn_number: abn,
      contact_email: email,
      contact_number: phone,
      fax: fax,
    };

    const result = await axios
      .post(
        `${backendUrl}/api/organisation`,
        body,
        { withCredentials: true },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((res) => {
        setLoader(false);
        navigate(`/app/organisations-management`, {
          state: {
            showAlert: "Organisation has been created successfully",
          },
        });
      })
      .catch((e) => {
        setLoader(false);
        if(e?.response?.data?.message.includes("duplicate")){
          setError("Organisation name is already exist")
          return
        }
        setError("Something went wrong. Please try again", true);
      });

    setimageURL(result.imagePath);

    return result.data;
  }

  const headerLogoHandler = (event) => {
    setHeaderLogoError("");
    let img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0]);

    const logo = event.target.files[0];
    setHeaderLogo(logo);
  };

  const templateLogoHandler = (event) => {
    setTemplateLogoError("");
    let img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0]);

    const logo = event.target.files[0];
    setTemplateLogo(logo);
  };

  const fileUploadValidation = (event, file) => {
    // var allowedExtensions =/(\.jpg|\.jpeg|\.png)$/i;
    let file_size = event.target.files[0].size;

    if (file_size > 1020157) {
      if (file === "HEADER") {
        
        setHeaderLogoError("File size exceeds 1 MB");
      }
      if (file === "TEMPLATE") {
        
        setTemplateLogoError("File size exceeds 1 MB");
      }
    }
  };

  const submitValidation = () => {
    let proceed = true;
    proceed = proceed && nameValidation();
    proceed = proceed && abnValidation();
    proceed = proceed && phoneValidation();
    proceed = proceed && emailValidation();
    proceed = proceed && faxValidation();

    if (proceed) {
      // All validations passed, proceed with submitting the form
      return true;
    } else {
      // Some validations failed, display error messages
      nameValidation();
      abnValidation();
      phoneValidation();
      emailValidation();
      faxValidation();

      return false;
    }
  };

  const submitHandler = async (event) => {
    setLoader(true);
    event.preventDefault();

    let isProceed = submitValidation();

    if (!isProceed) {
      setLoader(false);
      return false;
    }

    if (headerLogoError || templateLogoError) {
      setLoader(false);
      fileUploadValidation(null, "HEADER");
      fileUploadValidation(null, "TEMPLATE");
      return;
    }

    await postData();

    setLoader(false);
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/organisations-management",
      iconName: "bx:buildings",
      name: "Organisations Management",
    },
    {
      link: "#",
      iconName: "el:plus-sign",
      name: "Add Organisation",
    },
  ];

  const handleDelete = (logo) => {
    setDeleteLogo(logo);
  };

  const confirmDelete = async () => {
    if (deleteLogo === "template") {
      setTemplateDeleted(true);
    } else {
      setHeaderDeleted(true);
    }
    closeDelete();
  };

  const closeDelete = () => {
    setDeleteLogo();
  };

  const clearHeaderInput = () => {
    setHeaderLogoError();
    setHeaderLogo();
  };
  const clearTemplateInput = () => {
    setTemplateLogoError();
    setTemplateLogo();
  };

  const mimsIntegratedOptions = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  return (
    <ThemeProvider theme={Theme}>
      <Loader open={loader} />
      <Breadcrumb options={breadCrumbData} />
      {error ? (
        <CautionAlert
          severity="error"
          open={error}
          content={error}
          onCloseActionB={() => setError(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      {success ? (
        <CautionAlert
          severity="success"
          success={true}
          open={success}
          content={success}
          onCloseActionB={() => setSuccess(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}
      <CautionAlert
        severity="error"
        open={deleteLogo}
        content={`Are you sure you want to delete ${deleteLogo} logo?`}
        onCloseActionB={confirmDelete}
        onCloseActionA={closeDelete}
        okText="Delete"
        cancelText="Cancel"
      />

      <PageHeader left="Add Organisation" />
      <Loader open={loader} />

      <form>
        <Box className={classes.FormBox}>
          <Grid container direction="row" spacing={2}>
            {/* First Column */}
            <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
              className={classes.Max}
            >
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Organisation Name"
                onChangeAction={nameHandler}
                onBlurAction={nameValidation}
                value={name}
                error={nameError}
                placeholder="State"
                required
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="number"
                label="ABN"
                onChangeAction={abnHandler}
                value={abn}
                onBlurAction={abnValidation}
                error={abnError}
                placeholder="ABN"
                required
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="number"
                label="Phone Number"
                onChangeAction={phoneHandler}
                value={phone}
                onBlurAction={phoneValidation}
                error={phoneError}
                placeholder="Phone"
                required
              />
            </Grid>

            <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              className={classes.Max}
              sx={{
                marginLeft: widthIs1630 ? "" : "80px",
                maxWidth: widthIs14 ? "" : "590px !important",
              }}
            >
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Email"
                onChangeAction={emailHandler}
                value={email}
                onBlurAction={emailValidation}
                error={emailError}
                placeholder="Email"
                required
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Fax Number"
                onChangeAction={faxHandler}
                value={fax}
                onBlurAction={faxValidation}
                error={faxError}
                placeholder="Fax"
                required
              />
            </Grid>
          </Grid>
        </Box>
        <PageFooter
          right={
            <Fragment>
              <Box
                sx={{
                  px: 2,
                }}
              >
                <Button
                  text="Submit"
                  // type="submit"
                  onClickAction={submitHandler}
                />
              </Box>

              <Box>
                <Button
                  fontSize="14px"
                  onMouseDownAction={() => navigate(-1)}
                  bgLight={true}
                  text="Cancel"
                />
              </Box>
            </Fragment>
          }
        />
      </form>
    </ThemeProvider>
  );
}
