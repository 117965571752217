import { Grid, TextField } from "@mui/material";
import React, { Fragment } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import classes from "./DateSelector.module.css";
import Tooltip from "../../Tooltip/Tooltip";

function DateSelector(props) {
  return (
    <Fragment>
      {/* <Grid
        item
        xs={12}
        sm={12}
        md={props.md || 12}
        lg={props.lg || 6}
        spacing={2}
        className={classes.FormGroup}
      > */}
      <Grid container spacing={2}>
        <Grid item xs={props.xsLabel || 4} className={`LabelBox`}>
          <label>
            {props.label}
            {props.required ? <sup>*</sup> : null}
          </label>
        </Grid>
        <Grid item xs={props.xsInside || 8}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="item-relative">
              <DesktopDatePicker
                disabled={props.disabled}
                minDate={props.minDate}
                maxDate={props.maxDate}
                className={classes.DatePicker}
                autocomplete="off"
                item
                xs={8}
                inputFormat="dd/MM/yyyy"
                value={props.value}
                onChange={props.onChangeAction}
                renderInput={(params) => (
                  <TextField
                    // className={`SelectPrimary ${props.error ? "ErrorBorder": null}`}

                    onBlur={props.onBlurAction}
                    // className={classes.DatePickerField}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: props.error
                          ? "1.5px solid #FF0000 !important"
                          : "1.5px solid #E8E8E8",
                        // borderColor:"1.5px solid #E8E8E8"
                      },
                      "& .MuiInputBase-root": {
                        height: "41px",
                        borderRadius: "0px",
                        fontSize: "14px",

                        borderWidth: "0px !important",
                      },
                      "& .MuiOutlinedInput-input.Mui-disabled": {
                        WebkitTextFillColor: "rgb(0 0 0 / 67%) !important",
                      },
                      "& .MuiOutlinedInput-root.Mui-disabled": {
                        background: "#e9e9e9",
                        WebkitTextFillColor: "rgb(0 0 0 / 67%) !important",
                      },
                      "& .MuiInputBase-root:hover": {
                        backgroundColor: "none",
                        color: "none",
                        border: props.error
                          ? "1.5px solid #FF0000 !important"
                          : "0px !important",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1.5px solid #E8E8E8 !important",
                          outline: "none !Important",
                        },
                      "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
                        {
                          border: "0px !important",
                          borderColor: "none !important",
                        },

                      backgroundColor: "white",
                      padding: "0px !Important",
                      //   width: "100%",
                      fontSize: "10px !important",
                      borderWidth: "0px !important",
                      border: "0px !important",
                    }}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "DD/MM/YYYY",
                    }}
                  />
                )}
              />
              {props.info && <Tooltip info={props.info} />}
            </div>
            {props.error ? (
              <label className={classes.ErrorMsg}>{props.error}</label>
            ) : null}
          </LocalizationProvider>
        </Grid>
      </Grid>
      {/* </Grid> */}
    </Fragment>
  );
}

export default DateSelector;
