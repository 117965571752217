import React, { Fragment, useEffect, useState } from "react";
import DataTable from "../../../Layout/DataTable/DataTable";
import PageHeader from "../../../Layout/PageHeader/PageHeader";
import Button from "../../../UI/Button/Button";
import { Icon } from "@iconify/react";
import { useNavigate, useLocation } from "react-router-dom";

// Import these files for breadcrumbs
import Breadcrumb from "../../../Layout/Breadcrumb/Breadcrumb";

import CautionAlert from "../../../utils/CautionAlert";
import axios from "axios";
import backendUrl from "../../../utils/backendUrl";
import LoaderWithoutBackdrop from "../../../UI/Loader/LoaderWithoutBackdrop";
import Loader from "../../../UI/Loader/Loader";
import { checkPrivilage } from "../../../utils/checkPrivilage";
import columns from "../../../Resources/FeeColumns";
import Theme from "../../../Theme/Theme";
import { DropdownButton } from "react-bootstrap";
import ActionButton from "../../../UI/ActionButton/ActionButtonBox";

const MonthlyMBS = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const [switchItem, setSwitchItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoader(true);
    await axios({
      method: "GET",
      url: `${backendUrl}/api/monthly_mbs_files`,
      withCredentials: true,
    })
      .then((response) => {
        if (response.data?.data) {
          var dt = response.data?.data;
          dt = dt.map((item) => {
            return {
              ...item,
              action: (
                <Fragment>
                  <style type="text/css">
                    {`
                          .ActionButton button {
                            background-color:${Theme.palette.primaryColor.backgroundColor};
                          }
                          .ActionButton button:hover {
                            background-color:${Theme.palette.secondaryColor.backgroundColor};
                          }
                          .ActionButton button:focus {
                            background-color:${Theme.palette.secondaryColor.backgroundColor};
                          }
                      `}
                  </style>
                  <DropdownButton
                    align="end"
                    title="Actions"
                    id="dropdown-menu-align-start"
                    className="ActionButton"
                  >
                    <ActionButton
                      content="Edit"
                      onClickAction={() =>
                        navigate(`/app/edit/monthly-file/${item.id}`, {
                          state: { data: item },
                        })
                      }
                      icon="ep:edit"
                      eventKey="1"
                    />
                    <ActionButton
                      content="Delete"
                      onClickAction={() => setDeleteItem(item.id)}
                      icon="ant-design:delete-outlined"
                      eventKey="1"
                    />
                  </DropdownButton>
                </Fragment>
              ),
            };
          });

          setData(dt);
          setLoader(false);
        } else {
          setApiError("Something went wrong. Please try again");
          setLoader(false);
        }
      })
      .catch((e) => {
        
        setApiError("Something went wrong. Please try again");
        setLoader(false);
      });
  };

  //   const handleDelete = async (id) => {
  //     setDeleteLoader(true);
  //     await axios({
  //       method: "DELETE",
  //       withCredentials: true,
  //       url: `${backendUrl}/${getSite}/api/appointment_types/${id}`,
  //     })
  //       .then((response) => {
  //         setOpen(false);
  //         successHandler(response.data.message);
  //         getData();
  //         setDeleteLoader(false);
  //       })
  //       .catch(function (error) {
  //         setOpen(false);
  //         if (error.response) {
  //           setDeleteLoader(false);
  //           errorHandler("Something went wrong. Please try again");
  //         } else {
  //           setDeleteLoader(false);
  //           errorHandler("Something went wrong. Please try again");
  //         }
  //       });
  //   };

  //   const deleteConfirm = () => {
  //     handleDelete(passIdDelete.id);
  //   };

  //   const handleClose = (value) => {
  //     setOpen(false);
  //   };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/fee-management",
      iconName: "bi:currency-dollar",
      name: "Fee Management",
    },
    {
      link: "/app/monthly/files",
      iconName: "ph:files-bold",
      name: "Monthly Files",
    },
  ];

  const retry = () => {
    setError(null);
    setTimeout(() => {
      loadData();
    }, 100);
  };

  const handleDelete = async (id) => {
    setDeleteItem(null);
    setLoader(true);
    await axios({
      method: "DELETE",
      withCredentials: true,
      url: `${backendUrl}/api/monthly_mbs_files/${id}`,
    })
      .then((response) => {
        setLoader(false);
        setSuccess("Record has been successfully deleted");
        loadData();
      })
      .catch(function (error) {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };

  return (
    <Fragment>
      <Loader open={loader} />

      {/* <CautionAlert
        severity="error"
        open={open}
        content="Are you sure you want to delete this item?"
        onCloseActionB={deleteConfirm}
        onCloseActionA={handleClose}
        okText="Delete"
        cancelText="Cancel"
      />
       */}
      <CautionAlert
        severity="error"
        open={deleteItem}
        content="Are you sure you want to delete this record?"
        onCloseActionB={() => handleDelete(deleteItem)}
        onCloseActionA={() => setDeleteItem(null)}
        okText="Delete"
        cancelText="Cancel"
      />
      <CautionAlert
        severity="error"
        open={apiError}
        content={apiError}
        onCloseActionB={() => setApiError(null)}
        okText="Ok"
        oneButton={true}
      />
      <CautionAlert
        severity="success"
        success={true}
        open={success}
        content={success}
        onCloseActionB={() => setSuccess(null)}
        okText="Ok"
        oneButton={true}
      />
      {location.state?.showAlert && (
        <CautionAlert
          severity="success"
          success={true}
          open={location.state.showAlert}
          content={location.state.showAlert}
          onCloseActionB={() =>
            navigate("/app/monthly/files", location.state.showAlert, {})
          }
          okText="Ok"
          oneButton={true}
        />
      )}

      <Breadcrumb options={breadCrumbData} />

      {showTable ? (
        <>
          <PageHeader
            left={"Monthly Files"}
            right={
              <Button
                // disabled={openAddUser === true ? true : false}
                onClickAction={() => navigate("/app/add/monthly-file")}
                text="Add Monthly File"
                startIcon={
                  <Icon style={{ fontSize: "14px" }} icon="akar-icons:plus" />
                }
              ></Button>
            }
          />

          {data && (
            <DataTable data={data} columns={columns.monthlyMBSTableColumns} />
          )}
        </>
      ) : null}
    </Fragment>
  );
};

export default MonthlyMBS;
