import React, { Fragment, useEffect, useState } from "react";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Loader from "../../UI/Loader/Loader";
import TabLists from "../Dashboard/TabLists";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import DataTable from "../../Layout/DataTable/DataTable";
import columns from "../../Resources/MBSFeesColumn";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import CustomDataTable from "../../Layout/DataTable/CustomDataTable";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import Theme from "../../Theme/Theme";
import { DropdownButton } from "react-bootstrap";
import ActionButton from "../../UI/ActionButton/ActionButtonBox";
import storageService from "../../utils/localStorageHelpers";
import Button from "../../UI/Button/Button";
import { Alert, Backdrop, Grid } from "@mui/material";
import AmaPopup from "./AmaPopup";
import CautionAlert from "../../utils/CautionAlert";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { checkPrivilage } from "../../utils/checkPrivilage";
import PrivateFeePopup from "./PrivateFeePopup";
import moment from "moment";

export default function Fees() {
  const navigate = useNavigate();
  const location = useLocation();

  const canCreate = checkPrivilage("PRIVATE_FEES", "CREATE");
  const canUpdate = checkPrivilage("PRIVATE_FEES", "UPDATE");
  const canDelete = checkPrivilage("PRIVATE_FEES", "DELETE");
  const canReadMBS = checkPrivilage("MBS_ITEM", "READ");

  useEffect(() => {
    if (location.state && location.state.showAlert) {
      setTimeout(() => {
        navigate("/app/fee-management", location.state.showAlert, {});
      }, 2000);
    }

    if (location?.state?.tab) {
      setTabIndex(location?.state?.tab);
    }
  }, []);

  const [loader, setLoader] = useState(null);

  const [tabIndex, setTabIndex] = useState(1);
  const [data, setData] = useState([]);
  const [monthlyMBS, setMonthlyMBS] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [popupError, setPopupError] = useState(false);

  const [MBSPopup, setMBSPopup] = useState(false);
  const [DVAPopup, setDVAPopup] = useState(false);
  const [AlliedPopup, setAlliedPopup] = useState(false);

  const [dvaAlliedFees, setDvaAlliedFees] = useState([]);
  const [dvaAlliedFeePageData, setDvaAlliedFeePageData] = useState(1);
  const [dvaAlliedFeesCount, setDvaAlliedFeesCount] = useState(0);

  const [dvaFees, setDvaFees] = useState([]);
  const [dvaFeePageData, setDvaFeePageData] = useState(1);
  const [dvaFeesCount, setDvaFeesCount] = useState(0);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [passIdDelete, setPassIdDelete] = useState(null);

  const perPage = 10;
  const siteArr = storageService.loadSite();

  useEffect(() => {
    canReadMBS && showAlertData();
    onPageChange(page, perPage);
    onDvaAlliedPageChange(dvaAlliedFeePageData, perPage);
    onDvaFeesChange(dvaFeePageData, perPage);
  }, []);

  const onPageChange = (page, sizePerPage) => {
    setPage(page);
    canReadMBS && loadData(page);
  };

  const onDvaAlliedPageChange = (page, sizePerPage) => {
    setDvaAlliedFeePageData(page);
    canReadMBS && loadDvaAlliedFee(page);
  };

  const onDvaFeesChange = (page, sizePerPage) => {
    setDvaFeePageData(page);
    canReadMBS && loadDvaFees(page);
  };

  const tabHandler = (e, newValue) => {
    setTabIndex(newValue);
  };

  const errorHandler = (msg, autoHidden) => {
    setError(msg);
  };

  const successHandler = (msg) => {
    setSuccess(msg);
  };

  const loadData = async (pg) => {
    setError(null);
    setLoader(true);
    await axios({
      method: "GET",
      url: `${backendUrl}/api/mbs_item?page=${
        pg ? Number(pg) - 1 : null
      }&perPage=${pg ? perPage : null}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response.data && response.data.data) {
          let result = response.data.data.map((item) => {
            return {
              ...item,
              action: (
                <Fragment>
                  <style type="text/css">
                    {`
                        .ActionButton button {
                          background-color:${Theme.palette.primaryColor.backgroundColor};
                        }
                        .ActionButton button:hover {
                          background-color:${Theme.palette.secondaryColor.backgroundColor};
                        }
                        .ActionButton button:focus {
                          background-color:${Theme.palette.secondaryColor.backgroundColor};
                        }
                    `}
                  </style>
                  <DropdownButton
                    align="end"
                    title="Actions"
                    id="dropdown-menu-align-start"
                    className="ActionButton"
                  >
                    <ActionButton
                      content="View"
                      onClickAction={(e) =>
                        navigate(`/app/mbs-fees/view/${item.id}`, {
                          state: { data: item },
                        })
                      }
                      icon="carbon:view"
                      eventKey="1"
                    />
                  </DropdownButton>
                </Fragment>
              ),
            };
          });
          result = result.sort(compare);
          setData(result);
          setCount(response.data && response.data.count);
          setLoader(false);
        }
      })
      .catch((e) => {
        setApiError("Something went wrong. Please try again", false);
        setLoader(false);
      });
  };

  const loadDvaAlliedFee = async (pg) => {
    setError();
    setLoader(true);
    await axios({
      method: "GET",
      url: `${backendUrl}/api/dva_allied_items?page=${
        pg ? Number(pg) - 1 : null
      }&perPage=${pg ? perPage : null}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response.data && response.data.data) {
          let result = response.data.data.map((item) => {
            return {
              ...item,
              action: (
                <Fragment>
                  <style type="text/css">
                    {`
                        .ActionButton button {
                          background-color:${Theme.palette.primaryColor.backgroundColor};
                        }
                        .ActionButton button:hover {
                          background-color:${Theme.palette.secondaryColor.backgroundColor};
                        }
                        .ActionButton button:focus {
                          background-color:${Theme.palette.secondaryColor.backgroundColor};
                        }
                    `}
                  </style>
                  <DropdownButton
                    align="end"
                    title="Actions"
                    id="dropdown-menu-align-start"
                    className="ActionButton"
                  >
                    <ActionButton
                      content="View"
                      onClickAction={(e) =>
                        navigate(`/app/dva-allied-fee/view/${item.id}`, {
                          state: { data: item },
                        })
                      }
                      icon="carbon:view"
                      eventKey="1"
                    />
                  </DropdownButton>
                </Fragment>
              ),
            };
          });
          result = result.sort(compare);
          setDvaAlliedFees(result);
          setDvaAlliedFeesCount(response.data && response.data.count);
          setLoader(false);
        } 
      })
      .catch((e) => {
        setApiError("Something went wrong. Please try again");
        setLoader(false);
      });
  };

  const loadDvaFees = async (pg) => {
    setError();
    setLoader(true);
    await axios({
      method: "GET",
      url: `${backendUrl}/api/dva_fees?page=${
        pg ? Number(pg) - 1 : null
      }&perPage=${pg ? perPage : null}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response.data && response.data.data) {
          let result = response.data.data.map((item) => {
            return {
              ...item,
              action: (
                <Fragment>
                  <style type="text/css">
                    {`
                        .ActionButton button {
                          background-color:${Theme.palette.primaryColor.backgroundColor};
                        }
                        .ActionButton button:hover {
                          background-color:${Theme.palette.secondaryColor.backgroundColor};
                        }
                        .ActionButton button:focus {
                          background-color:${Theme.palette.secondaryColor.backgroundColor};
                        }
                    `}
                  </style>
                  <DropdownButton
                    align="end"
                    title="Actions"
                    id="dropdown-menu-align-start"
                    className="ActionButton"
                  >
                    <ActionButton
                      content="View"
                      onClickAction={(e) =>
                        navigate(`/app/dva-fees/view/${item.id}`, {
                          state: { data: item },
                        })
                      }
                      icon="carbon:view"
                      eventKey="1"
                    />
                  </DropdownButton>
                </Fragment>
              ),
            };
          });
          result = result.sort(compare);
          setDvaFees(result);
          setDvaFeesCount(response.data && response.data.count);
          setLoader(false);
        } else {
          errorHandler("No record found", true);
        }
      })
      .catch((e) => {
        setApiError("Something went wrong. Please try again");
        setLoader(false);
      });
  };

  const showAlertData = async (pg) => {
    setError();
    setLoader(true);
    await axios({
      method: "GET",
      url: `${backendUrl}/api/monthly_mbs_files?show_alert=true`,
      withCredentials: true,
    })
      .then((response) => {
        let dt = response.data.data;
        setMonthlyMBS(dt);
      })
      .catch((e) => {
        
        setLoader(false);
      });
  };

  function compare(a, b) {
    if (a.created_at > b.created_at) {
      return -1;
    }
    if (a.created_at < b.created_at) {
      return 1;
    }
    return 0;
  }

  let tabData = [
    {
      label: "MBS Fees",
      value: 1,
    },
    {
      label: "DVA Fees",
      value: 2,
    },
    {
      label: "DVA Fees [Allied Health]",
      value: 3,
    },
  ];

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "#",
      iconName: "bi:currency-dollar",
      name: "Fee Management",
    },
  ];

  const retry = () => {
    setApiError(null);
    setTimeout(() => {
      onPageChange(page, perPage);
      onDvaAlliedPageChange(dvaAlliedFeePageData, perPage);
      onDvaFeesChange(dvaFeePageData, perPage);
    }, 100);
  };

  const handleClose = () => {
    setDeleteOpen(false);
  };

  const uploadMBS = () => {
    setMBSPopup(true);
  };

  const uploadDVA = () => {
    setDVAPopup(true);
  };

  const uploadAllied = () => {
    setAlliedPopup(true);
  };

  const upoadMBSDone = () => {
    setSuccess("MBS Fee has been successfully added");
    loadData(page);
  };

  const uploadDVADone = () => {
    setSuccess("DVA Fee has been successfully added");
    loadDvaFees(page);
  };

  const uploadAlliedDone = () => {
    setSuccess("DVA Fees [Allied Health] has been successfully added");
    loadDvaAlliedFee(page);
  };

  const handleMBSPopupClose = () => {
    setMBSPopup(false);
  };
  const handleDVAPopupClose = () => {
    setDVAPopup(false);
  };
  const handleAlliedPopupClose = () => {
    setAlliedPopup(false);
  };

  return (
    <Fragment>
      <Loader open={loader} />
      <Breadcrumb options={breadCrumbData} />

      <CautionAlert
        severity="error"
        open={deleteOpen}
        content="Are you sure you want to delete this item?"
        // onCloseActionB={deleteConfirm}
        onCloseActionA={handleClose}
        okText="Delete"
        cancelText="Cancel"
      />

      {MBSPopup && (
        <AmaPopup
          ama={true}
          title="Upload MBS Fees"
          handlePopupOpen={uploadMBS}
          PopUpOpen={MBSPopup}
          success={upoadMBSDone}
          handlePopupClose={handleMBSPopupClose}
          url={`${backendUrl}/api/mbs_item`}
        />
      )}

      {DVAPopup && (
        <AmaPopup
          title="Upload DVA Fees"
          handlePopupOpen={uploadDVA}
          PopUpOpen={DVAPopup}
          success={uploadDVADone}
          handlePopupClose={handleDVAPopupClose}
          url={`${backendUrl}/api/dva_fees`}
        />
      )}
      {AlliedPopup && (
        <AmaPopup
          title="Upload DVA Fees [Allied Health]"
          handlePopupOpen={uploadAllied}
          PopUpOpen={AlliedPopup}
          success={uploadAlliedDone}
          handlePopupClose={handleAlliedPopupClose}
          url={`${backendUrl}/api/dva_allied_items`}
        />
      )}

      <CautionAlert
        severity="success"
        success={true}
        open={success}
        content={success}
        onCloseActionB={() => setSuccess(null)}
        okText="Ok"
        oneButton={true}
      />

      {location.state && location.state.showAlert ? (
        <CautionAlert
          severity="success"
          success={true}
          open={location.state && location.state.showAlert}
          content={location.state && location.state.showAlert}
          onCloseActionB={() =>
            navigate("/app/fee-management", location.state.showAlert, {})
          }
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      {apiError ? (
        <CautionAlert
          severity="error"
          open={apiError}
          content={apiError}
          onCloseActionA={() => setApiError()}
          onCloseActionB={retry}
          okText="Retry"
          cancelText="Dismiss"
        />
      ) : null}

      <CautionAlert
        severity="error"
        open={error}
        content={error}
        onCloseActionB={() => setError(null)}
        okText="Ok"
        oneButton={true}
      />

      <Fragment>
        {monthlyMBS &&
          monthlyMBS.map((e) => {
            return (
              <Fragment>
                <TabContext
                  className="SubSectionRow"
                  style={{
                    height: "100%",
                    margin: "0px !important",
                    padding: "0px !important",
                  }}
                  value={tabIndex}
                >
                  {e.fee_type === "MBS Fees" ? (
                    <TabPanel sx={{ padding: "0px !important" }} value={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                          <Alert severity="info">
                            New MBS File is released on{" "}
                            <strong>
                              {moment(e.release_date).format("MMM Do YYYY")},{" "}
                            </strong>
                            which will be effective from{" "}
                            <strong>
                              {moment(e.effective_date).format("MMM Do YYYY")}.{" "}
                            </strong>
                            The below table will be updated with new data on the
                            effective date.
                          </Alert>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  ) : e.fee_type === "DVA Fees" ? (
                    <TabPanel sx={{ padding: "0px !important" }} value={2}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                          <Alert severity="info">
                            New DVA Fees File is released on{" "}
                            <strong>
                              {moment(e.release_date).format("MMM Do YYYY")},{" "}
                            </strong>
                            which will be effective from{" "}
                            <strong>
                              {moment(e.effective_date).format("MMM Do YYYY")}.{" "}
                            </strong>
                            The below table will be updated with new data on the
                            effective date.
                          </Alert>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  ) : e.fee_type === "DVA Fees [Allied Health]" ? (
                    <TabPanel sx={{ padding: "0px !important" }} value={3}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                          <Alert severity="info">
                            New DVA Fees [Allied Health] File is released on{" "}
                            <strong>
                              {moment(e.release_date).format("MMM Do YYYY")},{" "}
                            </strong>
                            which will be effective from{" "}
                            <strong>
                              {moment(e.effective_date).format("MMM Do YYYY")}.{" "}
                            </strong>
                            The below table will be updated with new data on the
                            effective date.
                          </Alert>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  ) : null}
                </TabContext>
              </Fragment>
            );
          })}

        <PageHeader
          left={"Fee Management"}
          right={
            <TabContext
              className="SubSectionRow"
              style={{
                height: "100%",
                margin: "0px !important",
                padding: "0px !important",
              }}
              value={tabIndex}
            >
              <TabPanel sx={{ padding: "0px !important" }} value={1}>
                {canCreate && (
                  <Button
                    fontSize="14px"
                    onClickAction={() => uploadMBS(true)}
                    text="Upload MBS Fee"
                    startIcon={
                      <Icon
                        style={{ fontSize: "14px" }}
                        icon="akar-icons:plus"
                      />
                    }
                  ></Button>
                )}
              </TabPanel>

              <TabPanel sx={{ padding: "0px !important" }} value={2}>
                {canCreate && (
                  <Button
                    fontSize="14px"
                    onClickAction={() => uploadDVA(true)}
                    text="Upload DVA Fees"
                    startIcon={
                      <Icon
                        style={{ fontSize: "14px" }}
                        icon="akar-icons:plus"
                      />
                    }
                  ></Button>
                )}
              </TabPanel>

              <TabPanel sx={{ padding: "0px !important" }} value={3}>
                {canCreate && (
                  <Button
                    fontSize="14px"
                    onClickAction={() => uploadAllied(true)}
                    text="Upload DVA Fees [Allied Health]"
                    startIcon={
                      <Icon
                        style={{ fontSize: "14px" }}
                        icon="akar-icons:plus"
                      />
                    }
                  ></Button>
                )}
              </TabPanel>
            </TabContext>
          }
        />

        <TabLists
          data={tabData}
          // uploadAMA={uploadAMA}
          // uploadWorkcover={uploadWorkcover}
          // uploadPrivateFee={uploadPrivateFee}
          value={tabIndex}
          handleChange={tabHandler}
        />

        <TabContext
          className="SubSectionRow"
          style={{
            height: "100%",
            margin: "0px !important",
            padding: "0px !important",
          }}
          value={tabIndex}
        >
          <TabPanel sx={{ padding: "0px !important" }} value={1}>
            {data && (
              <CustomDataTable
                data={data}
                length={count}
                page={page}
                perPage={perPage}
                columns={columns.mbsFeesColumn}
                onPageChange={onPageChange}
              />
            )}
          </TabPanel>

          <TabPanel sx={{ padding: "0px !important" }} value={2}>
            {data && (
              <CustomDataTable
                data={dvaFees}
                length={dvaFeesCount}
                page={dvaFeePageData}
                perPage={perPage}
                columns={columns.dvaFeesTable}
                onPageChange={onDvaFeesChange}
              />
            )}
          </TabPanel>
          <TabPanel sx={{ padding: "0px !important" }} value={3}>
            {data && (
              <CustomDataTable
                data={dvaAlliedFees}
                length={dvaAlliedFeesCount}
                page={dvaAlliedFeePageData}
                perPage={perPage}
                columns={columns.dvaAlliedFeesTable}
                onPageChange={onDvaAlliedPageChange}
              />
            )}
          </TabPanel>
        </TabContext>
      </Fragment>
    </Fragment>
  );
}
