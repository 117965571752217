import moment from "moment";

const timestampHandler = (datetime, format = "DD/MM/YYYY hh:mmA") => {
  // create a Moment.js object representing the UTC timestamp
  const utcMoment = moment.utc(datetime);

  // convert to local system time
  const localMoment = utcMoment.local();

  // format the result as a string
  const localTimestamp = localMoment.format(format);

  return localTimestamp;
};

export default timestampHandler;
