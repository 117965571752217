import React, { Fragment, useRef } from "react";
import { Grid, ListItemText, Typography } from "@mui/material";
import classes from "./Upload.module.css";
import Button from "../../Button/Button";
import { Icon } from "@iconify/react";

export default function UploadButton(props) {
  const fileRef = useRef();
  function handleClear() {
    fileRef.current.value = "";
    props.clear();
  }
  return (
    <Fragment>
      <Grid container spacing={2}>
        {props.noLabel ? (
          ""
        ) : (
          <Grid
            item
            sx={props.sxLabel || ""}
            xs={props.xsLabel || 4}
            className={`LabelBox`}
          >
            <label>
              {props.label}
              {props.required ? <sup>*</sup> : null}
            </label>
          </Grid>
        )}

        <Grid item sx={props.sx || ""} xs={props.xsInside || 8}>
          <div className={classes.FileInput}>
            <input
              // multiple="multiple"
              className={`InputPrimary BackWhite ${
                props.error ? "ErrorBorder" : null
              } }`}
              accept={
                // "image/*,.pdf"
                props.inbound
                  ? "image/*,.pdf"
                  : props.ama
                  ? "image/*,.xlsx"
                  : props.logo
                  ? ".jpg, .jpeg, .png"
                  : "image/*"
              }
              id="faceImage"
              type="file"
              onChange={props.onChangeAction}
              onBlur={props.onBlurAction}
              name={props.name}
              style={{ backgroundColor: "white !important" }}
              ref={fileRef}
            />
            {fileRef?.current?.value && (
              <Icon
                className={classes.IconButton}
                onClick={handleClear}
                icon="ci:close-big"
              />
            )}
          </div>
          {props.error ? (
            <label className={classes.ErrorMsg}>{props.error}</label>
          ) : null}

          <div>
            <Typography variant="caption" display="block" gutterBottom>
              {props.inbound ? (
                <ul>
                  <li>Allowed format : .pdf, .jpeg, .png, .svg</li>
                  <li>Maximum upload size: 5 MB.</li>
                </ul>
              ) : props.ama ? (
                <ul>
                  <li>Allowed format : .xls, .xlsx</li>
                </ul>
              ) : (
                <ul>
                  <li>Maximum upload image size: 1 MB.</li>
                  <li>Suggested image dimensions: 260 by 150 pixels</li>
                  <li>Allowed format : JPEG, PNG</li>
                </ul>
              )}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
}
