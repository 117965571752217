import React, { Fragment, useState } from "react";
import { Grid } from "@mui/material";
import classes from "./TextBox.module.css";

function TextBox(props) {
  const checkNumberValidation = (e) => {
    return props.numberOnly
      ? ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
      : "";
  };
  return (
    <Fragment>
      {/* <Grid
        item
        xs={props.xs || 12}
        sm={props.sm || 12}
        md={props.width ? props.width : props.md || 6}
        sx={6}
        spacing={2}
        className={classes.FormGroup}
      > */}

      <Grid container spacing={2}>
        {props.noLabel ? (
          ""
        ) : (
          <Grid item xs={props.xsLabel || 4} className={`LabelBox`}>
            <label>
              {props.label}
              {props.required ? <sup>*</sup> : null}
            </label>
          </Grid>
        )}

        <Grid
          item
          sx={
            props.markAvail
              ? { paddingLeft: "9px !important" }
              : props.sxLabel || ""
          }
          xs={props.xsInside || 8}
        >
          <input
            className={`InputPrimary ${props.error ? "ErrorBorder" : null} ${
              props.disabled === true ? classes.Disabled : null
            }`}
            value={props.value}
            onChange={props.onChangeAction}
            onBlur={props.onBlurAction}
            onKeyDown={checkNumberValidation}
            // placeholder={props.placeholder}
            type={props.type}
            disabled={props.disabled}
            name={props.name}
            ref={props.textBoxRef}
          />
          {/* <label>Title<sup>*</sup></label> */}
          {props.error ? (
            <label className={classes.ErrorMsg}>{props.error}</label>
          ) : null}
        </Grid>
      </Grid>

      {/* </Grid> */}
    </Fragment>
  );
}

export default TextBox;
