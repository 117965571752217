import { Icon } from "@iconify/react";
import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Button from "../../UI/Button/Button";
import Tile from "../../UI/Tile/Tile";
import { checkAnyPrivilage } from "../../utils/checkPrivilage";

export default function MimsManagement() {
  const location = useLocation();
  const navigate = useNavigate();

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "#",
      iconName: "fa6-solid:capsules",
      name: "MIMS Management",
    },
  ];

  return (
    <Fragment>
      <Breadcrumb options={breadCrumbData} />

      <Col lg={12}>
        <Row>
          <Col lg={2} className="mb-4">
            <Tile
              small={true}
              icon="mdi:users-check"
              to="/app/mims/users"
              text="Integrated MIMS Users"
            />
          </Col>
          <Col lg={2} className="mb-4">
            <Tile
              small={true}
              icon="mdi:users-minus"
              to="/app/mims/other/users"
              text="Other MIMS Users"
            />
          </Col>
          <Col lg={2} className="mb-4">
            <Tile
              small={true}
              icon="teenyicons:tick-circle-solid"
              to="/app/mims/subscriptions"
              text="MIMS Subscriptions"
            />
          </Col>
          <Col lg={2} className="mb-4">
            <Tile
              small={true}
              icon="quill:paper"
              to="/app/prescription/template"
              text="Prescription Templates"
            />
          </Col>
        </Row>
      </Col>
    </Fragment>
  );
}
