import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  privilages: null,
  checkDeviceExpiry: false,
  callMimsEula: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    savePrivilages: (state, action) => {
      state.privilages = action.payload;
    },
    removePrivilages: (state) => {
      state.privilages = null;
    },
    setCheckDeviceExpiry: (state, action) => {
      state.checkDeviceExpiry = action.payload;
    },
    setCallMimsEula: (state, action) => {
      state.callMimsEula = action.payload;
    },
  },
});

export const {
  setCheckDeviceExpiry,
  savePrivilages,
  removePrivilages,
  setCallMimsEula,
} = authSlice.actions;

export default authSlice.reducer;
