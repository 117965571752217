import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

export default function LoaderWithoutBackdrop(props) {
    return (
        <Backdrop
        sx={{ backgroundColor: '#00000000', visibility:"hidden" }}
        open={props.open}
      >
        <CircularProgress  open={props.open} sx={{
            right: "50%",
            top: "50%",
            position: "absolute",
            visibility:'initial'
          }} />
          </Backdrop>
      )
}
