import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { ThemeProvider } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ListBox from "../../../UI/FormGroup/ListBox/ListBox";
import MuiTable from "../../../UI/MuiTable/MuiTable";
import Button from "../../../UI/Button/Button";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    maxWidth: "80%",
    minWidth: "80%",
    borderRadius: "0px",
    maxHeight: "90vh",
  },

  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
    borderRadius: 0,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    borderRadius: 0,
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        textAlign: "center",
        backgroundColor: "#615586",
        color: "white",
      }}
      {...other}
    >
      {children}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const EulaPopup = ({ data, open, closeHandler, selectApprovalNumber }) => {
  let htmlData = data && data.eula?.replace(/position:absolute;/g, "");
  return (
    <ThemeProvider>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          style={{ textAlign: "left" }}
        >
          End User Licence Agreement
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div
            style={{ overflow: "hidden" }}
            dangerouslySetInnerHTML={{
              __html: htmlData,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button text="I Agree" onMouseDownAction={closeHandler} />
        </DialogActions>
      </BootstrapDialog>
    </ThemeProvider>
  );
};

export default EulaPopup;
