import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  claimSearch: {},
};

const searchParamsSlice = createSlice({
  name: "searchParams",
  initialState: INITIAL_STATE,
  reducers: {
    setClaimSearch: (state, action) => {
      state.claimSearch = action.payload;
    },
  },
});

export const { setClaimSearch } = searchParamsSlice.actions;

export default searchParamsSlice.reducer;
