import React, { useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, createTheme, ThemeProvider } from "@mui/system";
import classes from "./Dashboard.module.css";
import Button from "../../UI/Button/Button";
import { Icon } from "@iconify/react";
import storageService from "../../../components/utils/localStorageHelpers.js";

const theme = createTheme({
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: "red",
      },
    },
    MuiTab: {
      root: {
        "&:hover": {
          backgroundColor: "white",
        },
      },
      selected: {
        backgroundColor: "red",
        "&:hover": {
          backgroundColor: "red",
        },
      },
    },
  },
});

export default function TabLists(props) {
  return (
    <Box backgroundColor="#ECEBEE">
      <TabContext
        className="SubSectionRow"
        style={{
          height: "100%",
          maxHeight: "50px",
          margin: "0px !important",
        }}
        value={props.value}
      >
        <TabList
          sx={{
            "& .MuiTab-root.Mui-selected": {
              backgroundColor: "#F7F7F7 !important",
              color: "black !important",
              borderLeft:props.value === 1 ? "1px solid #e5e5e5" : ""
            },
            "& .MuiTab-root": {
              textTransform: "capitalize !important",
            },
          }}
          onChange={props.handleChange}
          aria-label="lab API tabs example"
          indicatorColor="#ecebee00"
        >
          {props?.data?.map((e) => {
                return (
                  <Tab
                    className={`subSection d-flex justify-content-center align-items-center`}
                    label={e.label}
                    value={e.value}
                  />
                )
              })}
        </TabList>

        {/* <TabPanel value="1">Item One</TabPanel>
        <TabPanel value="2">Item Two</TabPanel>
        <TabPanel value="3">Item Three</TabPanel> */}
      </TabContext>
    </Box>
  );
}
