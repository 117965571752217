import { Icon } from "@iconify/react";
import { Typography, Tooltip, Link, Button } from "@mui/material";
import moment from "moment";
import classes from "./Resource.module.css";
import CopyToClipboard from "react-copy-to-clipboard";

const handleCopyLink = async (link) => {
  if (link) {
    await navigator.clipboard
      .writeText(link)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((error) => {
        alert("Failed to copy link: ", error);
      });
  }
};

const monthlyMBSTableColumns = [
  {
    text: "Url",
    dataField: "url",
    formatter: (value) => {
      return (
        <Tooltip className="Add" title={value}>
          <Typography className={classes.Description}>
            <CopyToClipboard text={value}>
              <Button style={{ textTransform: "none" }} href="#text-buttons">
                <Icon icon="fa-solid:copy" style={{ marginRight: "5px" }} />{" "}
                Copy Url
              </Button>
            </CopyToClipboard>
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    text: "Release Date",
    dataField: "release_date",
    formatter: (value) => {
      return moment(value).format("DD/MM/YYYY");
    },
  },
  {
    text: "Effective Date",
    dataField: "effective_date",
    formatter: (value) => {
      return moment(value).format("DD/MM/YYYY");
    },
  },
  {
    text: "Description",
    dataField: "description",
    formatter: (value) => {
      return (
        <Tooltip className="Add" title={value}>
          <Typography className={classes.Description}>
            {value?.length > 50
              ? value?.substring(0, Math.min(50, value?.length)) + "..."
              : value}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    text: "Fee Type",
    dataField: "fee_type",
  },
  {
    text: "Show Alert",
    dataField: "show_alert",
  },
  {
    text: "Actions",
    dataField: "action",
  },
];

export default {
  monthlyMBSTableColumns,
};
