import React, { Fragment, useEffect, useState } from "react";
import DataTable from "../../Layout/DataTable/DataTable";
import ActionButton from "../../UI/ActionButton/ActionButtonBox";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Button from "../../UI/Button/Button";
import { Icon } from "@iconify/react";
import AddUser from "../User/AddUser/AddUser";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Import these files for breadcrumbs
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import { DropdownButton } from "react-bootstrap";
import LoaderWithoutBackdrop from "../../UI/Loader/LoaderWithoutBackdrop";
import columns from "../../Resources/userColumns";
import storageService from "../../utils/localStorageHelpers";
import { Alert, Backdrop } from "@mui/material";
import CautionAlert from "../../utils/CautionAlert";
import Theme from "../../Theme/Theme";
import moment from "moment";
import InfoAlert from "../../utils/InfoAlert";
import { checkPrivilage } from "../../utils/checkPrivilage";

const UserManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const canCreate = checkPrivilage("SITE_USER", "CREATE");
  const canUpdate = checkPrivilage("SITE_USER", "UPDATE");
  const canDelete = checkPrivilage("SITE_USER", "DELETE");

  let siteDt = storageService.loadSite();
  siteDt = siteDt[0];

  const [data, setData] = useState([]);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [viewUser, setViewUser] = useState("");
  const [editUser, setEditUser] = useState("");
  const [showTable, setShowTable] = useState(true);
  const [loader, setLoader] = useState(false);
  const [site, setSite] = useState(null);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [apiError, setApiError] = useState("");
  const [resend, setResend] = useState(null);
  const [resendOpen, setResendOpen] = useState(false);
  const [revoke, setRevoke] = useState(null);
  const [editFrom, setEditFrom] = useState(null);
  const [deactivate, setDeactivate] = useState(null);
  const [deactivateAlert, setDeactivateAlert] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [fullTimeSub, setFullTimeSub] = useState(null);
  const [partTimeSub, setPartTimeSub] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [partTimeSubError, setPartTimeSubError] = useState(null);
  const [showErrorBtn, setShowErrorBtn] = useState(true);

  const closeAddUserHandler = () => {
    setOpenAddUser(false);
  };

  useEffect(() => {
    getSite();
    getData();
    loadSubscription();
  }, []);

  const getSite = () => {
    const siteArr = storageService.loadSite();
    setSite(siteArr[0]);
  };

  const successHandler = (msg) => {
    setSuccess(msg);
    getData();
  };

  const errorHandler = (msg, autoHidden) => {
    setError(msg);
  };

  const loadSubscription = () => {
    return axios({
      medthod: "GET",
      url: `${backendUrl}/api/user_subscription`,
      params: {
        site_id: siteDt.site_id,
        status: "Active",
      },
      withCredentials: true,
    })
      .then((response) => {
        let dt = response?.data?.data;
        setSubscriptions(dt);
        let ft = dt?.filter(
          (sub) => sub.user_type === "fulltime" && sub.user_id === null
        );
        setFullTimeSub(ft);

        let pt = dt?.filter(
          (sub) => sub.user_type === "parttime" && sub.user_id === null
        );
        setPartTimeSub(pt);
        setLoader(false);
      })
      .catch((e) => {
        
        errorHandler("Something went wrong. Please try again", false);
        setLoader(false);
      });
  };

  const getData = async () => {
    let arr = [];
    setLoader(true);
    const siteArr = storageService.loadSite();
    siteArr &&
      (await axios({
        method: "GET",
        withCredentials: true,
        url: `${backendUrl}/api/users?site_id=${siteArr[0].site_id}`,
      })
        .then(async (response) => {
          let dt = response.data.data;

          let siteUsers;

          let siteDt = storageService.loadSite();

          await axios({
            method: "GET",
            url: `${backendUrl}/${siteDt[0].site_name}/api/site_user`,
            withCredentials: true,
          }).then((res) => {
            siteUsers = res.data.data;
          });

          return Promise.all(
            dt.map(async (item) => {
              item.role_name = item.role.name;

              const res = siteUsers.filter((user) => user.user_id === item.id);

              arr.push({
                ...item,
                ...res[0],
                action: (
                  <Fragment>
                    <style type="text/css">
                      {`
                  .ActionButton button {
                    background-color:${Theme.palette.primaryColor.backgroundColor};
                  }
                  .ActionButton button:hover {
                    background-color:${Theme.palette.secondaryColor.backgroundColor};
                  }
                  .ActionButton button:focus {
                    background-color:${Theme.palette.secondaryColor.backgroundColor};
                  }
              `}
                    </style>
                    <DropdownButton
                      align="end"
                      title="Actions"
                      id="dropdown-menu-align-start"
                      className="ActionButton"
                    >
                      <ActionButton
                        content="View"
                        onClickAction={(e) =>
                          loadViewUser(item.id, { ...item, ...res[0] })
                        }
                        icon="carbon:view"
                        eventKey="1"
                      />
                      {canUpdate && (
                        <ActionButton
                          content="Edit"
                          onClickAction={(e) => {
                            loadEditUser(item.id, { ...item, ...res[0] });
                            setShowTable(false);
                            closeAddUserHandler();
                          }}
                          icon="ep:edit"
                          eventKey="2"
                        />
                      )}
                      {canUpdate && item.status === "Active" ? (
                        <ActionButton
                          content={"Deactivate"}
                          onClickAction={(e) => deactivateValidation(res[0])}
                          icon="bytesize:reload"
                          eventKey="3"
                        />
                      ) : canUpdate && item.status === "Deactivated" ? (
                        <ActionButton
                          content={"Activate"}
                          onClickAction={(e) => {
                            setResend(item);
                            setResendOpen(true);
                          }}
                          icon="bytesize:reload"
                          eventKey="3"
                        />
                      ) : (
                        canUpdate && (
                          <Fragment>
                            <ActionButton
                              content={
                                item.status === "Revoked"
                                  ? "Send Activation Email"
                                  : "Resend Activation Email"
                              }
                              onClickAction={(e) => {
                                setResend(item);
                                setResendOpen(true);
                              }}
                              icon="bytesize:reload"
                              eventKey="3"
                            />
                            {item.status !== "Revoked" ? (
                              <ActionButton
                                content="Revoke Invitation"
                                onClickAction={(e) => {
                                  setRevoke(item);
                                }}
                                icon="charm:block"
                                eventKey="4"
                              />
                            ) : null}
                          </Fragment>
                        )
                      )}
                    </DropdownButton>
                  </Fragment>
                ),
              });
            })
          );
        })
        .then(() => {
          arr = arr.filter((user) => user?.role?.list_for_site);
          arr = arr.sort(compare);
          setData(arr);
          setLoader(false);
        })
        .catch((e) => {
          setApiError("Something went wrong. Please try again", false);
          setLoader(false);
        }));
  };

  function compare(a, b) {
    if (a.status < b.status) {
      return -1;
    }
    if (a.status > b.status) {
      return 1;
    }
    return 0;
  }

  const loadViewUser = async (id, user) => {
    navigate(`/app/user/view/${id}`, { state: { user: user } });
  };

  const loadEditUser = async (id, user) => {
    navigate(`/app/user/edit/${id}`, { state: { user: user } });
  };

  const resendActivationHandler = async () => {
    let activeSub = subscriptions.find(
      (sub) => sub.status === "Active" && sub.user_id === resend.id
    );

    if (activeSub) {
      resendFunction(true);
      return;
    }

    const role_id = resend?.role.id;

    const partSub = partTimeSub.filter((sub) => sub.role_id === role_id);
    const fullSub = fullTimeSub.filter((sub) => sub.role_id === role_id);

    if (resend) {
      if (resend.role.paid_role === true) {
        if (partSub?.length < 1 && fullSub?.length < 1) {
          setResendOpen(false);
          setSubscriptionError(
            "You required a new licence. Please contact the administrator"
          );
          return;
        }
      }

      if (resend.role?.paid_role === true) {
        if (resend.user_type === "parttime") {
          if (partSub.length < 1 && fullSub.length > 0) {
            setResendOpen(false);
            setPartTimeSubError(
              "You do not have a part time licence. Do you want to assign this part time user to a full time licence?"
            );
            return;
          }
        } else {
          if (fullSub.length < 1 && partSub.length > 0) {
            setResendOpen(false);
            setSubscriptionError(
              "You required a full time licence to activate this user. Please contact the administrator"
            );
            return;
          }
        }
      }

      return resendFunction();
    }
  };

  const resendFunction = async (subscribed = false) => {
    await axios({
      method: "POST",
      withCredentials: true,
      url: `${backendUrl}/api/resend/link/${resend.id}`,
      data: {
        site_id: siteDt.site_id,
        paid_role: resend.role.paid_role,
        user_type: resend.user_type,
        roleId: resend?.role?.id,
        subscribed,
      },
    })
      .then((response) => {
        successHandler(response.data.message);
        setResendOpen(false);
        setResend(null);
      })
      .catch(function (error) {
        setResendOpen(false);
        setResend(null);
        errorHandler("Something went wrong. Please try again", true);
      });
  };

  const closeResendAlert = () => {
    setResend(null);
  };
  const revokeUserHandler = async () => {
    if (revoke) {
      await axios({
        method: "PATCH",
        withCredentials: true,
        url: `${backendUrl}/api/user/deactivation/${revoke.id}`,
        data: {
          status: "Revoked",
          reset_token: null,
          expire_token: null,
        },
      })
        .then((response) => {
          successHandler("User revoked");
          setRevoke(null);
        })
        .catch(function (error) {
          if (error.response) {
            setError(error.response.data.message);
            setRevoke(null);
          } else {
            setError(error.message);
            setRevoke(null);
          }
        });
    }
  };

  const closeRevokeHandler = () => {
    setRevoke(null);
  };

  const deactivateValidation = async (user) => {
    setLoader(true);
    let siteDt = storageService.loadSite();
    await axios({
      method: "GET",
      withCredentials: true,
      url: `${backendUrl}/${siteDt[0].site_name}/api/appointments?provider_id=${user.id}`,
    })
      .then((response) => {
        if (response) {
          let appointments = response?.data?.data;
          let future = appointments.filter((ap) => {
            let duration = moment.duration(ap.duration, "hh:mm").asMinutes();
            let aptTime = new Date(
              ap.appointment_date + " " + ap.appointment_time
            );
            return moment(aptTime).add(duration, "minutes") > new Date();
          });

          let current = appointments.filter(
            (ap) => ap.status === "Waiting" && ap.status === "In Progress"
          );

          if (future?.length || current?.length) {
            setDeactivateAlert(
              "Appointments exist for this user. Please cancel the appointments to deactivate or wait until the appointments are over"
            );
          } else {
            setDeactivate(user);
          }
        } else {
          setDeactivate(user);
        }
        setLoader(false);
      })
      .catch((e) => {
        
        setLoader(false);
        errorHandler("Something went wrong. Please try again", true);
      });
  };

  const deactivateHandler = () => {
    Promise.all([deactivateUserHandler(), deactivateSchedule()])
      .then((result) => {
        setDeactivate(null);
        successHandler("User account is successfully deactivated");
        navigate("/app/user");
      })
      .catch((e) => {
        setDeactivate(null);
        errorHandler("Something went wrong", true);
      });
  };

  const deactivateUserHandler = async () => {
    if (deactivate) {
      await axios({
        method: "PATCH",
        withCredentials: true,
        url: `${backendUrl}/api/user/deactivation/${deactivate.user_id}`,
        data: {
          status: "Deactivated",
        },
      })
        .then((response) => {
          return true;
        })
        .catch(function (error) {
          if (error.response) {
            setDeactivate(null);
            throw new Error();
          } else {
            setDeactivate(null);
            throw new Error();
          }
        });
    }
  };

  const deactivateSchedule = async () => {
    let siteDt = storageService.loadSite();
    if (deactivate) {
      await axios({
        method: "PATCH",
        withCredentials: true,
        url: `${backendUrl}/${siteDt[0].site_name}/api/user/schedule/${deactivate.id}`,
        data: {
          status: "Deactivated",
        },
      })
        .then((response) => {
          return true;
        })
        .catch(function (error) {
          if (error.response) {
            setDeactivate(null);
            throw new Error();
          } else {
            setDeactivate(null);
            throw new Error();
          }
        });
    }
  };

  const closeDeactivateAlert = () => {
    setDeactivate(null);
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "#",
      iconName: "carbon:user-multiple",
      name: "User Management",
    },
  ];

  const retry = () => {
    setApiError(null);
    setTimeout(() => {
      getData();
    }, 100);
  };

  return (
    <Fragment>
      {partTimeSubError && (
        <CautionAlert
          severity="error"
          open={partTimeSubError}
          content={partTimeSubError}
          onCloseActionB={() => {
            setPartTimeSubError();
            return resendFunction();
          }}
          onCloseActionA={() => setPartTimeSubError()}
          okText="Yes"
          cancelText="No"
        />
      )}
      {subscriptionError && (
        <CautionAlert
          severity="error"
          open={subscriptionError}
          content={subscriptionError}
          oneButton={true}
          onCloseActionB={() => setSubscriptionError()}
          okText="Ok"
        />
      )}
      {resendOpen && resend && (
        <CautionAlert
          severity="error"
          open={resendOpen}
          content={
            resend.status === "Revoked" || resend.status === "Deactivated"
              ? `Send activation link for ${resend.name}?`
              : `Resend activation link for ${resend.name}?`
          }
          okText={
            resend.status === "Revoked" || resend.status === "Deactivated"
              ? "Send"
              : "Resend"
          }
          cancelText="Cancel"
          onCloseActionB={resendActivationHandler}
          onCloseActionA={closeResendAlert}
        />
      )}
      {deactivate && (
        <CautionAlert
          severity="error"
          open={deactivate}
          content="Are you sure you want to deactivate the user?"
          onCloseActionB={deactivateHandler}
          onCloseActionA={closeDeactivateAlert}
          okText="Yes"
          cancelText="No"
        />
      )}

      {deactivateAlert && (
        <CautionAlert
          severity="error"
          open={deactivateAlert}
          content={deactivateAlert}
          onCloseActionB={() => setDeactivateAlert()}
          okText="Ok"
          oneButton={true}
        />
      )}

      {revoke && (
        <CautionAlert
          severity="error"
          open={revoke}
          content={`Revoke user invitation?`}
          okText="Revoke"
          cancelText="Cancel"
          onCloseActionB={revokeUserHandler}
          onCloseActionA={closeRevokeHandler}
        />
      )}

      <Breadcrumb options={breadCrumbData} />

      {apiError && (
        <CautionAlert
          severity="error"
          open={apiError}
          content={apiError}
          onCloseActionB={retry}
          onCloseActionA={() => setApiError(null)}
          okText="Retry"
          cancelText="Dismiss"
        />
      )}
      {error && (
        <CautionAlert
          severity="error"
          open={error}
          content={error}
          onCloseActionB={() => setError(null)}
          okText="Ok"
          oneButton={true}
        />
      )}
      <CautionAlert
        severity="success"
        success={true}
        open={success}
        content={success}
        onCloseActionB={() => setSuccess(null)}
        okText="Ok"
        oneButton={true}
      />
      {location.state?.showAlert && (
        <CautionAlert
          severity="success"
          success={true}
          open={location.state.showAlert}
          content={location.state.showAlert}
          onCloseActionB={() =>
            navigate("/app/user", location.state.showAlert, {})
          }
          okText="Ok"
          oneButton={true}
        />
      )}

      {showTable ? (
        <Fragment>
          <PageHeader
            left={"System Users"}
            right={
              canCreate ? (
                openAddUser ? null : (
                  <Button
                    fontSize="14px"
                    onClickAction={() => navigate("/app/user/add")}
                    text="Add User"
                    startIcon={
                      <Icon
                        style={{ fontSize: "14px" }}
                        icon="akar-icons:plus"
                      />
                    }
                  ></Button>
                )
              ) : null
            }
          />

          {data && <DataTable data={data} columns={columns.userTableColumns} />}

          <LoaderWithoutBackdrop open={loader} />
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default UserManagement;
